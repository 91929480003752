.CreateNewNodeBackground {
  width: 100%;
  height: 100%;
  //border: 1px solid black;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 100;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.CreateNewNodeContainer {
  width: 75%;
  height: 70%;
    // border: 1px solid red;
  background-color: var(--primaryColor);
  justify-content: space-evenly;
  box-shadow: 0px 0px 30px #fd6125;
  padding: 10px calc(50px * var(--widthmultiplier));
  border-radius: calc(
    20px * (var(--heightmultiplier) * var(--widthmultiplier)) / 2
  );
  &__form {
    height: 15%;
    font-size: calc(28px * var(--heightmultiplier));
    font-weight: 600;
    color: var(--primaryTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 2px solid black;
  }
  //border: 2px solid black;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  &__subContainer {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
 

  &__left {
    width: 48%;
    height: 100%;
    padding-left: calc(10px * var(--heightmultiplier));
   // border: 2px solid red;
  }

  &__input-row-cont__colon{
    margin-right: calc(12px * var(--widthmultiplier));
  }
  &__right {
    width: 48%;
    height: 100%;
    padding-left: calc(10px * var(--heightmultiplier));
   // border: 2px solid red;
  }
  &__input-row-cont {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    vertical-align: top;
    font-size: calc(20px * var(--heightmultiplier));
    margin-bottom: calc(10px * var(--heightmultiplier));
    //width: calc(900px * var(--widthmultiplier));
    border: 1px solid #fd6125;
    border-radius: calc(
      10px * (var(--heightmultiplier) * var(--widthmultiplier)) / 2
    );
    overflow: hidden;
    padding: calc(5px * var(--heightmultiplier))
      calc(20px * var(--widthmultiplier));
    //border: 1px solid black;
    &__input {
      color: var(--primaryTextColor);
      background-color: var(--secondaryColor);
      outline: none;
      border: none;
      // border: 1px solid #FD6125;
      // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
      // width: fit-content;
      width: 65%;
    }

    &__label {
      color: var(--primaryTextColor);
      margin-right: 10px;
      height: calc(27px * var(--heightmultiplier));
      width: 35%;
      font-weight: 500;
    }
  }

  &__field {
    font-size: calc(20px * var(--heightmultiplier));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(4px * var(--heightmultiplier)) 0px;
    border: 1px solid #fd6125;
    margin-bottom: calc(10px * var(--heightmultiplier));
    border-radius: calc(
      10px * (var(--heightmultiplier) * var(--widthmultiplier)) / 2
    );
    &__title {
      width: 29%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-around;
     // border:1px solid green;
    }

    &__colon {
      width: 2%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: calc(7px * var(--widthmultiplier));
  

    }

    &__value {
      width: 69%;
      //font-weight: 500;
      //background-color: grey;
      border: none;
      display: flex;
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;

      &__option {
        width: 100%;
        font-weight: 500;
        background-color: var(--secondaryColor);
        color: var(--primaryTextColor);
        border: none;
        // margin-left: calc(2px * var(--heightmultiplier));
      }
    }
  }
  &__bottom{
    width: 100%;
    height:15%;
    //border: 1px solid red;
    &__createNodeBtn{
      width: calc(300px * var(--widthmultiplier));;
        padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier));
        font-size: calc(24px * var(--heightmultiplier));
        font-weight: 500;
        border: none;
        border-radius: calc(15px * var(--heightmultiplier));
        background-color: rgba($color: #FD6125, $alpha: 0.5);
        color: var(--primaryTextColor);

        &:hover{
            font-weight: 600;
            background-color: #FD6125;
            color: white;
        }
    }
  }
}