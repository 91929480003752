.HubRelatedNodesDetailsPopup-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  //border: 1px solid white;
}



.HubRelatedNodesDetailsPopup-modalContainer {
  width: 60%;
  height: 70%;

  border-radius: 0.75em;

  background-color: var(--primaryColor);
  ;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  //border: 1px solid blue;
  padding: calc(20px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

  font-size: calc(50px * var(--heightmultiplier));
  box-shadow: 0px 0px 15px #FD6125;
  overflow-y: auto;


  &__heading {
    width: 100%;
    font-weight: 500;
    height: 10%;
    font-size: calc(32px * var(--heightmultiplier));
    //  border: 1px solid blue;
  }

  &__dataContainer {
    width: 100%;
    height:80%;
    // border: 1px solid green;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  &__item {
    font-size: calc(20px * var(--widthmultiplier));
    margin-top: calc(5px * var(--heightmultiplier));
    margin-bottom: calc(5px * var(--heightmultiplier));
    width: 60%;
    //border: 1px solid yellow;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      width: 40%;
      font-weight: 500;
    }

    &__value {
      width: 60%;
      padding-left: 10px;
    }
  }
  &__button{
    width: 100%;
    height: fit-content;
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__OkayBtn {

      box-sizing: border-box;
      width: calc(150px * var(--widthmultiplier));
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 600;
      font-size: calc(40px * var(--heightmultiplier));
      background-color: rgba(253, 97, 37, 1);
      border-radius: 5px;
      color: white;
  
      &:hover {
        cursor: pointer;
        background-color: #FC6025;
      }
    }
  }
}