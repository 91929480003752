@import '../styles/variables';

.forgotpassword-page {

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondaryColor);
    overflow: hidden;
    padding: 0px;
    margin: 0px;

    &__leftHalf {

        left: 0;
        top: 0;
        height: 100%;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);

        // border: 1px solid black;


        &__otp-container {
            width: 69.84%;
            margin-left: 10%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // border: 1px solid black;
            margin-bottom: calc(20px * var(--heightmultiplier));


            &__input-password {
                box-sizing: border-box;
                outline: none;
                outline: none;
                border: 1px solid #F2F2F2;
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                padding-left: calc(32px * var(--widthmultiplier));
                width: 73.2%;
                height: calc(60px * var(--heightmultiplier));
                font-size: calc(16px * var(--heightmultiplier));
                
            }
            &__spinner{
                width: calc(30px * var(--widthmultiplier));
                height: calc(30px * var(--widthmultiplier));
                box-sizing: border-box;
                border: calc(5px * var(--widthmultiplier)) solid #FD6125;
                border-right-color: rgba(6, 6, 6, 0.0);
                border-radius: 50%;
                -webkit-animation: spin 1s linear infinite;
                animation: spin 1s linear infinite;
              
            }

            &__btn-getOTP {
                border: none;
                width: 18%;
                height: 100%;
                height: calc(45px * var(--heightmultiplier));
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(22px * var(--heightmultiplier));
                color: #F2F2F2;

                background: #FD6125;
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }


        &__welcomeBox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 59.84%;
            margin-bottom: calc(90px * var(--heightmultiplier));


            &__logo {
                height: calc(80px * var(--heightmultiplier));
                width: calc(80px * var(--widthmultiplier));
                margin-bottom: calc(80px * var(--heightmultiplier));
                
                // background: linear-gradient(91.14deg, #4285F4 6.67%, #BBD5FF 113.35%);
            }

            &__welcomeMsg {
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(52px * var(--heightmultiplier));
                line-height: calc(70px * var(--heightmultiplier));

                /* Primary Black */

                color: var(--primaryTextColor);

            }

            &__register-message {
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(22px * var(--heightmultiplier));
                line-height: calc(30px * var(--heightmultiplier));
                /* identical to box height */
                color: #878B8F;

            }
        }

        &__register-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 59.84%;


            &__input-email {
                box-sizing: border-box;
                outline: none;
                border: none;
                background: #F2F2F2;
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                padding-left: calc(32px * var(--widthmultiplier));
                width: 85.5%;
                height: calc(60px * var(--heightmultiplier));
                font-size: calc(16px * var(--heightmultiplier));
                margin-bottom: calc(18px * var(--heightmultiplier));
            }

            &__input-password {
                box-sizing: border-box;
                outline: none;
                outline: none;
                border: 1px solid #F2F2F2;
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                padding-left: calc(32px * var(--widthmultiplier));
                width: 85.5%;
                height: calc(60px * var(--heightmultiplier));
                font-size: calc(16px * var(--heightmultiplier));
                margin-bottom: calc(20px * var(--heightmultiplier));
            }

            &__forgotPassword {
                text-align: end;
                box-sizing: border-box;
                outline: none;
                outline: none;
                // border: 1px solid #F2F2F2;
                // border-radius: 10px;
                // padding-left: 32px;
                width: 85.5%;
                height: calc(20px * var(--heightmultiplier));
                font-weight: 400;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));
                text-decoration-line: underline;

                color: #878B8F;

                mix-blend-mode: normal;

                margin-bottom: calc(26px * var(--heightmultiplier));
            }
            

            &__btn-SignIn {
                border: none;
                width: 85.5%;
                height: calc(45px * var(--heightmultiplier));
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(22px * var(--heightmultiplier));
                color: #F2F2F2;

                background: #FD6125;
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                }
            }

            &__register-msg1 {
                margin-top: calc(20px * var(--heightmultiplier));
                width: 85.5%;
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));
                /* identical to box height, or 125% */


                color: #878B8F;

                mix-blend-mode: normal;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__register-msg2 {
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));
                /* identical to box height, or 125% */

                mix-blend-mode: normal;
                color: rgba(66, 133, 244, 1);

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &__error-msg {
                color: red;
                font-size: calc(16px * var(--heightmultiplier));
                opacity: 0;
            }
        }
    }

    &__rightHalf {
        box-sizing: border-box;
        position: relative;
        height: 100%;
        width: 55%;
        // border: 1px solid black;
        overflow: hidden;

        &__img {
            // width: 1920px;
            position: absolute;
            height: 100%;
            // width: 181.8%;
            left: -41.85%;
            top: 0px;

            z-index: 0;
        }
    }

}