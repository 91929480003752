.MainDroneManagementTab {
  // position: relative;
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
  padding: 5px 0px;
}

.DroneManagementSearch {
  align-self: flex-start;
  width: fit-content;
  height: 7%;
  // border: 1px solid red;
  margin: calc(5px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid blue;
  &__box {
    width: fit-content;
    height: fit-content;
    border-radius: calc(16px * var(--heightmultiplier));
    margin: calc(2px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    border: 1px solid var(--primaryTextColor);
    outline: none;
    padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid green;
    &__input {
      width: 80%;
      height: fit-content;
      border-radius: calc(16px * var(--heightmultiplier));
      border: none;
      outline: none;
      padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      background-color: var(--primaryColor);
      color: var(--primaryTextColor);
    }
  }

  &__filter {
    box-sizing: border-box;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    // border: 1px solid red;
    margin-left: calc(10px * var(--heightmultiplier));
    // height: 5%;

    &__title {
      height: 100%;
      width: calc(250px * var(--widthmultiplier));
      //  background-color: #FD6125;
      padding: 0px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: flex-start;
      // border: 1px solid blue;
      color: var(--primaryTextColor);
      font-size: calc(24px * var(--heightmultiplier));
    }

    &__options {
      height: 90%;
      width: 80%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 2px solid #FD6125;
      border-radius: calc(10px * var(--heightmultiplier));
      font-size: calc(20px * var(--heightmultiplier));
      background-color: var(--primaryColor);
      color: var(--primaryTextColor);
      padding-left: calc(10px * var(--heightmultiplier));
      // border: 1px solid red;
      &__item {
        color: var(--primaryTextColor);
        font-size: calc(20px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        margin: 0px 10px;
        border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
        background-color: rgba($color: #fd6125, $alpha: 0.1);

        &:hover {
          background-color: rgba($color: #fd6125, $alpha: 0.5);
          border-color: #fd6125;
          color: white;
        }
      }

      &__selected-item {
        color: white;
        font-size: calc(24px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        margin: 0px 10px;
        border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
        background-color: rgba($color: #fd6125, $alpha: 1);

        // &:hover {
        //     background-color: rgba($color: #FD6125, $alpha: 0.5);
        //     border-color: #FD6125;
        //     color: white;
        // }
      }
    }
  }

}

.DroneManagementContent-container {
  position: relative;
  top: 0px;
  left: 0px;
  // z-index: 10;
  width: 99%;
  height: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  //border: 1px solid black;
  // padding-top: calc(10px * var(--heightmultiplier));

  &__filter {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: calc(50px * var(--heightmultiplier));
    width: 97%;

    &__title {
      // background-color: #FD6125;
      color: var(--primaryTextColor);
      font-size: calc(30px * var(--heightmultiplier));
    }

    &__options {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // border: 1px solid #FD6125;
      height: 80%;
      // padding-left: calc(20px * var(--heightmultiplier));

      &__item {
        color: var(--primaryTextColor);
        font-size: calc(24px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        margin: 0px 10px;
        border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
        background-color: rgba($color: #fd6125, $alpha: 0.1);

        &:hover {
          background-color: rgba($color: #fd6125, $alpha: 0.5);
          border-color: #fd6125;
          color: white;
        }
      }

      &__selected-item {
        color: white;
        font-size: calc(24px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        margin: 0px 10px;
        border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
        background-color: rgba($color: #fd6125, $alpha: 1);

        // &:hover {
        //     background-color: rgba($color: #FD6125, $alpha: 0.5);
        //     border-color: #FD6125;
        //     color: white;
        // }
      }
    }

    &__duration-title {
      color: var(--primaryTextColor);
      font-size: calc(24px * var(--heightmultiplier));
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
    }

    &__duration-select {
      color: black;
      font-size: calc(20px * var(--heightmultiplier));
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      margin: 0px 10px;
      border-color: #fd6125;
      border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      // &__option{

      // }
    }
  }

  &__index {
    position: sticky;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    background-color: rgba($color: #fd6125, $alpha: 1);
    color: white;
    // margin-top: 10px;
    border-radius: 5px;
    // margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    // margin-right: 11px;
    // font-size: calc(24px * var(--heightmultiplier));
    // font-weight: 500;
    //font-size: calc(20px * var(--heightmultiplier));
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 30%;
    // height: 100%;
    width: 98%;

    //border: 1px solid blue;
    &__term {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
      font-size: calc(22px * var(--heightmultiplier));
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 30%;
      //border: 1px solid black;
      border-right: 1px solid white;
      border-left: 1px solid white;
      height: 100%;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      user-select: none;

      // &:hover{
      //     cursor: pointer;
      // }
    }

    &__droneNo {
      width: 7%;
      // border: 1px solid black;
      border-left: none;
    }

    &__modelNo {
      width: 11%;
      // border: 1px solid black;
    }

    &__payload {
      width: 10%;
      // border: 1px solid black;
    }

    &__maxRange {
      width: 10%;
      // border: 1px solid black;
    }

    &__flightHrs {
      width: 10%;
      // border: 1px solid black;
    }

    &__mfgDate {
      width: 15%;
      // border: 1px solid black;
    }

    &__lmDate {
      width: 15%;
      // border: 1px solid black;
    }

    &__versionD {
      width: 10%;
      // border: 1px solid black;
    }

    &__pilotId {
      width: 12%;
      // border: 1px solid black;
    }

    &__currLoca {
      width: 10%;
      // border: 1px solid black;
      //border-right: none;
    }

    &__button {
      width: 8%;
      border-right: none;
    }
  }
}

.DroneManagementContent-datacontainer {
  position: relative;
  top: 0px;
  left: 0px;
  // z-index: 10;
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    //padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    background-color: rgba($color: #FD6125, $alpha: 0.1);
    margin-top: 10px;
    border-radius: 5px;
    &:nth-child(odd) {
      background-color: rgba($color: #E2E2E2, $alpha: 0.3); // Light gray for odd rows
    }

    &:nth-child(even) {
      background-color: rgba($color: #FD6125, $alpha: 0.2); // White for even rows
    }

    &__term {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: calc(20px * var(--heightmultiplier));
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      // margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      //margin-right: 11px;
      // width: 30%;
      // border: 1px solid blue;
      padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
      border-right: 1px solid #fd6125;
      border-left: 1px solid #fd6125;
      height: 100%;
    }

    &__droneNo {
      width: 7%;
      // border: 1px solid black;
      border-left: none;
    }

    &__modelNo {
      width: 11%;
      // border: 1px solid black;
    }

    &__payload {
      width: 10%;
      // border: 1px solid black;
    }

    &__maxRange {
      width: 10%;
      // border: 1px solid black;
    }

    &__flightHrs {
      width: 10%;
      // border: 1px solid black;
    }

    &__mfgDate {
      width: 15%;
      // border: 1px solid black;
    }

    &__lmDate {
      width: 15%;
      // border: 1px solid black;
    }

    &__versionD {
      width: 10%;
      // border: 1px solid black;
    }

    &__pilotId {
      width: 12%;
      // border: 1px solid black;
    }

    &__currLoca {
      width: 10%;
      // border: 1px solid black;
      //border-right: none;
    }

    &__button {
      width: 8%;
      // border: 1px solid black;
      border-right: none;
    }
  }
  &__btn{
    width:fit-content;
    padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    border: 1px solid #4285F4;
    background-color:  rgba($color: #4285F4, $alpha: 0.4);
    color: var(--primaryTextColor);
    &:hover{
        cursor: pointer;
        background-color:  rgba($color: #4285F4, $alpha: 1);
    }
  }
}

.AddNewDroneButton {
  width: 98%;
  height: 5%;
  margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
  //padding: calc(10px * var(--heightmultiplier));
  //border: 2px solid green;
  display: flex;
  align-items: right;
  justify-content: right;
  vertical-align: bottom;

  // padding-top: calc(40px*var(--heightmultiplier));
  &__Btn {
    border: 1px solid #fd6125;
    border-radius: calc(10px * var(--heightmultiplier));
    padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    background-color: rgba($color: #fd6125, $alpha: 0.5);
    font-size: calc(18px * var(--heightmultiplier));
    //border: none;
    //height: 50%;
    color: var(--primaryTextColor);

    &:hover {
      cursor: pointer;
      background-color: rgba($color: #fd6125, $alpha: 1);
      color: white;
    }
  }
}