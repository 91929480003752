.cancellationOrderTemplateContent {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    //border: 1px solid black;
    margin-top: calc(15px * var(--heightmultiplier));
    &__title {
      width: 70%;
      height: 10%;
      //border: 1px solid var(--primaryTextColor);
      font-size: calc(24px * var(--heightmultiplier));
      font-weight: 600;
      display: flex;
      text-align: left;
      margin: calc(0px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
      background-color: var(--primaryColor);
    }
    &__error{
      width: 70%;
      height: 10%;
      //border: 1px solid var(--primaryTextColor);
      font-size: calc(16px * var(--heightmultiplier));
      display: flex;
      text-align: left;
      color: rgb(255, 0, 0);
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      background-color: var(--primaryColor);
    }
  
    &__inputFiled {
      width: 70%;
      height: fit-content;
      border: 1px solid var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      margin: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
      background-color: var(--primaryColor);
      // border: 1px solid red;
      // padding: calc(10px * var(--heightmultiplier))
      // calc(20px * var(--widthmultiplier));
      //overflow-y: auto;
      //overflow-x: hidden;
      // position: relative;
      max-height: 40%;
  
      &__label {
        width: fit-content;
        margin-left: calc(10px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        height: 2%;
        //border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        font-weight: 600;
        display: flex;
        text-align: flex-start;
        align-items: center;
        margin-top: calc(-12px * var(--heightmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor) // position: absolute;
          // left: 0px;
          // top: 0px;
      }
  
      &__value {
        width: 100%;
        height: fit-content;
        max-height: 40%;
        //border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        display: flex;
        text-align: flex-start;
        align-items: center;
        background-color: transparent;
        border-radius: calc(10px * var(--heightmultiplier));
        padding: calc(5px * var(--heightmultiplier));
  
  
        &__input {
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          color: var(--primaryTextColor);
          background-color: var(--primaryColor);
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 40%;
        }
      }
    }
  
    &__button {
      width: 100%;
      height: calc(60px * var(--heightmultiplier));
      //border: 1px solid #F25858;
      display: flex;
      align-items: center;
      align-items: center;
      justify-content: center;
      margin-top: calc(20px * var(--heightmultiplier));
  
      &__orderCancel {
        width: 20%;
        height: 70%;
        border: 2px solid red;
        background-color: rgba($color: red, $alpha: 0.2);
        font-size: calc(18px*var(--heightmultiplier));
        font-weight: 500;
        border-radius: calc(5px * var(--heightmultiplier));
        color: red;
  
        &:hover {
          cursor: pointer;
          background-color: rgba($color: red, $alpha: 1);
          box-shadow: var(--primaryTextColor) 0px 5px 5px;
          color: white;
        }
      }
    }
  }