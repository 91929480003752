.hubs-filter-value {
    // width: calc(200px * var(--widthmultiplier));
    height: calc(30px * var(--heightmultiplier));
    // border: 1px solid blue;
    border-radius: calc(10px * var(--heightmultiplier));
    margin: calc(0px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    color: var(--primaryTextColor);
    display: flex;
    flex-direction: column;
    //text-align: center;
    align-items: flex-start;
    justify-content: space-between;
   
    
    &__container {
        display: flex;
        // flex-direction: column;
        margin-bottom: 1rem;
        // margin-top: -0.7rem;

        &__label {
            // font-size: 1rem;
            // font-weight: bold;
            // margin-bottom: 0.5rem;
            height: fit-content;
            width: calc(200px * var(--widthmultiplier));
            font-size: calc(18px * var(--heightmultiplier));
            white-space: nowrap; 
            margin-left: calc(0px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
           
        }


        &__select{
            width: 100%;
            height: 100%;
            font-size: calc(18px * var(--heightmultiplier));
            border-radius: calc(10px * var(--heightmultiplier));
            border: 2px solid var(--primaryTextColor);
            display: flex;
            flex-direction: row;
            text-align: left;
            align-items: center;
            justify-content: space-around;
            // border: 1px solid pink;
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
          
    
            &__option {
                width: 100%;
                height: 80%;
                outline: none;
                border: none;
                //border: 1px solid blue;
                background-color: var(--primaryColor);
                color: var(--primaryTextColor);
            }
    }


  
    }
}