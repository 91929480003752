/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
// input[type=number] {
//     -moz-appearance: textfield;
// }

.MissionPlanPointRow-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--primaryTextColor);
    border: 1px solid var(--primaryTextColor);
    border-radius: 5px;
    margin-bottom: calc(10px * var(--heightmultiplier));
    width: 100%;
    height: fit-content;
    padding: 2px;

    &__hoverData-container{
        position: absolute;
        background-color: var(--primaryColor);
        z-index: 50;
        border: 1px solid #FD6125;
        padding:0px 10px;
        border-radius: 5px;
        left: 0px;
        margin-top: 10px;
    }

    &__row1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--primaryTextColor);
        border: 1px solid var(--primaryTextColor);
        width: 100%;
        padding: 2px;

        &__srno {
            height: 100%;
            width: calc(30px * var(--widthmultiplier));
            text-align: center;
            font-size:  calc(20px * var(--heightmultiplier));
            font-weight: 600;
            // border: 1px solid var(--primaryTextColor);
        }
        &__cmd{
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: fit-content;
        }
        &__cmd-select{
            width: fit-content;
        }

        &__updownimg {
            height: calc(24px * var(--heightmultiplier));
            width: fit-content;
            border-radius: calc(5px * var(--heightmultiplier));

            &:hover {
                cursor: pointer;
                border: 1px solid #FD6125;
            }
        }
        &__buttons{
            display: flex;
            align-items: center;
            justify-content: space-between;
            // background-color: gray;
        }
    }

    &__row2 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__para-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            &__label{
                font-weight: 500;
            }


            &__paraInput-disabled {
                width: calc(60px * var(--widthmultiplier));
                background-color: gray;
                color: gray;
                border-radius: 2px;
            }
            &__paraInput-enabled {
                width: calc(60px * var(--widthmultiplier));
                border-radius: 2px;
            }
        }
    }

    &__row3{
        margin-top: calc(10px * var(--heightmultiplier));
        width: 100%;
        display: flex;
        align-items: center;
        color: var(--primaryTextColor);
        justify-content: space-around;
        &__frame{
            font-weight: 600;
            
        }

        &__distance{
            width: calc(80px * var(--widthmultiplier));
            margin-left: calc(5px * var(--widthmultiplier));
        }
        &__distance-cont{
            font-weight: 600;
        }
    }





}