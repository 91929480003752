.InventoryManagement-container {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border: 1px solid red;
    &__ims {

        width: calc(500px * var(--widthmultiplier));
        height: calc(300px * var(--heightmultiplier));
        border: 1px solid var(--primaryTextColor);
        // margin: calc(15px * var(--widthmultiplier));
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-radius: calc(15px * var(--widthmultiplier));
        overflow: hidden;
        padding: 0px;
        border: none;
        background-color: rgba($color: #9D85F6, $alpha: 0.2);
        font-size: calc(32px * var(--heightmultiplier));
     

        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 15px #686868;
            scale: 1.1;
            // margin-top: calc(60px * var(--heightmultiplier));
    
            


        }
    }


    // &:hover + &__ims-note {
    //     margin-top: calc(40px * var(--heightmultiplier));
    // }

    &__ims-note {
        font-size: calc(16px * var(--heightmultiplier));
        color: rgb(66, 135, 238);
        padding: calc(10px * var(--widthmultiplier));
       margin-top: calc(15px * var(--heightmultiplier)); 
         
    }
}