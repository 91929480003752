.DailyPlans-container {
    padding-top: calc(10px * var(--heightmultiplier));
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    // overflow-y: auto;
    &__all-data {
        overflow-y: scroll;
        width: 100%;
    }

    // &__filter {
    //     box-sizing: border-box;
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-start;
    //     height: calc(80px * var(--heightmultiplier));
    //     width: 97%;

    //     &__title {
    //         // background-color: #FD6125;
    //         color: var(--primaryTextColor);
    //         font-size: calc(30px * var(--heightmultiplier));
    //         margin-left: calc(5px * var(--widthmultiplier));
    //     }

    //     &__options {
    //         box-sizing: border-box;
    //         display: flex;
    //         align-items: center;
    //         justify-content: flex-start;
    //         // border: 1px solid #FD6125;
    //         height: 80%;
    //         // padding-left: calc(20px * var(--heightmultiplier));

    //         &__item {
    //             color: var(--primaryTextColor);
    //             font-size: calc(24px * var(--heightmultiplier));
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             padding: 0px 10px;
    //             margin: 0px 10px;
    //             border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    //             background-color: rgba($color: #FD6125, $alpha: 0.1);

    //             &:hover {
    //                 cursor: pointer;
    //                 background-color: rgba($color: #FD6125, $alpha: 0.5);
    //                 border-color: #FD6125;
    //                 color: white;
    //             }
    //         }

    //         &__selected-item {
    //             color: white;
    //             font-size: calc(24px * var(--heightmultiplier));
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             padding: 0px 10px;
    //             margin: 0px 10px;
    //             border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    //             background-color: rgba($color: #FD6125, $alpha: 1);

    //             // &:hover {
    //             //     background-color: rgba($color: #FD6125, $alpha: 0.5);
    //             //     border-color: #FD6125;
    //             //     color: white;
    //             // }
    //         }
    //     }

    //     &__duration-title {
    //         color: var(--primaryTextColor);
    //         font-size: calc(24px * var(--heightmultiplier));
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         padding: 0px 10px;
    //     }

    //     &__duration-select {
    //         color: black;
    //         font-size: calc(20px * var(--heightmultiplier));
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         padding: 0px 10px;
    //         margin: 0px 10px;
    //         border-color: #FD6125;
    //         border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    //         // &__option{

    //         // }
    //     }
    // }

    &__filter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        // border: 1px solid white;
        gap: 10px;

        &__noOfFlights {
            font-size: calc(20px * var(--widthmultiplier));
        }

        &__sort {
            display: flex;
            align-items: center;

            &__duration-select {
                color: black;
                font-size: calc(18px * var(--widthmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-color: #FD6125;
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            }

            &__heading {
                font-size: calc(20px * var(--widthmultiplier));
            }
        }

        &__duration {
            gap: 5px;
            display: flex;
            align-items: center;
            justify-content: start;
            // border: 1px solid white;

            &__label {
                font-size: calc(20px * var(--widthmultiplier));
                // height: 100%;
                display: flex;
                align-items: center;
            }
        }

        &__toggle-btn {
            width: fit-content;
            height: calc(40px * var(--heightmultiplier));
            padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            border: 1px solid #4285F4;
            background-color: #7facfa;
            color: var(--primaryTextColor);

            &:hover {
                cursor: pointer;
                background-color: #4285F4;
            }
        }

        &__applyBtn {
            // width: calc(100px * var(--widthmultiplier));
            padding: calc(10px * var(--heightmultiplier)) calc(30px * var(--heightmultiplier));
            font-size: calc(18px * var(--heightmultiplier));
            font-weight: 500;
            border: none;
            border-radius: calc(10px * var(--heightmultiplier));
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;

        }

        &__today {
            width: 8%;
            color: var(--primaryTextColor);
            font-size: calc(18px * var(--widthmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: rgba($color: #4285Fa, $alpha: 0.2);
            border: 1px solid #4285Fa;

            &:hover {
                background-color: rgba($color: #4285Fa, $alpha: 1);
                border: 1px solid #4285Fa;
                color: white;
            }

            &__active {
                background-color: rgba($color: #4285Fa, $alpha: 1);
                border: 1px solid #4285Fa;
                color: white;
            }
        }

        &__tommorrow {
            width: 9%;
            color: var(--primaryTextColor);
            font-size: calc(18px * var(--widthmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: rgba($color: #9d85f6, $alpha: 0.2);
            border: 1px solid #9d85f6;

            &:hover {
                background-color: rgba($color: #9d85f6, $alpha: 1);
                border: 1px solid#9d85f6;
                color: white;
            }

            &__active {
                background-color: rgba($color: #9d85f6, $alpha: 1);
                border: 1px solid #9d85f6;
                color: white;
            }
        }

        &__update-btn {
            width: 10%;
            color: var(--primaryTextColor);
            font-size: calc(18px * var(--widthmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: rgba($color: #6CCC54, $alpha: 0.2);
            border: 1px solid #6CCC54;

            &:hover {
                background-color: rgba($color: #6CCC54, $alpha: 1);
                border: 1px solid #6CCC54;
                color: white;
            }

            &:focus {
                background-color: rgba($color: #6CCC54, $alpha: 1);
                border: 1px solid #6CCC54;
                color: white;
            }
        }

        &__checkBox {

            &__label {
                font-size: calc(20px * var(--widthmultiplier));
                padding-left: calc(22px * var(--widthmultiplier));
                display: flex;
                align-items: center;
                gap: calc(14px * var(--widthmultiplier));
            }

            &__input {
                height: calc(20px * var(--widthmultiplier));
                width: calc(20px * var(--widthmultiplier));
            }
        }
    }


    &__index {
        position: sticky;
        top: 0px;
        width: 98.1%;
        height: calc(50px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: rgba($color: #FD6125, $alpha: 1);
        color: white;
        // margin-top: 10px;
        border-radius: 5px;
        // margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // margin-right: 17px;
        font-size: calc(20px * var(--heightmultiplier));
        font-weight: 400;
        // width: 30%;
        // height: 100%;

        // border: 1px solid rgb(219, 11, 11);

        &__term {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: calc(24px * var(--heightmultiplier));
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 30%;
            border-right: 1px solid white;
            border-left: 1px solid white;
            height: 100%;

            -moz-user-select: -moz-none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        &__flight-type {
            width: 10%;
            // border: 1px solid black;
            border-left: none;

        }

        // &__drone {
        //     width: 18%;
        //     // border: 1px solid black;
        //     overflow: hidden;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        // }

        &__source {
            width: 12%;
            // border: 1px solid black;
        }

        &__destination {
            width: 12%;
            // border: 1px solid black;
        }

        &__payload {
            width: 9%;
            // border: 1px solid black;
        }

        // &__distance {
        //     width: 8%;
        //     // border: 1px solid black;
        // }

        &__flight-date {
            width: 12.25%;
            // border: 1px solid black;
        }

        &__start-time {
            width: 12.25%;
            // border: 1px solid black;
        }

        &__time-taken {
            width: 10.5%;
            // border: 1px solid black;
        }

        &__order-details {
            width: 11%;
            // border: 1px solid black;
            // border-right: none;


            &__btn {
                padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            }
        }

        &__shuffle {
            width: 11%;
            // border: 1px solid black;
            border-right: none;


            &__btn {
                padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            }
        }
    }

    &__pagination {
        width: 98%;
        height: fit-content;
        // border: 1px solid red;
        position: sticky;
        bottom: 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &__complete-flight {
        width: 98%;
        border: 1px solid #FD6125;
        display: flex;
        border-radius: 5px;


        &__allData {
            width: 90%;

            &__details {
                display: flex;
                flex-direction: column;
                gap: calc(10px * (var(--heightmultiplier)));
            }

            &__header {
                // border: 1px solid blue;
                display: flex;
                // gap: calc(14px * var(--heightmultiplier));
                justify-content: space-between;
                font-size: calc(22px * var(--heightmultiplier));
                padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier)) calc(0px * var(--widthmultiplier)) calc(10px * var(--widthmultiplier));

                &__orderId {

                    width: 25%;
                    // border: 1px solid blue;
                    text-align: start;

                    &__doubleHeadedArrowSvg {
                        width: calc(60px * var(--heightmultiplier));
                        height: calc(40px * var(--heightmultiplier));
                    }
                }

                &__orderNumber {
                    width: 15%;
                }

                &__droneId {
                    width: 30%;
                    // border: 1px solid blue;
                }

                &__orderType {
                    width: 25%;
                    // text-align: start;
                }
            }

            // &__details {
            //     // border: 1px solid darkblue;
            // }
        }

    }

}

.DailyPlans-serach {
    width: fit-content;
    height: fit-content;
    // border: 1px solid red;
    margin: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__box {
        width: fit-content;
        height: fit-content;
        border-radius: calc(16px * var(--heightmultiplier));
        margin: calc(2px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        border: 1px solid white;
        outline: none;
        padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__input {
            width: 80%;
            height: fit-content;
            border-radius: calc(16px * var(--heightmultiplier));
            border: none;
            outline: none;
            padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
        }
    }

    &__filterBox {
        width: fit-content;
        height: fit-content;
        // border: 1px solid red;
        margin-left: calc(16px * var(--widthmultiplier));
        // padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));

        &__button {
            width: calc(100px * var(--widthmultiplier));
            height: fit-content;
            padding: calc(4px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            border: 2px solid #FD6125;
            background-color: rgba($color: #FD6125, $alpha: 0.2);
            border-radius: calc(10px * var(--heightmultiplier));

            &:hover {
                cursor: pointer;
                background-color: rgba($color: #FD6125, $alpha: 0.8);
                border-color: #FD6125;
                color: white;
                font-weight: 500;
            }
        }
    }

    &__dispalyParams {
        width: fit-content;
        height: fit-content;
        // border: 1px solid red;
        margin-left: calc(16px * var(--widthmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__container {
            width: fit-content;
            height: fit-content;
            border: 2px solid #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            margin: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            // background-color: #FD6125;
        }

        &__title {
            width: fit-content;
            height: fit-content;
            // border: 1px solid yellow;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: calc(2px * var(--heightmultiplier)) calc(2px * var(--widthmultiplier));
            font-size: large;
            font-weight: 400;
            color: var(--primaryTextColor);
        }

        &__input {
            width: fit-content;
            height: fit-content;
            // border: 1px solid #FD6125;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__InputBox {
                width: fit-content;
                height: fit-content;
                // border: 1px solid red;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        // &__value {
        //     width: fit-content;
        //     height: fit-content;
        //     // border: 1px solid yellow;
        //     display: flex;
        //     flex-direction: row;
        //     align-items: center;
        //     justify-content: flex-start;
        //     padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        //     font-size: large;
        //     font-weight: 400;
        //     color: var(--primaryTextColor);
        // }
        &__select {
            width: fit-content;
            height: fit-content;
            color: black;
            font-size: calc(18px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // padding: 0px 10px;
            margin: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            // border:none;
            border-color: #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
            padding-left: calc(10px * var(--heightmultiplier));
            // border: 1px solid purple;
            // &__option{

            // }
        }

        &__selectFrom {
            width: fit-content;
            height: fit-content;
            color: black;
            font-size: calc(18px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // padding: 0px 10px;
            margin: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            border: none;
            border-color: #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
            // border: 1px solid purple;
            // &__option{

            // }
        }

        &__selectTo {
            width: fit-content;
            height: fit-content;
            color: black;
            font-size: calc(18px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // padding: 0px 10px;
            margin: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            border: none;
            border-color: #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
            // border: 1px solid purple;
            // &__option{

            // }
        }

    }

}

.DailyPlans-datacontainer {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 88%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // overflow-y: auto;
    flex-grow: 1;
    margin-top: calc(10px * var(--heightmultiplier));
    gap: calc(10px * var(--heightmultiplier));

    // border: 1px solid red;
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: var(--ongoingColor);
        // margin-top: calc(10px * var(--heightmultiplier));
        border-radius: 5px;
        height: calc(40px * var(--heightmultiplier));
        transition: transform 0.3s ease-in-out, height 0.3s ease-in-out;
        // &:nth-child(odd) {
        //     background-color: rgba($color: #E2E2E2, $alpha: 0.5); // Light gray for odd rows
        // }

        // &:nth-child(even) {
        //     background-color: rgba($color: #FD6125, $alpha: 0.3); // White for even rows
        // }

        &__term {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: calc(19px * var(--heightmultiplier));
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 30%;
            border-right: 1px solid #FD6125;
            border-left: 1px solid #FD6125;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__flight-type {
            width: 11.10%;
            // border: 1px solid black;
            border-left: none;

        }

        // &__drone {
        //     width: 18%;
        //     // border: 1px solid black;
        //     overflow: hidden;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        // }

        &__source {
            width: 13.70%;
            // border: 1px solid black;
        }

        &__destination {
            width: 13.50%;
            // border: 1px solid black;
        }

        &__payload {
            width: 10.50%;
            // border: 1px solid black;
        }

        // &__distance {
        //     width: 7%;
        //     // border: 1px solid black;
        // }

        &__flight-date {
            width: 14%;
            // border: 1px solid black;
        }

        &__start-time {
            width: 14%;
            // border: 1px solid black;
        }

        &__time-taken {
            width: 12%;
            // border: 1px solid black;
        }

        &__order-details {
            width: 12%;
            // border: 1px solid black;
            border-right: none;
            // border-left: none;
            display: flex;
            justify-content: space-around;



            &__btn {
                width: fit-content;
                height: calc(40px * var(--heightmultiplier));
                padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border: 1px solid #4285F4;
                background-color: #7facfa;
                color: var(--primaryTextColor);

                &:hover {
                    cursor: pointer;
                    background-color: #4285F4;
                }
            }

            &__start-btn {
                width: fit-content;
                height: calc(40px * var(--heightmultiplier));
                padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border: 1px solid #9d85f6;
                background-color: #b3a1f7;
                color: var(--primaryTextColor);

                &:hover {
                    cursor: pointer;
                    background-color: #9d85f6;
                }
            }
        }

        &__shuffle {
            width: 11.2%;
            // border: 1px solid black;
            border-right: none;
            display: flex;
            flex-direction: column;
            gap: calc(15px * var(--heightmultiplier));

            &__btns {
                width: fit-content;
                height: calc(40px * var(--heightmultiplier));
                padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                // border: 1px solid #4285F4;
                // background-color: rgba($color: #4285F4, $alpha: 0.4);
                color: var(--primaryTextColor);
                display: flex;
                gap: calc(15px * var(--heightmultiplier));

                // &:hover {
                //     cursor: pointer;
                //     background-color: rgba($color: #4285F4, $alpha: 1);
                // }
                &__up-btn {
                    background-color: transparent;
                    border: 1px solid #FD6125;
                    height: fit-content;

                    &:hover {
                        cursor: pointer;
                        background-color: var(--primaryTextColor);
                    }
                }

                &__down-btn {
                    background-color: transparent;
                    border: 1px solid #FD6125;
                    height: fit-content;
                    color: white;

                    &:hover {
                        cursor: pointer;
                        background-color: var(--primaryTextColor);
                    }
                }
            }

            &__cancel-btn {
                width: fit-content;
                height: fit-content;
                border: 2px solid red;
                background-color: rgba($color: red, $alpha: 0.2);
                font-size: calc(18px * var(--heightmultiplier));
                font-weight: 500;
                padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                border-radius: calc(5px * var(--heightmultiplier));
                color: red;

                &:hover {
                    cursor: pointer;
                    background-color: rgba($color: red, $alpha: 1);
                    color: white;
                }
            }
        }

    }

    &__rowGreen {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        height: calc(40px * var(--heightmultiplier));
        background-color: var(--successColor);
        // margin-top: calc(10px * var(--heightmultiplier));
        border-radius: 5px;

    }

    &__rowRed {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        height: calc(40px * var(--heightmultiplier));
        background-color: var(--cancelColor);
        // margin-top: calc(10px * var(--heightmultiplier));
        border-radius: 5px;
    }
}

.rs-input {
    height: calc(10px var(--heightmultiplier)) !important;
    font-size: calc(16px * var(--widthmultiplier)) !important;
    padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--heightmultiplier)) !important;
    background-color: transparent !important;
    overflow: hidden !important;
    color: var(--primaryTextColor) !important
}

.rs-input-group-addon {
    padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier)) !important;
    background-color: transparent !important;
    color: var(--primaryTextColor) !important
}

.rs-input-group {
    color: var(--primaryTextColor) !important;
    background-color: transparent !important;
    border: 2px solid var(--primaryTextColor) !important;
}

@keyframes moveUpSingle {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    20% {
        transform: translateY(-40%);
        opacity: 1;
    }

    50% {
        transform: translateY(-80%);
        opacity: 1;
    }

    80% {
        transform: translateY(-120%);
        opacity: 1;
    }

    100% {
        transform: translateY(-160%);
        opacity: 1;
    }
}

@keyframes moveDownSingle {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    20% {
        transform: translateY(40%);
        opacity: 1;
    }

    50% {
        transform: translateY(80%);
        opacity: 1;
    }

    80% {
        transform: translateY(120%);
        opacity: 1;
    }

    100% {
        transform: translateY(160%);
        opacity: 1;
    }
}

@keyframes moveUpDouble {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    20% {
        transform: translateY(-10%);
        opacity: 1;
    }

    50% {
        transform: translateY(-25%);
        opacity: 1;
    }

    80% {
        transform: translateY(-40%);
        opacity: 1;
    }

    100% {
        transform: translateY(-50%);
        opacity: 1;
    }
}

@keyframes moveDownDouble {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    20% {
        transform: translateY(10%);
        opacity: 1;
    }

    50% {
        transform: translateY(25%);
        opacity: 1;
    }

    80% {
        transform: translateY(40%);
        opacity: 1;
    }

    100% {
        transform: translateY(50%);
        opacity: 1;
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    30% {
        transform: translateY(-30%);
        opacity: 1;
    }

    50% {
        transform: translateY(-50%);
        opacity: 1;
    }

    70% {
        transform: translateY(-70%);
        opacity: 1;
    }

    100% {
        transform: translateY(-100%);
        opacity: 1;
    }
}

@keyframes moveDown {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    30% {
        transform: translateY(30%);
        opacity: 1;
    }

    50% {
        transform: translateY(50%);
        opacity: 1;
    }

    70% {
        transform: translateY(70%);
        opacity: 1;
    }

    100% {
        transform: translateY(100%);
        opacity: 1;
    }
}

.DailyPlans-container__row--animate-up {
    animation: moveUp 0.3s ease-out;
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
}

.DailyPlans-container__row--animate-down {
    animation: moveDown 0.3s ease-out;
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
}

.DailyPlans-container__row--animate-up-single {
    animation: moveUpSingle 0.3s ease-out;
    /* Smooth easing for single move-up */
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
}

.DailyPlans-container__row--animate-down-single {
    animation: moveDownSingle 0.3s ease-out;
    /* Smooth easing for single move-down */
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
}

.DailyPlans-container__row--animate-up-double {
    animation: moveUpDouble 0.3s ease-out;
    /* Smooth easing for double move-up */
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
}

.DailyPlans-container__row--animate-down-double {
    animation: moveDownDouble 0.3s ease-out;
    /* Smooth easing for single move-down */
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
}

.DailyPlans-container__complete-flight {
    transition: transform 0.3s ease-out,
        height 0.3s ease-out,
        opacity 0.3s ease-out;
}