@import "../variables";

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}


.view-order-page-container {

    position: relative;

    height: 100%;
    width: 100%;
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    // background-color: aquamarine;
    box-sizing: border-box;
    background-color: var(--secondaryColor);
    color: var(--primaryTextColor);
    font-family: "General Sans";
    // border: 1px solid red;
}

.view-order-sidebar {
    // flex: 1;
    background-color: var(--primaryColor);
    // border: 2px solid #FD6125;
    // border-left-color: white;
    // border: 2px solid black;
    position: relative;
    box-sizing: border-box;
    width: 10.5%;
    height: 100%;
    border-top-right-radius: calc(20px * var(--widthmultiplier));
    border-bottom-right-radius: calc(20px * var(--widthmultiplier));
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
//    border: 1px solid rgb(37, 40, 202);


    &__logodiv {
        height: 8%;
        width: 100%;
        // background: #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 5px 20px;
        // padding-right: 50px;
        // border: 1px solid green;

        &__logoimg {
            margin-left: 2em;
            height: 2.4em;
            width: 10em;
            &:hover{
                cursor: pointer;
            }
        }

        &__back {
            margin-right: 50px;
            // border: 2px solid black;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__logodiv-border {
        height: 2px;
        width: 90%;
        background-color: var(--secondaryColor);
    }

    &__DroneName {
        box-sizing: border-box;
        padding-top: 1.375em;
        // padding-left: 1.375;
        margin-left: 2em;
        font-size: 1.5em;
        font-weight: 500;
        height: 100%;
        // width: 100%;
        // color: #08030A;
        font-weight: 400;
    }
    &__container{
        height: 86.33%;
        width: 100%;
        // border: 2px solid #0fc8e9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    &__tabs-container{
        position: relative;
        height: 88%;
        width: 100%;
        // background-color: #FD6125;
        margin-top: 9.2%;
        border: 2px solid #FD6125;
        // border-right: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // border: 1px solid rgb(224, 210, 12);

        &__tab{
            // position: absolute;
            box-sizing: content-box;
            width: 100%;
            left: 0px;
            height: fit-content;
            // margin: calc(18px * var(--heightmultiplier)) 0px;

            border: 2px solid #FD6125;
            border-top: 1px solid #FD6125;
            border-bottom: 1px solid #FD6125;
            
            padding: calc(5px * var(--heightmultiplier)) 0px;
            height: calc(50px * var(--heightmultiplier));
            font-size: calc(24px * var(--heightmultiplier));
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--secondaryColor);
            border-right: var(--primaryColor);

            &:hover{
                cursor: pointer;
                background-color: #FD6125;
                color: white;
            }
        }
    }

    &__logout{
        position: absolute;
        bottom: 8px;
        font-size: calc(24px * var(--heightmultiplier));
        width: calc(145px * var(--widthmultiplier));
        height: calc(40px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #FD6125, $alpha: 0.1);
        border-radius: calc(10px * var(--heightmultiplier));
        font-weight: 500;
        margin-top: calc(15px * var(--heightmultiplier));
        border-color: #FD6125;
        color: var(--primaryTextColor);

        &:hover{
            // text-decoration: underline;
            cursor: pointer;
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;
        }
    }


}




.view-order-map {
    height: 100%;
    width: 89.5%;

    // margin-right: 0.0428%;
    // border: 2px solid black;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid rgb(24, 204, 8);

    &__topInfobar {
        height: 10%;
        width: 91.6%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // border: 1px solid rgb(32, 100, 6);
        // display: none;

        &__designation-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            font-size: calc(24px * var(--heightmultiplier));
            border: 2px solid #FD6125;
            border-radius: calc(10px * var(--heightmultiplier));
            box-sizing: border-box;
            overflow: hidden;
            color: var(--primaryTextColor);
            // margin-top: ;

            &__title{
                font-size: calc(24px * var(--heightmultiplier));
                font-weight: 600;
                background-color: #FD6125;
                color: white;
                padding: calc(5px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));

            }
            &__value{
                font-size: calc(20px * var(--heightmultiplier));
                font-weight: 400;
            }
        }

        &__header {
            width: 100%;
            height: 8.2%;
            // border-bottom: 1px solid var(--secondaryTextColor);
            // border: 1px solid var(--primaryTextColor);
            // border-radius: calc(16px * var(--heightmultiplier));
            display: flex;
            justify-content: end;
    
            &__userIcon {
                width: calc(200px * var(--widthmultiplier));
                height: 100%;
                // background-color: var(--secondaryColor);
                // border: 1px solid red;
                display: flex;
                align-items: center;
                justify-content: center;
    
                &__proImg {
                    height: 3em;
                    width: 3em;
                    // min-width: 50px;
                    // margin-left: 1em;
                    box-sizing: border-box;
                    border-radius: 50%;
                    background-color: #FD6125;
                    color: var(--primaryColor);
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
    
                    &:hover {
                        cursor: pointer;
                    }
                }
    
                &__username {
                    width: fit-content;
                    height: 100%;
                    padding: calc(5px * var(--heightmultiplier)) calc(12px * var(--widthmultiplier));
                    // border: 1px solid green;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &__SelectLocations-container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;


            &__buttons {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &__btn {
                    // padding: 0.3em;
                    width: fit-content;
                    height: 48%;
                    text-align: center;
                    border-radius: 0.625em;
                    background-color: rgba(#FD6125, 0.1);
                    font-weight: 500;
                    font-size: 1em;
                    box-shadow: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 1em;
                    // border-width: 0.2em;
                    color: #FD6125;
                    box-sizing: border-box;
                    // box-shadow: 0px 0px 5px #FD6125;

                    &:hover {
                        // border-color: #f47746;
                        outline: none;
                        // border-width: 0.25em;
                        cursor: pointer;
                        color: white;
                        background-color: #FD6125;
                    }
                }
            }

            &__locations {
                box-sizing: border-box;
                // width: fit-content;
                padding: 0px 0.5em;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-around;
                // border: 2px solid black;
                // height: 100%;
            }

        }

        &__connectBtn {
            justify-self: flex-start;
            box-sizing: border-box;
            // padding: 10px 20px;
            width: 15%;
            font-size: 1em;
            width: 12.45%;
            height: 42%;
            background-color: #FD6125;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: black;
            border-radius: 0.5em;
            transition: all 0.1s linear;

            &:hover {
                cursor: pointer;
                background-color: rgba($color: #e82222, $alpha: 1.0);
                font-weight: 600;
            }
        }

        &__instuctionNStatus {
            justify-self: flex-start;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 90%;
            width: 70%;
            border: 2px solid rgba(#FD6125, 0.5);
            border-radius: 5px;
            box-sizing: border-box;
            overflow-y: scroll;



            &__message {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-items: center;
                width: 90%;
                height: 100%;
                font-size: 1em;
                // border: 2px solid black;

                // &__msg{
                    
                // }
            }
        }



        // &__user {
        //     height: 100%;
        //     // width: 37.11%;
        //     margin-right: 0px;
        //     display: flex;
        //     align-items: center;
        //     justify-self: baseline;
        //     // border: 1px solid red;

        //     &__proImg {
        //         height: 3em;
        //         width: 3em;
        //         // min-width: 50px;
        //         margin-left: 1em;
        //         box-sizing: border-box;
        //         border-radius: 50%;
        //         background-color: #FD6125;
        //         color: var(--primaryColor);
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
                

        //         &:hover{
        //             cursor: pointer;
        //         }
        //     }

        //     &__name {
        //         font-size: 1.5em;
        //     }
        //     &__username{
        //         width: fit-content;
        //         height: 100%;
        //         padding: calc(5px * var(--heightmultiplier))  calc(8px * var(--widthmultiplier));
        //         border: 1px solid green;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }
        // }


    }

    &__forms-container {
        box-sizing: border-box;
        position: relative;
        height: 83.32%;
        width: 100%;
        // border-radius: 30px;
        border: 2px solid #FD6125;
        border-left: none;
        // overflow: hidden;
        // border: 1px solid rgb(212, 8, 8);
        background-color: var(--primaryColor);

        // &::before{
        //     position: absolute;
        //     box-sizing: border-box;
        //     left: 0px;
        //     top: 0px;
        //     width: 100%;
        //     height: 50%;
        //     // border: 2px solid #FD6125;
        //     border-left: none;
        //     border-bottom: none;
        //     content: "";
        // }
        // &::after{
        //     position: absolute;
        //     box-sizing: border-box;
        //     left: 0px;
        //     bottom: 0px;
        //     width: 100%;
        //     height: 65.7%;
        //     // border: 2px solid #FD6125;
        //     border-top: none;
        //     // background-color: red;
        //     content: "";
        // }

        &__webcam-container{
            width: 100%;
            height: 100%;
            &__webcam{
                width: auto;
                height: 100%;
            }
        }
    }

    &__footer-container {
        position: relative;
        height: 6.66%;
        width: 91.6%;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 2px solid rgb(221, 68, 8);
        &__copyright {
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: calc(20px * var(--widthmultiplier));

            &__company-logo {
                width: auto;
                height: calc(50px * var(--heightmultiplier));
            }

            &__title {
                color: #878B8F;
                font-size: 1em;
            }
        }
    }

}

.supportlink{
    text-decoration: none;
    color: rgb(24, 24, 24);
    &:hover{
        text-decoration: none;
        color: rgb(24, 24, 24);
    }
}
.ReportbugsandErrors{
    text-decoration: none;
    color: var(--primaryTextColor);
    &:hover{
        text-decoration: none;
        color: var(--primaryTextColor);
    }
}