

  .experience {
    border-left: 3px solid #FD6125;
    padding: 0 30px;
    margin-left: 200px;
    font-size:calc(20px * var(--widthmultiplier));
    margin-top: calc(1rem * var(--widthmultiplier));
    height: 80%;
  }

  .fa-ul{
    list-style: none;
    display:flex;
    flex-direction: column;
    align-items: start;
  }
  
  @media (max-width: 767px) {
    .experience {
      margin-left: 0;
      padding-right: 0;
    }
  }
  
  .experience .item {
    position: relative;
    margin-bottom: 40px;
  }
  
  .experience .item::before {
    content: '';
    position: absolute;
    left: -43px;
    top: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #F0F0F0; 
    border: 3px solid #FD6125; 
  }
  
  .experience .item::after {
    content: '';
    position: absolute;
    left: -37px;
    top: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FD6125; 
  }
  
 
  .experience .done-by {
    color: #FD6125;
    font-size:calc(24px * var(--widthmultiplier));
    font-weight: 500;
    text-align: start;
  }
  
 
  .experience .location {
    position: absolute;
    right: 0;
    top: 2px;
  }
  
  // .experience .location .fa {
  //   // margin-right: 8px;
  //   align-items: start;
  // }
  

  .experience .job-info {
    position: absolute;
    left: -185px;
    top: 0;
  }
  
  .experience .job-info .date {
    color: #FD6125; 
  }
  
  @media (max-width: 767px) {
    .experience .job-info {
      position: static;
      margin-bottom: 1rem;
    }
  }
  