.FlightStatusIndicator-container {
    position: relative;
    width: 100%;
    //height: 10px;
    height:  100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //margin-top: calc(10px * var(--heightmultiplier));
     //border: 1px solid yellow;

    &__timeline {
        box-sizing: border-box;
        position: absolute;
        // top: calc(6px * var(--heightmultiplier));
        // left: calc(9px * var(--widthmultiplier));
        top: calc(5px * var(--heightmultiplier));
        left: calc(9px * var(--widthmultiplier));
        height: calc(9px * var(--heightmultiplier));
        width: 99%;
        // margin: calc(5px * var(--heightmultiplier)) calc(18px * var(--widthmultiplier));
        background-color: #FDD5C5;
        z-index: 0;
        // border: 1px solid green;


        &__bar{
            background-color: #FD6125;
            height: 100%;
            width: 0%;
            transition: all 2.1s linear;
        }
    }

    &__status-tags {
        position: relative;
        width: 100%;
        // width: calc(px * var(--widthmultiplier));
        height: 100%;
        z-index: 2;
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // border: 1px solid blue;

        &__start {
            height: 100%;
            // width: 33.333%;//calc(120px * var(--widthmultiplier));
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            // border: 1px solid red;
            font-family: 'General Sans';
            font-style: normal;
            font-weight: 400;
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(22px * var(--heightmultiplier));

            &__circle {
                width: calc(18px * var(--widthmultiplier));
                height: calc(18px * var(--heightmultiplier));
                border-radius: 50%;
                // background-color: rgba($color: #FD6125, $alpha: 0.5);
                background-color: #FDD5C5;
            }

            &__tag {
                box-sizing: border-box;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                //border: 1px solid yellow;
                margin-top: calc(15px * var(--heightmultiplier));
            }
        }
        &__intermediate {
            position: absolute;
            height: 100%;
            // width: 33.333%;//calc(120px * var(--widthmultiplier));
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            // border: 1px solid red;
            font-family: 'General Sans';
            font-style: normal;
            font-weight: 400;
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(22px * var(--heightmultiplier));

            &__circle {
                width: calc(18px * var(--widthmultiplier));
                height: calc(18px * var(--heightmultiplier));
                border-radius: 50%;
                // background-color: rgba($color: #FD6125, $alpha: 0.5);
                background-color: #FDD5C5;
            }

            &__tag {
                position: relative;
                left: -50%;
                box-sizing: border-box;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                //border: 1px solid yellow;
                margin-top: calc(15px * var(--heightmultiplier));
            }
        }

        &__end {
            position: absolute;
            height: 100%;
            // width: 33.333%;//calc(120px * var(--widthmultiplier));
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            // border: 1px solid red;
            font-family: 'General Sans';
            font-style: normal;
            font-weight: 400;
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(22px * var(--heightmultiplier));

            &__circle {
                width: calc(18px * var(--widthmultiplier));
                height: calc(18px * var(--heightmultiplier));
                border-radius: 50%;
                // background-color: rgba($color: #FD6125, $alpha: 0.5);
                background-color: #FDD5C5;
            }

            &__tag {
                box-sizing: border-box;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                //border: 1px solid yellow;
                margin-top: calc(15px * var(--heightmultiplier));
            }
        }

       
    }

}