.flightUploadInput {
    //  border: 1px solid red;
    width: 100%;
    height: 100%;

    &__subContainer {
        width: 100%;
        height: 100%;
        // border: 1px solid rgb(51, 255, 0);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;


        &__forward {
            width: 100%;
            height: 100%;
            border: 2px solid var(--primaryTextColor);
            border-radius: calc(10px * var(--heightmultiplier));
            padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            text-align: center;

            &__filedContainer {
                width: 100%;
                height: fit-content;
                // border: 1px solid red;
                display: flex;
                text-align: center;
                align-items: center;
                //justify-content: space-around;
                justify-content: center;
            }
            &__flightLogField{
                width: 100%;
                height: fit-content;
                // border: 1px solid red;
                display: flex;
                flex-direction: column;
                align-items: center;
                //justify-content: space-around;
                justify-content: center;
            }

            &__title {
                width: 36%;
                font-weight: 500;
                font-size: calc(18px * var(--heightmultiplier));
                //    border: 1px solid pink;
                margin-right: calc(10px * var(--heightmultiplier));
            }

            &__logFile {
                width: 100%;
                font-weight: 500;
                font-size: calc(16px * var(--heightmultiplier));
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                //    border: 1px solid pink;
                margin: calc(10px * var(--heightmultiplier));
                &__title {
                    width: 30%;
                    font-weight: 500;
                    font-size: calc(20px * var(--heightmultiplier));
                    // border: 1px solid pink;
                    margin-right: calc(10px * var(--heightmultiplier));
                }
                &__value {
                    width: 70%;
                    font-weight: 500;
                    font-size: calc(16px * var(--heightmultiplier));
                    // border-bottom: 2px solid rgb(95, 95, 95);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    // margin-right: calc(10px * var(--heightmultiplier));
                }
            }

            &__fileName {
                width: 100%;
                height: 20%;
                font-size: calc(26px * var(--heightmultiplier));
                line-height: calc(26px * var(--heightmultiplier));
            }

            &__textFieldError {
                width: 50%;
                height: 60%;
                // border: 1px solid rgb(255, 238, 0);
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                //justify-content: space-around;
                justify-content: center;

            }

            &__progress-bar {
                position: relative;
                width: 50%;
                height: calc(20px * var(--heightmultiplier));
                border: 1px solid rgb(126, 126, 125);
                font-size: calc(8px * var(--heightmultiplier));
                font-weight: bold;
                margin: 5px 10px;
            }

            &__progress-bar-inner {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background-color: #1f721c;
                border-radius: calc(2px * var(--heightmultiplier));
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                color: white;
                line-height: 10px;
            }

            &__progress-bar-number {
                position: absolute;
                z-index: 100;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: transparent;
                border-radius: calc(2px * var(--heightmultiplier));
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                color: white;
                line-height: calc(20px * var(--heightmultiplier));
                font-size: calc(18px * var(--heightmultiplier));
            }

            &__progress-bar-success {
                height: 100%;
                // background-color: #1f721c;
                // border-radius:  calc(2px * var(--heightmultiplier));
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                color: #1f721c;
                line-height: 10px;
            }

            &__textField {
                width: 100%;
                height: fit-content;
                border: 1px solid red;
                background-color: lightgrey;
                font-size: calc(20px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));
            }

            &__hidden {
                display: none;
            }
            &__buttonBox{
                // border: none;
                width: fit-content;
                height: fit-content;
                // border: 1px solid red;
                // border-radius: calc(10px * var(--heightmultiplier));
            }
            &__val{
                font-size: calc(12px * var(--heightmultiplier));
            }
            &__button {
                border: none;
                width: fit-content;
                font-size: calc(20px * var(--heightmultiplier));
                font-weight: 500;
                height: calc(45px * var(--heightmultiplier));
                border-radius: calc(10px * var(--heightmultiplier));
                // border-radius: calc(10px * var(--heightmultiplier));
                background-color: rgba($color: #fd6125, $alpha: 0.1);
                color: #fd6125;
                margin: calc(0px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier));

                &:hover {
                    cursor: pointer;
                    color: white;
                    background-color: rgba($color: #fd6125, $alpha: 1);
                }
            }

            &__DivBtn {
                width: 100%;
                height: fit-content;
                // border: 1px solid blue;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: space-evenly;

                &__UploadbtnActive {
                    border: none;
                    width: fit-content;
                    font-size: calc(20px * var(--heightmultiplier));
                    line-height: calc(20px * var(--heightmultiplier));
                    font-weight: 500;
                    height: 100%;
                    border-radius: calc(10px * var(--heightmultiplier));
                    // border-radius: calc(10px * var(--heightmultiplier));
                    background-color: rgba($color: #6CCC54, $alpha: 0.2);
                    border: 2px inset #6CCC54;
                    color: var(--primaryTextColor);
                    padding: calc(10px * var(--heightmultiplier));

                    // margin: 05px;

                    &:hover {
                        cursor: pointer;
                        background-color: rgba($color: #6CCC54, $alpha: 1);
                        color: white;
                    }
                }

                &__UploadbtnInActive {
                    border: none;
                    width: fit-content;
                    font-size: calc(20px * var(--heightmultiplier));
                    line-height: calc(20px * var(--heightmultiplier));
                    font-weight: 500;
                    height: 100%;
                    border-radius: calc(10px * var(--heightmultiplier));
                    // border-radius: calc(10px * var(--heightmultiplier));
                    background-color: rgba($color: #c4c5c2, $alpha: 0.2);
                    border: 2px solid #60615f;
                    color: #737473;
                    padding: calc(10px * var(--heightmultiplier));
                }

                &__disable {
                    border: none;
                    width: 20%;
                    font-size: calc(20px * var(--heightmultiplier));
                    line-height: calc(20px * var(--heightmultiplier));
                    font-weight: 500;
                    height: 60%;
                    border-radius: calc(10px * var(--heightmultiplier));
                    // border-radius: calc(10px * var(--heightmultiplier));
                    background-color: rgba($color: lightgray, $alpha: 0.2);
                    border: 2px inset lightgray;
                    color: lightgray;
                    padding: 0px;
                }

                &__exportBtn {
                    border: none;
                    width: 30%;
                    font-size: calc(20px * var(--heightmultiplier));
                    line-height: calc(20px * var(--heightmultiplier));
                    font-weight: 500;
                    height: 60%;
                    border-radius: calc(10px * var(--heightmultiplier));
                    // border-radius: calc(10px * var(--heightmultiplier));
                    background-color: rgba($color: #4285F4, $alpha: 0.2);
                    border: 2px inset #4285F4;
                    color: var(--primaryTextColor);
                    padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));

                    // margin: 05px;

                    &:hover {
                        cursor: pointer;
                        background-color: rgba($color: #4285F4, $alpha: 1);
                        color: white;
                    }
                }
            }

        }
    }
}