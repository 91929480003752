.AddMissionPopup-modalBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /* border: 1px solid black; */
}



.AddMissionPopup-modalContainer {
    width: 70%;
    height: 80%;

    border-radius: 0.75em;

    background-color: var(--primaryColor);
    ;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    //border: 5px solid #FC6025;
    /* padding-top: calc(50px);
        padding-bottom: calc(50px); */

    padding: calc(15px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));

    font-size: calc(50px * var(--heightmultiplier));
    box-shadow: 0px 0px 15px #FD6125;

    &__subContainer {
        width: 100%;
        height: 100%;
        // border: 1px solid red;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;


        &__forward {
            width: 100%;
            height: 40%;
            border: 2px solid var(--primaryTextColor);
            border-radius: calc(10px * var(--heightmultiplier));
            padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            text-align: center;
            &__filedContainer{
                width: 100%;
                height: 40%;
                //border: 1px solid red;
                display: flex;
                text-align: center;
                align-items: center;
                //justify-content: space-around;
                justify-content: center;
            }

            &__fileName {
                width: 100%;
                height: 20%;
                font-size: calc(26px * var(--heightmultiplier));
                line-height: calc(26px * var(--heightmultiplier));
            }
            &__textFieldError{
                width: 50%;
                height: 60%;
               // border: 1px solid red;
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                //justify-content: space-around;
                justify-content: center;
                
            }

            &__textField {
                width: 100%;
                height: fit-content;
                border: 1px solid red;
                background-color: lightgrey;
                font-size: calc(20px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));
            }

            &__hidden {
                display: none;
            }

            &__button {
                border: 1px solid #fd6125;
                width: 20%;
                // height: 10%;
                font-size: calc(20px * var(--heightmultiplier));
                font-weight: 500;
                height: calc(45px * var(--heightmultiplier));
                border-radius: calc(10px * var(--heightmultiplier));
                // border-radius: calc(10px * var(--heightmultiplier));
                background-color: rgba($color: #fd6125, $alpha: 0.1);
                color: #fd6125;
                margin: 0px;

                &:hover {
                    cursor: pointer;
                    // border: 2px solid #120eee;
                    color: white;
                    background-color: rgba($color: #fd6125, $alpha: 1);
                }
            }

            &__DivBtn {
                width: 100%;
                height: 40%;
                //border: 1px solid blue;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: space-evenly;
                
                &__Uploadbtn {
                    border: none;
                    width: 20%;
                    font-size: calc(20px * var(--heightmultiplier));
                    line-height: calc(20px * var(--heightmultiplier));
                    font-weight: 500;
                    height: 60%;
                    border-radius: calc(10px * var(--heightmultiplier));
                    // border-radius: calc(10px * var(--heightmultiplier));
                    background-color: rgba($color: #6CCC54, $alpha: 0.2);
                    border: 2px inset #6CCC54;
                    color: var(--primaryTextColor);
                    padding: 0px;

                   // margin: 05px;
    
                    &:hover {
                        cursor: pointer;
                        background-color: rgba($color: #6CCC54, $alpha: 1);
                        color: white;
                    }
                }
                &__disable{
                    border: none;
                    width: 20%;
                    font-size: calc(20px * var(--heightmultiplier));
                    line-height: calc(20px * var(--heightmultiplier));
                    font-weight: 500;
                    height: 60%;
                    border-radius: calc(10px * var(--heightmultiplier));
                    // border-radius: calc(10px * var(--heightmultiplier));
                    background-color: rgba($color: lightgray, $alpha: 0.2);
                    border: 2px inset lightgray;
                    color: lightgray;
                    padding: 0px;
                }
                &__exportBtn {
                    border: none;
                    width: 22%;
                    font-size: calc(20px * var(--heightmultiplier));
                    line-height: calc(20px * var(--heightmultiplier));
                    font-weight: 500;
                    height: 60%;
                    border-radius: calc(10px * var(--heightmultiplier));
                    // border-radius: calc(10px * var(--heightmultiplier));
                    background-color: rgba($color: #4285F4, $alpha: 0.2);
                    border: 2px inset #4285F4;
                    color: var(--primaryTextColor);
                    padding: 0px;

                   // margin: 05px;
    
                    &:hover {
                        cursor: pointer;
                        background-color: rgba($color: #4285F4, $alpha: 1);
                        color: white;
                    }
                }
            }

        }
    }

}