.MainAllUsersTab {
    width: 100%;
    height: 100%;
}

.AllUsersTabContent-container {
    position: relative;
    top: 0px;
    left: 0px;
    // z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: calc(10px * var(--heightmultiplier));
    &__filter {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: calc(50px * var(--heightmultiplier));
        width: 97%;
        height: 7%;
        &__title {
            // background-color: #FD6125;
            color: var(--primaryTextColor);
            font-size: calc(30px * var(--heightmultiplier));
        }

        &__options {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid #FD6125;
            height: 80%;
            // padding-left: calc(20px * var(--heightmultiplier));

            &__item {
                color: var(--primaryTextColor);
                font-size: calc(24px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #fd6125, $alpha: 0.1);

                &:hover {
                    background-color: rgba($color: #fd6125, $alpha: 0.5);
                    border-color: #fd6125;
                    color: white;
                }
            }

            &__selected-item {
                color: white;
                font-size: calc(24px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #fd6125, $alpha: 1);

                // &:hover {
                //     background-color: rgba($color: #FD6125, $alpha: 0.5);
                //     border-color: #FD6125;
                //     color: white;
                // }
            }
        }

        &__duration-title {
            color: var(--primaryTextColor);
            font-size: calc(24px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
        }

        &__duration-select {
            color: black;
            font-size: calc(20px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-color: #fd6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            // &__option{

            // }
        }
    }

    &__index {
        position: sticky;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: calc(5px * var(--heightmultiplier)) 0px;
        background-color: rgba($color: #fd6125, $alpha: 1);
        color: white;
        // margin-top: calc(10px * var(--heightmultiplier));
        border-radius: 5px;
        // margin: calc(5px * var(--heightmultiplier))
        //   calc(5px * var(--widthmultiplier));
        // margin-right: 11px;
        // font-size: calc(24px * var(--heightmultiplier));
        // font-weight: 500;
        //font-size: calc(20px * var(--heightmultiplier));
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 30%;
        //height: 100%;
        width: 98%;

        //border: 1px solid blue;
        &__term {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
            font-size: calc(22px * var(--heightmultiplier));
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            // width: 30%;
            //border: 1px solid black;
            border-right: 1px solid white;
            border-left: 1px solid white;
            height: 100%;
            -moz-user-select: -moz-none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            user-select: none;

            // &:hover{
            //     cursor: pointer;
            // }
        }

        &__SNo {
            width: 7%;
            // border: 1px solid black;
            border-left: none;
        }

        &__userName {
            width: 10%;
            // border: 1px solid black;
        }

        &__userEmail {
            width: 18%;
            // border: 1px solid black;
        }

        &__Phone_no {
            width: 10%;
            // border: 1px solid black;
        }

        &__date_birth {
            width: 10%;
            // border: 1px solid black;
        }

        &__gender {
            width: 7%;
            // border: 1px solid black;
        }

        &__permission {
            width: 9%;
            // border: 1px solid black;
        }

        &__hub_location {
            width: 11%;
            // border: 1px solid black;
        }

        &__delivery_loc {
            width: 15%;
            // border-right: none;
            // border: 1px solid black;
        }

        &__approval {
            width: 9%;
            // border: 1px solid black;
            //border-right: none;
        }
        &__getDetails {
            width: 11%;
            // border: 1px solid black;
            border-right: none;
        }
    }
}

.AllUsersTabContent-datacontainer {
    position: relative;
    top: 0px;
    left: 0px;
    // z-index: 10;
    width: 100%;
    //  height: calc(100px * var(--heightmultiplier));
     height: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
   // border: 1px solid red;
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        height: fit-content;
        //padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: rgba($color: #FD6125, $alpha: 0.1);
        margin-top: 10px;
        border-radius: 5px;
        //overflow-y: scroll;
        &:nth-child(odd) {
            background-color: rgba($color: #E2E2E2, $alpha: 0.3); // Light gray for odd rows
          }
      
          &:nth-child(even) {
            background-color: rgba($color: #FD6125, $alpha: 0.2); // White for even rows
          }

        &__term {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: calc(20px * var(--heightmultiplier));
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
            //margin-right: 11px;
            // width: 30%;
            // border: 1px solid blue;
            //overflow-y: scroll;
            overflow: hidden;
            padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            border-right: 1px solid #fd6125;
            border-left: 1px solid #fd6125;
            height: 100%;
        }

        &__SNo {
            width: 7%;
            // border: 1px solid black;
            border-left: none;
        }

        &__userName {
            width: 10%;
            // border: 1px solid black;
        }

        &__userEmail {
            width: 18%;
            // border: 1px solid black;
        }

        &__Phone_no {
            width: 10%;
            // border: 1px solid black;
        }

        &__date_birth {
            width: 10%;
            // border: 1px solid black;
        }

        &__gender {
            width: 7%;
            // border: 1px solid black;
        }

        &__permission {
            width: 9%;
            // border: 1px solid black;
        }

        &__hub_location {
            width: 11%;
            // border: 1px solid black;
        }

        &__delivery_loc {
            width: 15%;
            // border-right: none;
            // border: 1px solid black;
        }

        &__approval {
            width: 9%;
            // border: 1px solid black;
            //border-right: none;
        }
        &__getDetails {
            width: 11%;
            // border: 1px solid black;
            border-right: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            &__options{
              border: 1px solid #fd6125;
              border-radius: calc(10px * var(--heightmultiplier));
              padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
              background-color: rgba($color: #fd6125, $alpha: 0.5);
              font-size: calc(18px * var(--heightmultiplier));
              //border: none;
              //height: 50%;
              color: var(--primaryTextColor);
      
              &:hover {
                cursor: pointer;
                background-color: rgba($color: #fd6125, $alpha: 1);
                color: white;
              }
            }
        }
    }
}