.SitlContainer {
    width: calc(700px * var(--heightmultiplier));
    margin: 0 auto;
    padding: calc(20px * var(--heightmultiplier));
    background-color: transparent;
    // border-radius: calc(8px * var(--heightmultiplier));
    // box-shadow: 0 calc(4px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier)) rgba(0, 0, 0, 0.1);
    
    &__heading {
      text-align: center;
      color: var(--primaryTextColor);
      margin-bottom: calc(20px * var(--heightmultiplier));
      font-weight: 600;
      font-size: calc(48px * var(--heightmultiplier));
    }
    
    &__form {
      display: flex;
      flex-direction: column;
      
      &__inputContainer {
        // margin-bottom: calc(15px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        flex-direction: column;
        // justify-content: center;
        // gap: calc(10px * var(--heightmultiplier));
        &__inputinnerContainer{
            width: 100%;
            margin-bottom: calc(15px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            gap: calc(10px * var(--heightmultiplier));

            &__error {
                color: red;
                font-size: 12px;
                margin-top: 5px;
              }
              &__droneId{
                border: none !important;
              }
              &__colon{
                font-size:calc(24px * var(--heightmultiplier)) ;
              }
              &__getStatus{
                text-align: center;
                // width: 60%;
                padding: calc(10px * var(--heightmultiplier));
                font-size: calc(22px * var(--heightmultiplier));
                color: #fff;
                background-color: #007bff;
                border: none;
                border-radius: calc(4px * var(--heightmultiplier));
                cursor: pointer;
                transition: background-color 0.3s ease;
                
                &:hover {
                  background-color: #0056b3;
                }
              }
          }
        label {
          display: block;
          width: calc(150px * var(--heightmultiplier));
          margin-bottom: calc(5px * var(--heightmultiplier));
          font-weight: 500;
          font-size: calc(24px * var(--heightmultiplier));
          text-align: start;
        }
        
        input {
        //   width:;
        flex-grow: 1;
          padding: calc(8px * var(--heightmultiplier));
          font-size: calc(22px * var(--heightmultiplier));
          border: 1px solid var(--primaryTextColor);
          border-radius: calc(4px * var(--heightmultiplier));
          outline: none;
          transition: border-color 0.3s ease;
          background-color: var(--primaryColor);
          
          &:focus {
            border-color: #007bff;
          }
        }
        
        
      }
     
      &__submit{
        display: flex;
        align-items: center;
        justify-content: space-around;
      &__startBtn {
        text-align: center;
        // width: 60%;
        padding: calc(10px * var(--heightmultiplier));
        font-size: calc(22px * var(--heightmultiplier));
        color: #fff;
        background-color: #007bff;
        border: none;
        border-radius: calc(4px * var(--heightmultiplier));
        cursor: pointer;
        transition: background-color 0.3s ease;
        
        &:hover {
          background-color: #0056b3;
        }
    }
        &__killBtn{
            text-align: center;
            // width: 60%;
            padding: calc(10px * var(--heightmultiplier));
            font-size: calc(22px * var(--heightmultiplier));
            color: #fff;
            background-color: #007bff;
            border: none;
            border-radius: calc(4px * var(--heightmultiplier));
            cursor: pointer;
            transition: background-color 0.3s ease;
            
            &:hover {
                background-color: #0056b3;
              }
        }
      }
    }
    
  }
  