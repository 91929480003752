.HelpContentTopicRow-container{
    // width: 100%;
    width: fit-content;
    text-align: start;
    // padding: 1px 10px;
    // border: 1px solid var(--primaryTextColor);

    &:hover{
        cursor: pointer;
        text-decoration: underline;
        color: rgb(61, 133, 240);
    }
}