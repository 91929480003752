.HelpTopicPoints-container{
    height: 300px;
    width: 100%;
    margin: 10px 0px;
    border: 1px solid var(--primaryTextColor);
    
    &__topicLabel{
        display: flex;
        align-items: center;
        justify-content: flex-start;


        &__backBtn{
            margin-left: 50px;

            &:hover{
                cursor: pointer;
                text-decoration: underline;
                color: rgb(61, 133, 240);
            }
        }
    }

    
}