.CreateNewNode-background {
    width: 100%;
    height: 100%;
    // border: 1px solid red;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
    background: rgba(6, 6, 6, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &__close {
        border: 1px solid red;
        position: absolute;
        right: calc(50px * var(--widthmultiplier));
        top: calc(50px * var(--widthmultiplier));
        background: rgba(6, 6, 6, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.CreateNewNode-maincontainer {
    width: 90%;
    height: 90%;
    // border: 2px solid green;
    display: flex;
    flex-direction: column;
    background-color: var(--primaryColor);
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 30px #fd6125;
    border-radius: calc(20px * (var(--heightmultiplier) * var(--widthmultiplier)) / 2);

    &__header {
        width: 90%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // border: 1px solid green;
        margin-top: calc(10px * var(--heightmultiplier));
        gap: calc(20px * var(--heightmultiplier));

        &__itemsCont {
            // width: 90%;
            // height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            // border: 1px solid red;
            gap: calc(40px * var(--widthmultiplier));
            font-size: calc(20px * var(--heightmultiplier));

            &__item {
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: space-between;



                &__active {
                    color: #fd6125;
                    font-weight: 500;
                    cursor: default;
                }

                &__preActive {
                    color: var(--primaryTextColor);
                    font-weight: 500;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &__postActive {
                    color: #c1c2c4;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &__arrow {
                    width: calc(40px * var(--widthmultiplier));
                    height: auto;
                }
            }
        }

        &__progress-cont {
            width: 100%;

            height: calc(3px * var(--heightmultiplier));
            border-radius: calc(3px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: start;
            background-color: #E3E3E3;

            &__progress {
                height: 100%;
                background-color: #FD6125;
                transition: all ease-in-out;
                transition-duration: 1000ms;
            }
        }
    }

    &__body {
        width: 80%;
        height: 80%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: auto;
        row-gap: calc(16px * var(--heightmultiplier));
        // border: 1px solid blue;

        &__row {
            width: 80%;
            display: flex;
            gap: calc(16px * var(--heightmultiplier));
            // border: 1px solid green;
        }
        &__rowtest{
            width: 80%;
            display: flex;
            gap: calc(16px * var(--heightmultiplier));
            // border: 1px solid green;
            
            &__col{
                width: 100%;
                // border: 1px solid red;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: calc(16px * var(--heightmultiplier));
            }
        }
    }


    &__footer {
        width: 90%;
        height: 8%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 2px solid lightgray;

        &__nextBtn {
            margin-right: calc(75px * var(--widthmultiplier));
            background-color: #FD6125;
            padding: calc(7px * var(--heightmultiplier)) calc(24px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            border: none;
            outline: none;
            color: white;
            border-radius: calc(8px * var(--heightmultiplier));
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(20px * var(--heightmultiplier));
        }
    }

    // border-def1 border-te-gray rounded-def flex flex-col items-start w-3/6 p-5
    &__InputDetailField {
        width: 100%;
        border:2px solid gray;
        border-radius: calc(16px * var(--heightmultiplier));
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--heightmultiplier));
        gap: calc(10px * var(--heightmultiplier));

        &__label {
            // text-te-text-gray text-sm font-medium
            color: gray;
            font-size: calc(14px * var(--heightmultiplier));
            line-height: calc(16px * var(--heightmultiplier));
            font-weight: 500;

            &__mandatory{
                color: rgb(226, 50, 50);
            }
        }

        // / text-base font-medium border-b border-te-gray outline-0 w-full
        &__input {
            // text-te-text-gray text-sm font-medium
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(24px * var(--heightmultiplier));
            font-weight: 500;
            width: 100%;
            border: none;
            outline: none;
        }
    }

}