.UserDetailsPopup-modalBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /* border: 1px solid black; */
}



.UserDetailsPopup-modalContainer {
    width: 70%;
    height: 70%;

    border-radius: 0.75em;

    background-color: var(--primaryColor);
    ;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* border: 5px solid #FC6025; */
    /* padding-top: calc(50px);
    padding-bottom: calc(50px); */

    //padding: calc(50px * var(--widthmultiplier));

    font-size: calc(50px * var(--heightmultiplier));
    box-shadow: 0px 0px 15px #FD6125;
    padding: calc(20px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));

    &__heading {
        width: 100%;
        font-weight: 500;
        height: 10%;
        font-size: calc(32px * var(--heightmultiplier));
        //  border: 1px solid blue;
    }

    &__Conatiner {
        width: 90%;
        height: 95%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-between;

        //border: 1px solid blue;
        &__left {
            width: 50%;
            height: inherit;
            //border: 1px solid blue;
        }

        &__right {
            width: 50%;
            height: inherit;
            // border: 1px solid blue;
        }
    }

    &__item {
        font-size: calc(20px * var(--widthmultiplier));
        margin-top: calc(5px * var(--heightmultiplier));
        margin-bottom: calc(5px * var(--heightmultiplier));
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        justify-content: space-between;
        padding: calc(0px * var(--heightmultiplier))calc(10px * var(--widthmultiplier));

        &__title {
            width: 50%;
            font-weight: 500;
        }

        &__value {
            width: 60%;
            padding-left: 10px;
        }
    }

    &__Update {
        font-size: calc(20px * var(--widthmultiplier));
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        text-align: left;
        justify-content: space-between;
        padding: calc(0px * var(--heightmultiplier))calc(10px * var(--widthmultiplier));
        margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // border: 1px solid red;

        &__title {
            width: 30%;
            // height: 100%;
            // border: 1px solid green;
            display: flex;
            text-align: center;
            align-items: center;
        }
        &__options-container{
            width: 67%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            &__inputLabel{
                width: 40%;
                margin-left: 5px;
            }
        }

        &__value {
            width: 67%;
            // height: 100%;
            // border: 1px solid blue;
            border-radius: calc(10px * var(--heightmultiplier));
            margin: calc(0px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
            color: var(--primaryTextColor);
            display: flex;
            flex-direction: column;
            //text-align: center;
            align-items: flex-start;
            justify-content: space-between;


            &__select {
                width: 100%;
                height: 65%;
                font-size: calc(18px * var(--heightmultiplier));
                margin-top: calc(10px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
                border-radius: calc(10px * var(--heightmultiplier));
                border: 2px solid var(--primaryTextColor);
                display: flex;
                flex-direction: row;
                text-align: left;
                align-items: center;
                justify-content: space-around;
                //border: 1px solid pink;
                background-color: var(--primaryColor);
                color: var(--primaryTextColor);

                &__option {
                    width: 100%;
                    height: 80%;
                    outline: none;
                    border: none;
                    //border: 1px solid blue;
                    background-color: var(--primaryColor);
                    color: var(--primaryTextColor);
                }
            }
        }
    }

    &__OkayBtn {

        box-sizing: border-box;
        width: calc(150px * var(--widthmultiplier));
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 600;
        font-size: calc(40px * var(--heightmultiplier));
        background-color: rgba(253, 97, 37, 1);
        border-radius: 5px;
        color: white;

        &:hover {
            cursor: pointer;
            background-color: #FC6025;
        }
    }
}