.HelpContentPage-container{
    width: 100%;
    height: 97%;
    overflow-y: auto;
    overflow-x: hidden;
    border: 5px solid green;
    
    &__allTopics{
        width: 100%;
    }
}