.DroneDetailsPopup-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* border: 1px solid black; */
}

// .DroneDetailPopup-leftSide{
//   width: 50%;
//   height: fit-content;
// }
.DroneDetailsPopup-modalContainer {
  width: 80%;
  height: 80%;

  border-radius: 0.75em;

  background-color: var(--primaryColor);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 
  border: 2px solid #b63506;
  /* padding-top: calc(50px);
    padding-bottom: calc(50px); */

  //font-size: calc(24px * var(--heightmultiplier));
  box-shadow: 0px 0px 15px #fd6125;
  &__heading{
    width:100%;
    height: 10%;
    //border: 2px solid yellow;
    display: flex;
    // flex-direction: column;
     align-items: center;
     justify-content: space-evenly;
     font-size:  calc(32px * var(--heightmultiplier));
  }
  &__flexBox{
    width: 100%;
    height: 80%;
    padding: calc(5px * var(--heightmultiplier));
    display: flex;
   // flex-direction: column;
    align-items: center;
    justify-content: space-between;
    //border: 1px solid rgb(14, 144, 14);
    &__leftSide {
      width: 55%;
      height: 100%;
     // border: 1px solid black;
    }
    &__rightSide {
      width: 45%;
      height: 100%;
     //border: 1px solid black;
    }
    &__item {
      width: 100%;
      height: fit-content;
      font-size: calc(18px * var(--heightmultiplier));
      margin-top: calc(2px * var(--heightmultiplier));
      // margin-bottom: calc(5px * var(--heightmultiplier));
      
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: calc(0px * var(--heightmultiplier))
        calc(20px * var(--widthmultiplier));
     // border: 1px solid black;
      &__title {
        width: 40%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__value {
        width: 60%;
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__field {
      width: 90%;
      font-size: calc(18px * var(--widthmultiplier));
      margin-top: calc(5px * var(--heightmultiplier));
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: calc(0px * var(--heightmultiplier))
        calc(20px * var(--widthmultiplier));
      &__label {
        width: 50%;
        font-size: calc(16px * var(--heightmultiplier));
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__options{
        width: 50%;
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__select{
          width: 100%;
          border: 1px solid #fd6125;
          background-color: var(--secondaryColor);
          color: var(--primaryTextColor)
        }
      }
      &__colon {
        width: 2%;
        padding: calc(0px* var(--heightmultiplier)) calc(10px* var(--widthmultiplier));
        color: var(--primaryTextColor);
      }
      &__input {
        width: 50%;
        padding-left: 10px;
        outline: none;
        border: none;
        &__addValue{
          width: 100%;
          padding-left: 5px;
          color: var(--primaryTextColor);
          background-color: var(--secondaryColor);
          outline: none;
          border: none;
          border: 1px solid #fd6125;
        }
      }
    }
  }
  &__button {
    width: 100%;
    min-height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-around;
   // border: 1px solid black;
    margin-bottom: calc(10px * var(--heightmultiplier));
    &__OkayBtn {
      box-sizing: border-box;
      width: calc(120px * var(--widthmultiplier));
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Titillium Web";
      font-style: normal;
      font-weight: 600;
      font-size: calc(32px * var(--heightmultiplier));
      background-color: rgba($color: #fd6125, $alpha: 0.5);
      border-radius: 5px;
      color: white;
      border: 1px solid #fd6125;

      &:hover {
        cursor: pointer;
        background-color: #fc6025;
      }
    }
    &__Cancel{
      box-sizing: border-box;
      width: calc(120px * var(--widthmultiplier));
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Titillium Web";
      font-style: normal;
      font-weight: 600;
      font-size: calc(32px * var(--heightmultiplier));
      background-color: rgba($color: #fd6125, $alpha: 0.5);
      border-radius: 5px;
      color: white;
      border: 1px solid #fd6125;

      &:hover {
        cursor: pointer;
        background-color: #fc6025;
      }
    }
  }
}
