.FileUploadResultPopUp-modalBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    //  border: 1px solid blueviolet;
  }
  
  
  
  .FileUploadResultPopUp-modalContainer {
    width: 20%;
    height:25%;
  
    border-radius: 0.75em;
  
    background-color: var(--primaryColor);;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* border: 5px solid #FC6025; */
    /* padding-top: calc(50px);
    padding-bottom: calc(50px); */
   // border: 1px solid blueviolet;
  
    //padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
    
   // font-size: calc(50px * var(--heightmultiplier));
    box-shadow: 0px 0px 15px #FD6125;
    &__image {
        width: fit-content;
        font-weight: 500;
        height: fit-content;
        font-size: calc(32px * var(--heightmultiplier));
        //  border: 1px solid blue;
    }
    &__title{
        width: fit-content;
        font-weight: 500;
        height: fit-content;
        font-size: calc(32px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        // border: 1px solid blue;
    }
    &__message{
        width: fit-content;
        font-weight: 400;
        height: fit-content;
        font-size: calc(18px * var(--heightmultiplier));
        color: var(--primaryTextColor);
    }
  }
  
  