@import "../variables";



.view-all-drones-page-container {

    position: relative;

    height: 100%;
    width: 100%;
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: aquamarine;
    box-sizing: border-box;
    background-color: var(--secondaryColor);
    color: var(--primaryTextColor);
    font-family: "General Sans";
}

.view-all-drones-sidebar {
    // flex: 1;
    background-color: var(--primaryColor);
    // border: 2px solid #FD6125;
    // border-left-color: white;
    box-sizing: border-box;
    width: calc(300px * var(--widthmultiplier));
    height: 100%;
    border-top-right-radius: calc(20px * var(--widthmultiplier));
    border-bottom-right-radius: calc(20px * var(--widthmultiplier));
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__droneSelector {
        width: 95%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // border: 2px solid red;
        margin: calc(30px * var(--heightmultiplier));
        border-radius: calc(15px * var(--heightmultiplier));
        color: white;
        &__droneId-text{
            width: 100%;
            border: 1px solid white;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 400;
            text-align: center;
            padding: calc(5px * var(--heightmultiplier)) 0px;
            border-radius: calc(5px * var(--widthmultiplier));
            // padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        }
        &__droneId-text-selected{
            width: 100%;
            // border: 2px solid #FD6125;
            background-color: #FD6125;
            text-align: center;
            font-size: calc(17px * var(--heightmultiplier));
            font-weight: 500;
            padding: calc(5px * var(--heightmultiplier)) 0px;
            border-radius: calc(5px * var(--widthmultiplier));
            // padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        }
    }

    &__btnStopPlaning {
        font-size: calc(24px * var(--heightmultiplier));
        width: calc(145px * var(--widthmultiplier));
        height: calc(40px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #FD6125, $alpha: 0.2);
        border-radius: calc(10px * var(--heightmultiplier));
        font-weight: 500;
        margin-right: calc(30px * var(--widthmultiplier));
        border: none;
        color: var(--primaryTextColor);

        &:hover {
            // text-decoration: underline;
            cursor: pointer;
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;
        }
    }

    &__standard-data-container {
        box-sizing: border-box;
        width: 93%;
        margin-left: 2%;
        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: space-around;
        // border: 1px solid var(--primaryTextColor);
        margin-top: calc(10px * var(--heightmultiplier));

        &__view-all-drones-location {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: fit-content;
            padding: calc(5px * var(--widthmultiplier)) calc(10px * var(--widthmultiplier));
            border: 1px solid var(--primaryTextColor);
            padding-top: 0px;
            border-radius: calc(6px * (var(--widthmultiplier) + var(--widthmultiplier) / 2));

            &__title {
                margin-bottom: calc(10px * var(--heightmultiplier));
                font-weight: 500;
            }

            &__latlng {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(110px * var(--widthmultiplier));

                &__input {
                    width: calc(80px * var(--widthmultiplier));
                }
            }
        }

        &__defaults-cont {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            border: 1px solid var(--primaryTextColor);
            border-radius: calc(6px * (var(--widthmultiplier) + var(--heightmultiplier) / 2));
            padding: calc(5px * var(--widthmultiplier)) calc(10px * var(--heightmultiplier));
            padding-top: 0px;

            &__title {
                margin-bottom: calc(10px * var(--heightmultiplier));
                font-weight: 500;
            }

            &__allparameters {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;



            }

            &__input {
                width: calc(60px * var(--widthmultiplier));
            }
        }
    }

    &__logout {
        font-size: calc(24px * var(--heightmultiplier));
        position: absolute;
        bottom: 10px;
        width: calc(145px * var(--widthmultiplier));
        height: calc(40px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #FD6125, $alpha: 0.2);
        border-radius: calc(10px * var(--heightmultiplier));
        font-weight: 500;
        margin-right: calc(30px * var(--widthmultiplier));
        border: none;
        color: var(--primaryTextColor);
        &:hover {
            // text-decoration: underline;
            cursor: pointer;
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;
        }
    }


    &__launchAllbutton {
        font-size: calc(24px * var(--heightmultiplier));
        width: 90%;
        height: calc(50px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #FD6125, $alpha: 0.5);
        border-radius: calc(10px * var(--heightmultiplier));
        font-weight: 500;
        border: none;
        color: var(--primaryTextColor);
        

        &:hover {
            // text-decoration: underline;
            cursor: pointer;
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;
        }
    }



    &__logodiv {
        height: 8%;
        width: 100%;
        // background: #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 5px 20px;
        // padding-right: 50px;

        &__logoimg {
            margin-left: 2em;
            height: 2.4em;
            width: 10em;

            &:hover {
                cursor: pointer;
            }
        }

        &__back {
            margin-right: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__logodiv-border {
        height: 2px;
        width: 90%;
        background-color: var(--secondaryColor);
    }

    &__DroneName {
        box-sizing: border-box;
        margin-top: 1.375em;
        // padding-left: 1.375;
        // margin-left: 2em;
        font-size: 1.5em;
        // height: 7%;
        width: 90%;
        // color: #08030A;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__droneInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 2px solid #FD6125;
            border-radius: calc(10px * var(--heightmultiplier));
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;



            &__droneid {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                font-size: calc(20px * var(--heightmultiplier));
                background-color: #FD6125;
                width: 100%;
                // border-right: 1px solid var(--primaryTextColor);
                border: 2px solid var(--primaryTextColor);
                border-radius: calc(10px * var(--heightmultiplier));
                overflow: hidden;

                &__title {
                    font-size: calc(20px * var(--heightmultiplier));
                    font-weight: 600;
                    color: white;
                    height: 50%;
                    width: 100%;
                    text-align: center;
                }

                &__value {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--primaryColor);
                    font-size: calc(14px * var(--heightmultiplier));
                    font-weight: 400;
                    padding: calc(5px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
                    height: 50%;
                }
            }

            &__flightid {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                font-size: calc(20px * var(--heightmultiplier));
                background-color: #FD6125;
                height: 100%;
                width: 100%;
                // border-left: 1px solid var(--primaryTextColor);
                border: 2px solid var(--primaryTextColor);
                border-radius: calc(10px * var(--heightmultiplier));
                overflow: hidden;

                &__title {
                    font-size: calc(20px * var(--heightmultiplier));
                    font-weight: 600;
                    color: white;
                    height: 50%;
                    width: 100%;
                    text-align: center;
                }

                &__value {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--primaryColor);
                    font-size: calc(14px * var(--heightmultiplier));
                    font-weight: 400;
                    padding: calc(5px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
                    height: 50%;
                }
            }
        }



    }

}


.view-all-drones-main-map {
    height: 100%;
    width: calc(1670px * var(--widthmultiplier));
    margin-right: 0.0428%;
    // border: 2px solid black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__topInfobar {
        height: 10%;
        width: 91.6%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // border: 1px solid black;


        &__SelectLocations-container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;


            &__buttons {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-direction: column;

                // border: 1px solid black;
                &__btn {
                    // padding: 0.3em;
                    width: fit-content;
                    height: 40%;
                    text-align: center;
                    border-radius: 0.625em;
                    background-color: rgba(#FD6125, 0.1);
                    font-weight: 500;
                    font-size: 1em;
                    box-shadow: none;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 1em;
                    // border-width: 0.2em;
                    color: #FD6125;
                    box-sizing: border-box;
                    // box-shadow: 0px 0px 5px #FD6125;

                    &:hover {
                        // border-color: #f47746;
                        outline: none;
                        // border-width: 0.25em;
                        cursor: pointer;
                        color: white;
                        background-color: #FD6125;
                    }
                }

                &__missionbtn {
                    // padding: 0.3em;
                    width: 100%;
                    height: 40%;
                    text-align: center;
                    border-radius: 0.625em;
                    background-color: rgba(#FD6125, 0.1);
                    font-weight: 500;
                    font-size: 1em;
                    box-shadow: none;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 1em;
                    // border-width: 0.2em;
                    color: #FD6125;
                    box-sizing: border-box;
                    // box-shadow: 0px 0px 5px #FD6125;

                    &:hover {
                        // border-color: #f47746;
                        outline: none;
                        // border-width: 0.25em;
                        cursor: pointer;
                        color: white;
                        background-color: #FD6125;
                    }
                }
            }

            &__locations {
                box-sizing: border-box;
                // width: fit-content;
                padding: 0px 0.5em;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-around;
                // border: 2px solid black;
                // height: 100%;
            }

        }

        &__connectBtn-container {
            width: 12.45%;
            height: 100%;
            padding: 10px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__connectBtn {
                justify-self: flex-start;
                box-sizing: border-box;
                // padding: 10px 20px;
                width: 15%;
                font-size: 1em;
                width: 100%;
                height: 42%;
                background-color: #FD6125;
                display: flex;
                align-items: center;
                justify-content: space-around;
                color: black;
                border-radius: 0.5em;
                transition: all 0.1s linear;
                margin-bottom: 10px;
                border: none;

                &:hover {
                    cursor: pointer;
                    background-color: rgba($color: #e82222, $alpha: 1.0);
                    font-weight: 600;
                }
            }
        }


        &__instuctionNStatus {
            justify-self: flex-start;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 90%;
            width: 55%;
            border: 2px solid rgba(#FD6125, 0.5);
            border-radius: 5px;
            box-sizing: border-box;
            overflow-y: scroll;



            &__message {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-items: flex-start;
                width: 90%;
                height: 100%;
                font-size: 1em;
                // border: 2px solid black;

                // &__msg{

                // }
            }
        }



        &__user {
            height: 100%;
            // width: 37.11%;
            margin-right: 0px;
            display: flex;
            align-items: center;
            justify-self: baseline;

            &__proImg {
                height: 3em;
                width: 3em;
                // min-width: 50px;
                margin-left: 1em;
                box-sizing: border-box;
                border-radius: 50%;
                background-color: #FD6125;
                color: var(--primaryColor);
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                }
            }

            &__name {
                font-size: 1.5em;
            }
        }


    }

    &__GoogleMapContainer {
        background-color: var(--primaryColor);
        position: relative;
        top: 0px;
        left: 0px;
        height: 83.33%;
        width: 91.6%;
        width: 95%;
        border-radius: 30px;
        // overflow: hidden;
        // border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        &__followDroneBtn {
            position: absolute;
            height: 5%;
            width: 10%;
            left: 1.5em;
            top: 1.5em;
            font-size: 1em;
            border-radius: 0.4em;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #4285F4;
            box-sizing: border-box;
            color: var(--primaryColor);

            &:hover {
                cursor: pointer;
                font-weight: 600;
                background-color: white;
                color: #FD6125;
                border: 2px solid #4285F4;
            }
        }

        &__ZoomtoRoute {
            position: absolute;
            height: 5%;
            width: 10%;
            left: 1.5em;
            top: 5em;
            font-size: 1em;
            border-radius: 0.4em;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #4285F4;
            box-sizing: border-box;
            color: var(--primaryColor);

            &:hover {
                cursor: pointer;
                font-weight: 600;
                background-color: white;
                color: #FD6125;
                border: 2px solid #4285F4;
            }
        }
    }

    &__footer-container {
        position: relative;
        height: 6.66%;
        width: 91.6%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__copyright {
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: calc(20px * var(--widthmultiplier));

            &__company-logo {
                width: auto;
                height: calc(50px * var(--heightmultiplier));
            }

            &__title {
                color: #878B8F;
                font-size: 1em;
            }
        }

        &__themeSwitch {
            position: absolute;
            right: 0px;
            box-sizing: border-box;
            padding: 0px;
            width: 40px;
            height: 20px;
            border: 1px solid #FD6125;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 10px #FD6125;
            overflow: hidden;

            &:hover {
                cursor: pointer;
            }

            &__circle {
                height: 100%;
                width: 50%;
                border-radius: 50%;
                transition: all 0.2s linear;
                transform: translateX(-10px);
                background-color: #FD6125;
            }
        }
    }

    &__search-container {
        font-size: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        &__img {
            height: 100%;
            width: auto;
            margin-right: 0.2em;
        }

        &__search {
            outline: none;
            border: none;
            background-color: inherit;
            font-size: 1.25em;
        }
    }
}

.trapezoid-container {
    position: absolute;
    top: 42%;
    right: 43.5%;
    height: 100px;
    width: 240px;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: 3;
    // border: 1px solid black;
    transition: all 0.1s linear;
    // transform: rotate(-90deg);
    // &:hover{
    //     transform: rotate(180deg);
    // }

    &__img-container {
        position: relative;
        // border: 1px solid black;
        height: 50%;
        width: 30%;

        &__img {
            position: absolute;
            right: 30px;
            top: -3.5px;
            transform: rotate(-90deg);

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.mapRightClickPopUp-Container {

    background-color: white;
    color: black;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 5px 0px;

    border-radius: calc(10px * var(--heightmultiplier));
    border-top-left-radius: 0px;
    border: 2px solid #FD6125;

    &:hover {
        cursor: pointer;
    }

    &__ClickOption {

        width: 100%;
        height: fit-content;
        color: black;
        font-size: calc(20px * var(--heightmultiplier));
        white-space: nowrap;
        border: none;
        background-color: white;
        border-top: 0.5px solid #e0e0e0;
        border-bottom: 0.5px solid #e0e0e0;

        &:hover {
            background-color: #FD6125;
            color: white;
        }
    }

}


.goToLocationPopUp-Container {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: black;
        width: 30%;
        border-radius: calc(10px * var(--heightmultiplier));

        &__taskLabel {
            font-size: calc(30px * var(--heightmultiplier));
            margin-bottom: calc(15px * var(--heightmultiplier));
            font-weight: 500;
        }

        &__para {
            width: 90%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid #FD6125;
            border-radius: calc(10px * var(--heightmultiplier));
            margin-bottom: 5px;
            overflow: hidden;

            &__title {
                font-size: calc(24px * var(--heightmultiplier));
                font-weight: 500;
                width: 40%;
                text-align: center;
                background-color: #FD6125;
            }

            &__value {
                width: 60%;
                font-size: calc(20px * var(--heightmultiplier));
                text-align: center;
            }

            &__input {
                width: 60%;
                font-size: calc(20px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                border: none;
                outline: none;
                background-color: #e0e0e0;
            }
        }


        &__buttons {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin-top: calc(15px * var(--heightmultiplier));
            margin-bottom: calc(15px * var(--heightmultiplier));


            &__CloseBtn {
                height: fit-content;
                width: fit-content;
                font-size: calc(24px * var(--heightmultiplier));
                font-weight: 500;
                border: none;
                padding: 2px calc(5px * var(--heightmultiplier));
                border-radius: calc(5px * var(--heightmultiplier));
                background-color: rgba($color: red, $alpha: 0.3);
                color: black;
                width: 20%;

                &:hover {
                    background-color: red;
                    color: white;
                }
            }

            &__OkayBtn {
                height: fit-content;
                width: fit-content;
                font-size: calc(24px * var(--heightmultiplier));
                font-weight: 500;
                border: none;
                padding: 2px calc(5px * var(--heightmultiplier));
                border-radius: calc(5px * var(--heightmultiplier));
                background-color: rgba($color: green, $alpha: 0.5);
                color: black;
                width: 20%;

                &:hover {
                    background-color: green;
                    color: white;
                }
            }
        }

    }



}

// #trapezoid {
//     // position: absolute;
//     // top:50vh;
//     // right: 50vw;
//     margin-top: 0vw;
//     width: 100px;
//     height: 30px;

//     background-image: linear-gradient(to right,
//             rgba(233, 69, 14, 0) 30%,
//             rgba(233, 69, 14, 0.6) 50%,
//             rgba(233, 69, 14, 1) 100%);
//     transform: perspective(20px) rotateY(45deg);
//     //   border: 2px solid black;
// }

// div.roll {
//   transform: rotate(0deg);
// }
// div.roll div.pitch {
//   top: 0%;
// }
// div.yaw {
//   transform: rotate(0deg);
// }
// div.instrument.vario div.vario {
//   transform: rotate(0deg);
// }
// div.instrument.speed div.airspeed {
//   transform: rotate(90deg);
// }
// div.instrument.altimeter div.pressure {
//   transform: rotate(40deg);
// }
// div.instrument.altimeter div.needle {
//   transform: rotate(90deg);
// }
// div.instrument.altimeter div.needleSmall {
//   transform: rotate(90deg);
// }


.VideoPlayer-container {
    position: absolute;
    left: 10px;
    bottom: 10px;
    // z-index: 100;
    padding: 0px;
    width: 30%;
    height: 30%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;

    &__refreshIcon-img {
        position: absolute;
        height: calc(30px * var(--heightmultiplier));
        width: calc(30px * var(--heightmultiplier));
        right: 5px;
        top: 5px;
        z-index: 10;
        border-radius: 50%;
        transition: all 0.3s linear;
        background-color: white;

        &:hover {
            cursor: pointer;
            background-color: rgba(#FD6125, 0.5);
            transform: rotate(90deg);
        }
    }

    &__player {
        margin: 0px;
        width: 100%;
        height: auto;

    }
}

.markerLabelCss {
    // border: 1px solid green;
    background-color: white;
    padding: calc(5px * var(--heightmultiplier));
    border-radius: 5px;
    // transform: translateY(calc(-50px * var(--heightmultiplier)));
    border: 1px solid black;
    transform: translateY(-36px);
}

.hub-dronemarkerLabelCss {
    // border: 1px solid green;
    background-color: white;
    padding: calc(5px * var(--heightmultiplier));
    border-radius: 5px;
    // transform: translateY(calc(-50px * var(--heightmultiplier)));
    border: 1px solid black;
    font-weight: 500;
    transform: translateY(-15px);
}

.hub-dronemarkerLabelCss-selected {
    // border: 1px solid green;
    background-color: white;
    padding: calc(5px * var(--heightmultiplier));
    border-radius: 5px;
    // transform: translateY(calc(-50px * var(--heightmultiplier)));
    border: 2px solid #FD6125;
    font-weight: 500;
    transform: translateY(-15px);
}

.cluster-marker {
    // border: 1px solid green;
    background-color: white;
    padding: calc(5px * var(--heightmultiplier));
    font-size: calc(25px * var(--heightmultiplier));
    border-radius: 5px;
    // transform: translateY(calc(-50px * var(--heightmultiplier)));
    border: 1px solid black;
    font-weight: 500;
    transform: translateY(-36px);
}


.GmapCustomMarkerLabels {
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(#000000, 0.5);
    // transform: translateY(calc(-35px * var(--heightmultiplier)));
    transform: translateY(-35px);

}

.GmapCustomMarkerLabelsSelected {
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(#000000, 0.5);
    // transform: translateY(calc(-35px * var(--heightmultiplier)));
    transform: translateY(-35px);
    border: 2px solid #FD6125;
}

// .clusterStyle {
//     &::after{
//         content: "Jengjal";
//         background-color: #000000;
//         width: fit-content;
//         height: 50px;
//         // display: flex;
//         // align-items: center;
//         // justify-content: center;
//     }
// }