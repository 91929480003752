@import "../variables";

.RouteSelect {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30%;
    width: 98%;
    // border: 2px solid #e82222;
    // border-radius: 0.5rem;
    // padding: 3px;
    border-radius: 0.5em;
    box-sizing: border-box;
    background-color: var(--primaryColor);;
    padding: 0.75rem;
    color: var(--primaryTextColor);

    // margin-right: 1em;
    // background-color: rgba($color: #F47746, $alpha: 1.0);

    &__fields-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 75%;
        // border: 2px solid #e82222;
        border-radius: 0.5rem;
        box-sizing: border-box;
        outline: none;
        // background-color: rgba($color: #F47746, $alpha: 1.0);
    }


    &__fields-options {
        color: var(--primaryTextColor);

        background-color: var(--primaryColor);
        width: 5em;
        font-size: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.25em;

        &:hover {
            cursor: pointer;
        }
    }


}