.OrderDetailsPopup-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* border: 1px solid black; */
}



.OrderDetailsPopup-modalContainer {
  width: 70%;
  height: fit-content;

  border-radius: 0.75em;

  background-color: var(--primaryColor);;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* border: 5px solid #FC6025; */
  /* padding-top: calc(50px);
  padding-bottom: calc(50px); */

  padding: calc(50px * var(--widthmultiplier));
  
  font-size: calc(50px * var(--heightmultiplier));
  box-shadow: 0px 0px 15px #FD6125;

  &__item{
    font-size: calc(30px * var(--widthmultiplier));
    margin-top: calc(10px * var(--heightmultiplier));
    margin-bottom: calc(10px * var(--heightmultiplier));
  }

  &__OkayBtn {
  
    box-sizing: border-box;
    width: calc(150px * var(--widthmultiplier));
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size:  calc(40px * var(--heightmultiplier));
    background-color: rgba(253,97,37, 1);
    border-radius: 5px;
    color: white;
  
    &:hover {
      cursor: pointer;
      background-color: #FC6025;
    }
  }
}

