
.AddNewEmployee-modalBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  
  
  .AddNewEmployee-modalContainer {
    width: 50%;
    height: 60%;
    border-radius: 0.75em;
    background-color: var(--primaryColor);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
    font-size: calc(50px * var(--heightmultiplier));
    box-shadow: 0px 0px 15px #FD6125;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    &__title{
      position: relative;
      width: 100%;
      height: 10%;
     // border: 1px solid red;
      display: flex;
      //text-align: center;
      align-items: center;
      justify-content: center;
      &__heading {
        width: 40%;
        height: 100%;
        font-size: calc(32px * var(--heightmultiplier));
        font-weight: 500;
        border-bottom: 2px solid gray;
        padding: calc(px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        //border: 1px solid red;
      }
      &__cancleBtn{
        position: absolute;
        top: 0px;
        right: 0px;
        width: 4%;
        height: 60%;
        border-radius: 50%;
        font-size: calc(28px * var(--heightmultiplier));
        font-weight: 400;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border: none;
        background-color: var(--primaryColor);
        color: var(--primaryTextColor);
        //border: 1px solid red;
        //border-bottom: 2px solid gray;
        //padding: calc(px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        &:hover{
          cursor: pointer;
          background-color: rgba($color: #fd6125, $alpha: 0.2);
        }
      }
    }
 
    &__register-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: calc(30px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));

        &__input-email {
            box-sizing: border-box;
            outline: none;
            border: none;
            background: #b4b3b3;
            border-radius: calc(10px * (var(--widthmultiplier)  + var(--widthmultiplier)) / 2);
            padding-left: calc(32px * var(--widthmultiplier));
            width: 70%;
            height: calc(50px * var(--heightmultiplier));
            font-size: calc(18px * var(--heightmultiplier));
            margin-bottom: calc(18px * var(--heightmultiplier));
            color: black;
            &__fixDigit{
                width: 6%;
                height: 80%;
                background-color: transparent;
                border: none;
                outline: none;
                border-right: 2px solid rgb(125, 126, 125);
            }
            &__phone{
                width: 90%;
                height: 100%;
                padding-left: calc(10px * var(--heightmultiplier)) ;
                background-color: transparent;
                border: none;
                outline: none;
            }
        }

        &__input-password {
            box-sizing: border-box;
            outline: none;
            outline: none;
            border: 1px solid #F2F2F2;
            border-radius: calc(10px * (var(--widthmultiplier)  + var(--widthmultiplier)) / 2);
            padding-left: calc(32px * var(--widthmultiplier));
            width: 85.5%;
            height: calc(60px * var(--heightmultiplier));
            font-size: calc(16px * var(--heightmultiplier));
            margin-bottom: calc(20px * var(--heightmultiplier));
        }

        &__forgotPassword {
            text-align: end;
            box-sizing: border-box;
            outline: none;
            outline: none;
            // border: 1px solid #F2F2F2;
            // border-radius: 10px;
            // padding-left: 32px;
            width: 85.5%;
            height: calc(20px * var(--heightmultiplier));
            font-weight: 400;
            font-size: calc(16px * var(--heightmultiplier));
            line-height: calc(20px * var(--heightmultiplier));
            text-decoration-line: underline;

            color: #878B8F;

            mix-blend-mode: normal;

            margin-bottom: calc(26px * var(--heightmultiplier));
        }

        &__btn-SignIn {
            width: 85.5%;
            height: calc(45px * var(--heightmultiplier));
            // font-family: 'General Sans';
            font-style: normal;
            font-weight: 500;
            font-size: calc(16px * var(--heightmultiplier));
            line-height: calc(22px * var(--heightmultiplier));
            color: #F2F2F2;

            background: #FD6125;
            border-radius: calc(10px * (var(--widthmultiplier)  + var(--widthmultiplier)) / 2);
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }
        }

        &__register-msg1 {
            margin-top: calc(20px * var(--heightmultiplier));
            width: 85.5%;
            // font-family: 'General Sans';
            font-style: normal;
            font-weight: 400;
            font-size:calc(16px * var(--heightmultiplier));
            line-height: calc(20px * var(--heightmultiplier));
            /* identical to box height, or 125% */


            color: #878B8F;

            mix-blend-mode: normal;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__register-msg2 {
            // font-family: 'General Sans';
            font-style: normal;
            font-weight: 400;
            font-size: calc(16px * var(--heightmultiplier));
            line-height: calc(20px * var(--heightmultiplier));
            /* identical to box height, or 125% */

            mix-blend-mode: normal;
            color: rgba(66, 133, 244, 1);

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &__error-msg {
            color: red;
            font-size: calc(16px * var(--heightmultiplier));
            opacity: 0;
        }
    }
  
    &__button {
      width: 100%;
      display: flex;
      position: relative;
      //flex: column;
      align-items: center;
      justify-content: space-evenly;
  
      &__OkayBtn {
  
        box-sizing: border-box;
        width: calc(150px * var(--widthmultiplier));
        height: fit-content;
        font-style: normal;
        font-weight: 600;
        font-size: calc(30px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier));
        background-color: rgba($color: #fd6125, $alpha: 0.4);
        border-radius: 5px;
        color: black;
  
        &:hover {
          cursor: pointer;
          background-color: #FC6025;
          color: white;
        }
      }
      &__cancelBtn{
  
        box-sizing: border-box;
        width: calc(150px * var(--widthmultiplier));
        height: fit-content;
        // display: flex;
        // flex: row;
        // align-items: center;
        // justify-content: center;
        // font-family: '';
        font-style: normal;
        font-weight: 600;
        font-size: calc(30px * var(--heightmultiplier));
        //padding: calc(0px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier));
        background-color: rgba($color: #fd6125, $alpha: 0.4);
        border-radius: 5px;
        color: black;
  
        &:hover {
          cursor: pointer;
          background-color: #FC6025;
          color: white;
        }
      }
    }
  }