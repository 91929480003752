@import "./variables";



.view-page-container {

    position: relative;

    height: 100%;
    width: 100%;
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: aquamarine;
    box-sizing: border-box;
    background-color: var(--secondaryColor);
    color: var(--primaryTextColor);
}

.view-sidebar {
    // flex: 1;
    background-color: var(--primaryColor);
    // border: 2px solid #FD6125;
    // border-left-color: white;
    box-sizing: border-box;
    width: calc(200px * var(--widthmultiplier));
    height: 100%;
    border-top-right-radius: calc(20px * var(--widthmultiplier));
    border-bottom-right-radius: calc(20px * var(--widthmultiplier));
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__ElapsedTimeContainer {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        width: 90%;
        // display: flex;
        // justify-content: space-around;
        // align-items: center;
        font-size: 1em;
        border-radius: 0.3em;
        color: #4285F4;
    }

    &__controlButtons {
        width: 90%;
        height: 4.5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.3em 0px;
        margin-bottom: 1.02%;



        &__RTL {
            // padding: 0.3em;
            width: 47.345%;
            height: 100%;
            border-radius: 0.625em;
            background-color: rgba(253, 97, 37, 0.1);
            font-weight: 500;
            font-size: 1em;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            // border-width: 0.2em;
            color: #FD6125;
            box-sizing: border-box;

            // box-shadow: 0px 0px 5px #FD6125;
            &:hover {
                // border-color: #f47746;
                outline: none;
                // border-width: 0.25em;
                cursor: pointer;
                color: white;
                background-color: #FD6125;
            }
        }
    }

    &__infoContainer {
        
        width: 100%;
        height: calc(600px * var(--heightmultiplier));
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: calc(24px * var(--heightmultiplier)) calc(32px * var(--widthmultiplier));
        // border: 1px solid black;
        flex-wrap: wrap;



        // &__field {
        //     width: 48%;
        //     height: 95%;
        //     border-radius: 0.7em;
        //     background-color: var(--primaryColor);
        //     border: 1px solid var(--secondaryTextColor);
        //     overflow: hidden;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     justify-content: space-around;
        //     // color: #000;



        //     &__label {
        //         font-size: 1.5em;
        //     }

        //     &__currentvalue {
        //         font-size: 3em;
        //         width: 80%;
        //         height: 60%;
        //         margin-bottom: 0.5em;
        //         background-color: var(--secondaryColor);
        //         border-radius: 0.3em;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }

        // }

    }
    &__logout{
        font-size: calc(22px * var(--heightmultiplier));
        width: calc(145px * var(--widthmultiplier));
        height: calc(50px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #FD6125, $alpha: 0.1);
        border-radius: calc(10px * var(--heightmultiplier));
        font-weight: 500;

        &:hover{
            // text-decoration: underline;
            cursor: pointer;
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;
        }
    }



    &__logodiv {
        height: 8%;
        width: 100%;
        // background: #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 5px 20px;
        // padding-right: 50px;

        &__logoimg {
            margin-left: 2em;
            height: 2.4em;
            width: 10em;
        }

        &__back {
            margin-right: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__logodiv-border {
        height: 2px;
        width: 90%;
        background-color: var(--secondaryColor);
    }

    &__DroneName {
        box-sizing: border-box;
        padding-top: 1.375em;
        // padding-left: 1.375;
        margin-left: 2em;
        font-size: 1.5em;
        font-weight: 500;
        height: 100%;
        // width: 100%;
        // color: #08030A;
        font-weight: 400;
    }

    &__DroneInfoItem {
        margin-top: 2%;
        border-radius: 10px;
        width: 90%;
        height: 5%;
        background-color: var(--secondaryColor);
        // border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__key {
            margin-left: 20px;
        }

        &__InfoValue {
            margin-right: 20px;
            color: green;
        }
    }

    &__indicatorpanel {
        width: 95%;
        height: 35%;
        box-sizing: border-box;
        // border: 1px solid black;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

.instrument {
    position: relative;
    // justify-self: baseline;
    // display: inline-block;
    height: 33.7%;
    width: 68%;
    // width: auto;
    // width: 90%;
    overflow: hidden;
    justify-self: flex-end;
    margin-top: 1.3%;
    border-radius: 25.7%;
    // z-index: 2;
}

//   .instruments {
//       background-color: #444547;
//   }

/* The box containing any element of an indicator */
div.instrument .box {
    position: absolute;
    top: 100;
    left: 100;
    width: 100%;
    height: 100%;
}

/* Default transformations */

.altimeter_hand100 {
    //   transform: rotate(50deg);
    transition: transform 0.2s linear;
}

div.turn_airplane {
    // transform: rotate(50deg);
    transition: all 0.2s linear;
}

div.vario_hand {
    // transform: rotate(var(--vario_handdeg));
    transition: transform 0.5s linear;
}

div.attitude_pitch {
    transition: all 0.5s linear;
}

div.attitude {
    transition: all 0.5s linear;
}

.view-main-map {
    height: 100%;
    width: calc(1720px * var(--widthmultiplier));

    margin-right: 0.0428%;
    // border: 2px solid black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__topInfobar {
        height: 10%;
        width: 91.6%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // border: 1px solid black;


        &__SelectLocations-container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;


            &__buttons {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &__btn {
                    // padding: 0.3em;
                    width: fit-content;
                    height: 48%;
                    text-align: center;
                    border-radius: 0.625em;
                    background-color: rgba(#FD6125, 0.1);
                    font-weight: 500;
                    font-size: 1em;
                    box-shadow: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 1em;
                    // border-width: 0.2em;
                    color: #FD6125;
                    box-sizing: border-box;
                    // box-shadow: 0px 0px 5px #FD6125;

                    &:hover {
                        // border-color: #f47746;
                        outline: none;
                        // border-width: 0.25em;
                        cursor: pointer;
                        color: white;
                        background-color: #FD6125;
                    }
                }
            }

            &__locations {
                box-sizing: border-box;
                // width: fit-content;
                padding: 0px 0.5em;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-around;
                // border: 2px solid black;
                // height: 100%;
            }

        }

        &__connectBtn {
            justify-self: flex-start;
            box-sizing: border-box;
            // padding: 10px 20px;
            width: 15%;
            font-size: 1em;
            width: 12.45%;
            height: 42%;
            background-color: #FD6125;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: black;
            border-radius: 0.5em;
            transition: all 0.1s linear;

            &:hover {
                cursor: pointer;
                background-color: rgba($color: #e82222, $alpha: 1.0);
                font-weight: 600;
            }
        }

        &__instuctionNStatus {
            justify-self: flex-start;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 90%;
            width: 55%;
            border: 2px solid rgba(#FD6125, 0.5);
            border-radius: 5px;
            box-sizing: border-box;
            overflow-y: scroll;



            &__message {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-items: center;
                width: 90%;
                height: 100%;
                font-size: 1em;
                // border: 2px solid black;

                // &__msg{
                    
                // }
            }
        }



        &__user {
            height: 100%;
            // width: 37.11%;
            margin-right: 0px;
            display: flex;
            align-items: center;
            justify-self: baseline;

            &__proImg {
                height: 3em;
                width: 3em;
                // min-width: 50px;
                margin-left: 1em;
                box-sizing: border-box;
                border-radius: 50%;
                background-color: #FD6125;
                color: var(--primaryColor);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__name {
                font-size: 1.5em;
            }
        }


    }

    &__GoogleMapContainer {
        position: relative;
        top: 0px;
        left: 0px;
        height: 83.33%;
        width: 91.6%;
        border-radius: 30px;
        overflow: hidden;
        // border: 1px solid black;

        &__AbortBox {
            position: absolute;
            top: 1.5em;
            left: 28.5%;
            width: 43.4%;
            height: 9%;
            background-color: var(--primaryColor);
            display: flex;
            align-items: center;
            justify-content: space-around;
            box-shadow: 0px 4px 24px rgba(16, 29, 40, 0.08);
            border-radius: 0.625em;

            &__AbortMsg {
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 1.2em;
                line-height: 1.25em;
                /* identical to box height, or 111% */


                /* Colors / Black / 100% */

                color: var(--primaryTextColor);

                // mix-blend-mode: normal;
                // opacity: 0.4;
            }

            &__AbortBtn {
                width: 31.54%;
                height: 56.25%;
                font-size: 1.2em;
                color: #FD6125;
                background-color: rgba($color: #FD6125, $alpha: 0.1);
                border-radius: 0.625em;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                    color: white;
                    background-color: #FD6125;
                }
            }
        }

        &__followDroneBtn {
            position: absolute;
            height: 5%;
            width: 12.45%;
            left: 1.5em;
            top: 1.5em;
            font-size: 1em;
            border-radius: 0.4em;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #4285F4;
            box-sizing: border-box;
            color: var(--primaryColor);

            &:hover {
                cursor: pointer;
                font-weight: 600;
                background-color: white;
                color: #FD6125;
                border: 2px solid #4285F4;
            }
        }
    }

    &__footer-container {
        position: relative;
        height: 6.66%;
        width: 91.6%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__copyright {
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: calc(20px * var(--widthmultiplier));

            &__company-logo {
                width: auto;
                height: calc(50px * var(--heightmultiplier));
            }

            &__title {
                color: #878B8F;
                font-size: 1em;
            }
        }

        &__themeSwitch {
            position: absolute;
            right: 0px;
            box-sizing: border-box;
            padding: 0px;
            width: 40px;
            height: 20px;
            border: 1px solid #FD6125;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 10px #FD6125;
            overflow: hidden;

            &:hover {
                cursor: pointer;
            }

            &__circle {
                height: 100%;
                width: 50%;
                border-radius: 50%;
                transition: all 0.2s linear;
                transform: translateX(-10px);
                background-color: #FD6125;
            }
        }
    }

    &__search-container {
        font-size: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        &__img {
            height: 100%;
            width: auto;
            margin-right: 0.2em;
        }

        &__search {
            outline: none;
            border: none;
            background-color: inherit;
            font-size: 1.25em;
        }
    }
}

.trapezoid-container {
    position: absolute;
    top: 42%;
    right: 43.5%;
    height: 100px;
    width: 240px;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: 3;
    // border: 1px solid black;
    transition: all 0.1s linear;
    // transform: rotate(-90deg);
    // &:hover{
    //     transform: rotate(180deg);
    // }

    &__img-container {
        position: relative;
        // border: 1px solid black;
        height: 50%;
        width: 30%;

        &__img {
            position: absolute;
            right: 30px;
            top: -3.5px;
            transform: rotate(-90deg);

            &:hover {
                cursor: pointer;
            }
        }
    }
}

#trapezoid {
    // position: absolute;
    // top:50vh;
    // right: 50vw;
    margin-top: 0vw;
    width: 100px;
    height: 30px;

    background-image: linear-gradient(to right,
            rgba(233, 69, 14, 0) 30%,
            rgba(233, 69, 14, 0.6) 50%,
            rgba(233, 69, 14, 1) 100%);
    transform: perspective(20px) rotateY(45deg);
    //   border: 2px solid black;
}

// div.roll {
//   transform: rotate(0deg);
// }
// div.roll div.pitch {
//   top: 0%;
// }
// div.yaw {
//   transform: rotate(0deg);
// }
// div.instrument.vario div.vario {
//   transform: rotate(0deg);
// }
// div.instrument.speed div.airspeed {
//   transform: rotate(90deg);
// }
// div.instrument.altimeter div.pressure {
//   transform: rotate(40deg);
// }
// div.instrument.altimeter div.needle {
//   transform: rotate(90deg);
// }
// div.instrument.altimeter div.needleSmall {
//   transform: rotate(90deg);
// }

.aiimslogo_viewOnly{
    width: 80%;
    // border-radius: 50%;
    // background-color: var(--primaryTextColor);
    height: auto;
}