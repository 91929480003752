.FlightDetailsPopup-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  //  border: 1px solid blueviolet;
}



.FlightDetailsPopup-modalContainer {
  width: 70%;
  height: 75%;

  border-radius: 0.75em;

  background-color: var(--primaryColor);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //  border: 5px solid #FC6025; 
  /* padding-top: calc(50px);
  padding-bottom: calc(50px); */
  // border: 1px solid blueviolet;

  //padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

  // font-size: calc(50px * var(--heightmultiplier));
  box-shadow: 0px 0px 15px #FD6125;

  &__heading {
    width: 100%;
    font-weight: 500;
    height: 10%;
    font-size: calc(32px * var(--heightmultiplier));
    // border: 1px solid blue;
  }
  &__statusBar{
    width: 100%;
    height: 20%;
    // border: 1px solid red;
    // padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    margin:calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier)) ;
  }

  &__statusBarCancelled {
    width: 100%;
    height: fit-content;
    // border: 1px solid red;
    // padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    // margin:calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier)) ;
    &__title{
      width: fit-content;
      height: fit-content;
      // border: 1px solid yellow;
      font-size: calc(26px * var(--heightmultiplier));
      font-weight: 500;
      // color: red;
      display: flex;
      flex-direction: row;
      &__value{
        width: fit-content;
        height: fit-content;
        // sborder: 1px solid yellow;
        // border: 1px solid yellow;
        padding-left: calc(10px * var(--widthmultiplier));
        color: red;
      }
    }
    &__reason{
      width: fit-content;
      height: fit-content;
      // border: 1px solid yellow;
      font-size: calc(20px * var(--heightmultiplier));
      color: var(--primaryTextColor);
    }
  }

  &__dataTab {
    width: 100%;
    height: 73%;
    //border: 1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    &__left {
      width: 55%;
      height: 98%;
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: auto;
      // border: 1px solid blue;
      &__item {
        font-size: calc(20px * var(--widthmultiplier));
        // margin-top: calc(5px * var(--heightmultiplier));
        margin-bottom: calc(5px * var(--heightmultiplier));
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        // border: 1px solid yellow;
        &__title {
          width: 40%;
          font-weight: 500;
        }

        &__value {
          width: 60%;
          padding-left: 10px;

          &__payload-input{
             background-color: transparent;
            
             border: none;
          }
          &__editbtn{
            // width: 10%;
            color: var(--primaryTextColor);
            font-size: calc(18px * var(--widthmultiplier));
            // display: flex;
            // align-items: center;
            // justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: rgba($color: #9d85f6, $alpha: 0.2);
            border: 1px solid #9d85f6;

            &:hover {
                background-color: rgba($color: #9d85f6, $alpha: 1);
                border: 1px solid#9d85f6;
                color: white;
            }

            &__active {
                background-color: rgba($color: #9d85f6, $alpha: 1);
                border: 1px solid #9d85f6;
                color: white;
            }
          }
        }
      }
    }
    &__history{
       padding:  calc(18px * var(--widthmultiplier))  calc(0px * var(--widthmultiplier));
      &__btn{
      // margin-top: calc(18px * var(--widthmultiplier)) ;
      // width: 10%;
            color: var(--primaryTextColor);
            font-size: calc(18px * var(--widthmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 12px;
            margin: 0px 12px;
            font-weight: 500;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: rgba($color: #4285Fa, $alpha: 0.2);
            border: 1px solid #4285Fa;

            &:hover {
                background-color: rgba($color: #4285Fa, $alpha: 1);
                border: 1px solid #4285Fa;
                color: white;
            }

            &__active {
                background-color: rgba($color: #4285Fa, $alpha: 1);
                border: 1px solid #4285Fa;
                color: white;
            }
          }
    }
    &__right {
      width: 45%;
      height: 98%;
      display: flex;
      overflow: auto;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
      padding: calc(10px* var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      // border: 1px solid blue;
      position: relative;
    }

    &__container {
      font-size: calc(20px * var(--widthmultiplier));
      margin-top: calc(5px * var(--heightmultiplier));
      margin-bottom: calc(5px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
      width: 100%;
      height: fit-content;
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: flex-start;

      //border: 1px solid palevioletred;
      &__title {
        width: 60%;
        font-weight: 500;
        // border: 1px solid pink;
      }

      &__button {
        width: 30%;
        height: 100%;
        //padding-left: 10px;
        //border: none;
        border-radius: calc(10px * var(--heightmultiplier));
        border: 1px solid #4285F4;
        color: white;
        background-color: rgba($color: #4285F4, $alpha: 0.5);

        &:hover {
          cursor: pointer;
          background-color: rgba($color: #4285F4, $alpha: 1);
          color: white;
        }
      }
    }

    &__FileUpload {
      width: 100%;
      height: fit-content;
      margin-top: 10px;
      font-size: calc(24px * var(--widthmultiplier));

      // border-radius: calc(10px * var(--heightmultiplier));
      // border: 1px solid #4285F4;
      &__Conatiner {
        width: 100%;
        height: fit-content;
        // border: 1px solid blue;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__reminder {
          width: 100%;
          height: fit-content;
          padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          color: #FC6025;
          font-size: calc(16px * var(--widthmultiplier));
        }
      }

      &__error {
        width: 100%;
        height: fit-content;
        border: 2px solid var(--primaryTextColor);
        border-radius: calc(10px * var(--heightmultiplier));
        padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #FC6025;
        font-size: calc(18px * var(--widthmultiplier));
      }
    }
    &__markCompelete{
      width: 90%;
      height: fit-content;
      // border: 2px solid var(--primaryTextColor);
      // position: absolute;
      // bottom: px;
      border-radius: calc(10px * var(--heightmultiplier));
      margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
      padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      &__btn {
        width: fit-content;
        height: fit-content;
        float: right;
        border: 2px solid #4285F4;
        background-color: rgba($color: #4285F4, $alpha: 0.2);
        font-size: calc(18px * var(--heightmultiplier));
        font-weight: 500;
        padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        border-radius: calc(5px * var(--heightmultiplier));
        color: var(--primaryTextColor);

        &:hover {
          cursor: pointer;
          background-color: rgba($color: #4285F4, $alpha: 1);
          color: var(--primaryTextColor);
        }
      }
    }

    &__abortContainer {
      width: 90%;
      height: fit-content;
      // border: 2px solid var(--primaryTextColor);
      position: absolute;
      bottom: 0px;
      border-radius: calc(10px * var(--heightmultiplier));
      margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
      padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      
      &__inputContainer{
        width: 100%;
        height: fit-content;
        // border: 1px solid red;
        // background-color: lightgrey;
        font-size: calc(20px * var(--heightmultiplier));
        margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &__title{
          width: 35%;
          height: fit-content;
          // border: 1px solid yellow;
        }
        &__colon{
          width: 2%;
          height: fit-content;
        }
        &__inputfield{
          width: 62%;
          height: fit-content;
          color: 1px solid var(--primaryTextColor);
          border: 1px solid var(--primaryTextColor);
          background-color: var(--primaryColor);
          border-radius: calc(5px * var(--heightmultiplier));
          font-size: calc(20px * var(--heightmultiplier));
          padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
          // line-height: calc(20px * var(--heightmultiplier));
        }
      }
      &__selectContainer{
        width: 100%;
        height: fit-content;
        // border: 1px solid red;
        // background-color: lightgrey;
        font-size: calc(20px * var(--heightmultiplier));
        margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &__title{
          width: 35%;
          height: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          // border: 1px solid yellow;
        }
        &__colon{
          width: 2%;
          height: fit-content;
        }
        &__inputfield{
          width: 62%;
          height: fit-content;
          color: 1px solid var(--primaryTextColor);
          border: 1px solid var(--primaryTextColor);
          background-color: var(--primaryColor);
          border-radius: calc(5px * var(--heightmultiplier));
          font-size: calc(20px * var(--heightmultiplier));
          padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
          // line-height: calc(20px * var(--heightmultiplier));
        }
      }
     

      &__abortCompelete {
        width: 100%;
        height: 15%;
        // position: absolute;
        // bottom: 0px;
        // border: 1px solid orchid;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: flex-end;
        font-size: calc(32px * var(--heightmultiplier));

        &__btn {
          width: fit-content;
          height: fit-content;
          border: 2px solid red;
          background-color: rgba($color: red, $alpha: 0.2);
          font-size: calc(18px * var(--heightmultiplier));
          font-weight: 500;
          padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
          border-radius: calc(5px * var(--heightmultiplier));
          color: red;

          &:hover {
            cursor: pointer;
            background-color: rgba($color: red, $alpha: 1);
            color: white;
          }
        }
      }
    }
  }
  &__button{
    width: 100%;
    height: fit-content;
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__OkayBtn {
      // border: 1px solid green;
      box-sizing: border-box;
      width: calc(150px * var(--widthmultiplier));
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 600;
      font-size: calc(36px * var(--heightmultiplier));
      background-color: rgba(253, 97, 37, 1);
      border-radius: 5px;
      color: white;
  
      &:hover {
        cursor: pointer;
        background-color: #FC6025;
      }
    }
  }
}