.ChecklistItemRow-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &__title {
        width: 30%;
    }

    &__allParameters-cont {
        width: 70%;
    }

   padding: 5px;


    &__row {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: max-content;

        &__SrNo {
            min-width: fit-content;
            max-width: 5%;
            width: 5%;
            // border: 1px solid var(--primaryTextColor);
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
            height: max-content;
        }

        &__confirm {
            min-width: fit-content;
            max-width: 6%;
            width: 6%;
            // border: 1px solid var(--primaryTextColor);
            display: flex;
            align-items: center;
            justify-content: center;
            height: max-content;

            &__checkbox {
                // width: calc(40px * (var(--heightmultipler) + var(--widthmultipler)) / 2);
                // height: calc(40px * (var(--heightmultipler) + var(--widthmultipler)) / 2);
                width: 1.2em;
                height: 1.2em;

            }

        }

        &__label {
            min-width: min-content;
            max-width: 20%;
            width: 20%;
            // border: 1px solid var(--primaryTextColor);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: max-content;
        }

        &__parameters {
            min-width: fit-content;
            max-width: 45%;
            width: 45%;
            // border: 1px solid var(--primaryTextColor);
            text-align: center;
            padding: 5px;

            // padding: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            &__notEditable {
                display: flex;
                align-items: center;
                justify-content: center;

                &__name {
                    font-weight: 600;
                    padding: 2px 5px;
                }

                &__value {
                    padding: 2px 5px;
                }
            }
        }

        &__parameters2 {
            min-width: fit-content;
            max-width: 30%;
            width: 30%;
            // border: 1px solid var(--primaryTextColor);
            text-align: center;
            padding: 5px;

            // padding: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            &__notEditable {
                display: flex;
                align-items: center;
                justify-content: center;

                &__name {
                    font-weight: 600;
                    padding: 2px 5px;
                }

                &__value {
                    padding: 2px 5px;
                }
            }
        }

        &__notes {
            min-width: fit-content;
            max-width: 25%;
            width: 25%;
            // border: 1px solid var(--primaryTextColor);
            text-align: center;
            height: max-content;

            display: flex;
            align-items: center;
            justify-content: center;

            &__inputArea {
                width: 99%;
                height: 99%;
                background-color: inherit;
                color: inherit;
                text-align: center;
            }
        }

    }
}

.ChecklistItemRow-InputTextnNumber-cont {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 5px;
    padding: 2px;
    // border: 1px solid rgba($color: #FD6125, $alpha: 0.6);


    &__label {
        width: 25%;
    }

    &__input {
        width: 70%;

        color: black;
    }
}

.ChecklistItemRow-InputDropDown-cont {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 5px;
    padding: 2px;
    border: 1px solid rgba($color: #FD6125, $alpha: 0.6);

    &__label {
        width: 25%;
    }

    &__selectInput {
        width: 70%;
    }
}