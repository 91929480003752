.tab-content-container {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: calc(20px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));


    &__input-row-cont {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: calc(20px * var(--heightmultiplier));
        margin-bottom: calc(10px * var(--heightmultiplier));
        width: calc(900px * var(--widthmultiplier));
        border: 1px solid #FD6125;
        border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
        overflow: hidden;
        padding: calc(5px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

        &__input {
            color: var(--primaryTextColor);
            background-color: var(--secondaryColor);
            outline: none;
            border: none;
            // border: 1px solid #FD6125;
            // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
            // width: fit-content;
            width: calc(600px * var(--widthmultiplier));
        }

        &__label {
            color: var(--primaryTextColor);
            margin-right: 10px;
            height: calc(27px * var(--heightmultiplier));
            width: calc(200px * var(--widthmultiplier));
            font-weight: 500;
        }
    }

    &__dropdown-row-cont{
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: calc(20px * var(--heightmultiplier));
        margin-bottom: calc(10px * var(--heightmultiplier));
        width: calc(900px * var(--widthmultiplier));
        border: 1px solid #FD6125;
        border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
        overflow: hidden;
        padding: calc(5px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
        &__input {
            color: var(--primaryTextColor);
            background-color: var(--secondaryColor);
            outline: none;
            border: none;
            // border: 1px solid #FD6125;
            // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
            // width: fit-content;
            width: calc(600px * var(--widthmultiplier));
        }

        &__label {
            color: var(--primaryTextColor);
            margin-right: 10px;
            height: calc(27px * var(--heightmultiplier));
            width: calc(200px * var(--widthmultiplier));
            font-weight: 500;
        }

    }
}