.Pagination_Component{
    width: 100%;
    height: fit-content;
    // border: 1px solid yellow;
    margin: calc(10px *var(--heightmultiplier)) calc(0px * var(--heightmultiplier));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__leftArrow{
        width: fit-content;
        height: fit-content;
        // border: 1px solid pink;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &__BtnActive{
            height: 100%;
            width: 100%;
            cursor: pointer;
            transform: rotate(90deg);
            // border: 1px solid red;
            stroke: #FD6125;
            // color: var(--primaryTextColor);
        }
        &__BtnInActive{
            height: 100%;
            width: 100%;
            cursor: pointer;
            transform: rotate(90deg);
            // border: 1px solid red;
            stroke: var(--primaryTextColor);
           
            // color: #FD6125;
        }
        &__InActiveText{
            cursor: pointer;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 400;
            color: var(--primaryTextColor);
        }
        &__ActiveText{
            cursor: pointer;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 400;
            color: #FD6125;
        }
    }
    &__rightArrow{
        width: fit-content;
        height: fit-content;
        // border: 1px solid pink;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &__BtnActive{
            height: 100%;
            width: 100%;
            cursor: pointer;
            transform: rotate(270deg);
            // border: 1px solid red;
            stroke: #FD6125;
            // color: var(--primaryTextColor);
        }
        &__BtnInActive{
            height: 100%;
            width: 100%;
            cursor: pointer;
            transform: rotate(270deg);
            // border: 1px solid red;
            stroke: var(--primaryTextColor);
           
            // color: #FD6125;
        }
        &__InActiveText{
            cursor: pointer;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 400;
            color: var(--primaryTextColor);
        }
        &__ActiveText{
            cursor: pointer;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 400;
            color: #FD6125;
        }
    }
    &__pages{
        width: fit-content;
        height: fit-content;
        // border: 1px solid green;
        margin: calc(0px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        &__Dots{
            width: fit-content;
            height: fit-content;
            // border: 1px solid green;
            color: var(--primaryTextColor);
        }
        &__Active{
            width: calc(35px * var(--heightmultiplier));
            height: calc(35px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--heightmultiplier));
            // border: 1px solid yellow;
            border-radius: calc(60% * var(--heightmultiplier));
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 400;
            background-color:rgba($color: #fd6125, $alpha: 0.5);
            color: var(--primaryTextColor);
            
        }
        &__InActive{
            width: calc(25px * var(--heightmultiplier));
            height: calc(25px * var(--heightmultiplier));
            cursor: pointer;
            margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--heightmultiplier));
            // border: 1px solid yellow;
            border-radius: calc(50% * var(--heightmultiplier));
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 400;
            // background-color:rgba($color: #fd6125, $alpha: 0.5);
            color: var(--primaryTextColor);
        }
    }
}