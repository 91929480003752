.EmployeesTabContent-container {
    position: relative;
    top: 0px;
    left: 0px;
    // z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(10px * var(--heightmultiplier));
    justify-content: space-between;
    // border: 1px solid green;

    &__filter {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: calc(5px * var(--heightmultiplier));
        height: fit-content;
        width: 97%;
        // height: 5%;
        // border: 1px solid red;
        // position: relative;

        &__title {
            // background-color: #FD6125;
            color: var(--primaryTextColor);
            font-size: calc(30px * var(--heightmultiplier));
        }

        &__addButton {
            // position: absolute;
            width: calc(150px * var(--heightmultiplier));
            height: calc(40px * var(--heightmultiplier));
            // top: 0px;
            // right: 0px;
            border-radius: calc(10px * var(--heightmultiplier));
            font-size: calc(18px * var(--heightmultiplier));
            font-weight: 500;
            border: 1px solid #4285F4;
            background-color: rgba($color: #4285F4, $alpha: 0.4);

            // color: var(--secondaryTextColor);
            &:hover {
                cursor: pointer;
                background-color: #4285F4;
                color: var(--primaryTextColor);
            }


        }

    }

    &__index {
        position: sticky;
        top: 0px;
        // margin-top: calc(10px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        background-color: rgba($color: #FD6125, $alpha: 1);
        color: white;
        // margin-top: 10px;
        border-radius: 5px;
        // margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // margin-right: 17px;
        font-size: calc(20px * var(--heightmultiplier));
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 30%;
        // height: 100%;
        width: 98%;

        &__term {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: calc(24px * var(--heightmultiplier));
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 30%;
            border-right: 1px solid white;
            border-left: 1px solid white;
            height: 100%;
            -moz-user-select: -moz-none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        &__Employee {
            width: 11%;
            // border: 1px solid black;
            border-left: none;

        }

        &__active {
            width: 12%;
            // border: 1px solid black;
        }

        &__authLoc {
            width: 15%;
            // border: 1px solid black;
        }

        &__Designation {
            width: 12%;
            // border: 1px solid black;

        }

        &__Country {
            width: 10%;
            // border: 1px solid black;
        }

        &__Zone {
            width: 14%;
            // border: 1px solid black;
        }

        &__State {
            width: 10%;
            // border: 1px solid black;
        }

        &__Hub {
            width: 14%;
            // border: 1px solid black;
        }

        &__Change-Location {
            width: 12%;
            // border: 1px solid black;
            border-right: none;
        }
    }

    &__dropdowns {
        display: flex;
        // flex-direction: column;
        flex-direction: row;
        justify-content: flex-start;
        width: fit-content;
        height: calc(40px * var(--heightmultiplier));
        // border: 2px solid red;
        //  margin-bottom: calc(155px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // margin-bottom: 1.5rem;

        // margin-left: 1rem;
        //  padding-left: calc(25px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));

    }
}

.EmployeesTabContent-datacontainer {
    // position: relative;
    // top: 0px;
    // left: 0px;
    // z-index: 10;
    width: 99%;
    height: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    flex-grow: 1;
    // border: 1px solid red;

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        //padding: 2px 2px;
        background-color: rgba($color: #FD6125, $alpha: 0.1);
        margin-top: 5px;
        border-radius: 5px;

        &:nth-child(odd) {
            background-color: rgba($color: #E2E2E2, $alpha: 0.3); // Light gray for odd rows
        }

        &:nth-child(even) {
            background-color: rgba($color: #FD6125, $alpha: 0.2); // White for even rows
        }

        &__term {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: calc(20px * var(--heightmultiplier));
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 30%;
            border-right: 1px solid #FD6125;
            border-left: 1px solid #FD6125;
            height: 100%;
        }

        &__Employee {
            width: 11%;
            // border: 1px solid black;
            border-left: none;

        }

        &__active {
            width: 12%;
            // border: 1px solid black;
        }

        &__authLoc {
            width: 15%;
            // border: 1px solid black;
        }

        &__Designation {
            width: 12%;
            // border: 1px solid black;

        }

        &__Country {
            width: 10%;
            // border: 1px solid black;
        }

        &__Zone {
            width: 14%;
            // border: 1px solid black;
        }

        &__State {
            width: 10%;
            // border: 1px solid black;
        }

        &__Hub {
            width: 14%;
            // border: 1px solid black;
        }

        &__Change-Location {
            width: 12%;
            // border: 1px solid black;
            font-size: calc(18px * var(--heightmultiplier));
            border-right: none;
            display: flex;
            align-items: center;
            justify-content: center;

            &__getDtilbtn {
                width: 70%;
                height: calc(40px * var(--heightmultiplier));
                border-radius: calc(9px * var(--heightmultiplier));
                border: 2px solid #4285F4;
                background-color: rgba($color: #4285F4, $alpha: 0.5);
                color: var(--primaryTextColor);

                &:hover {
                    cursor: pointer;
                    background-color: #4285F4;
                }
            }
        }

    }
}