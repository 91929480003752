.AirspaceUnitsSettings-container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__field {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 90%;
        border: 1px solid var(--primaryTextColor);

        &__title {
            font-size: calc(20px * var(--heightmultiplier));
            font-weight: 500;
            width: 20%;
            
        }

        &__option {
            display: flex;
            margin-left: calc(10px * var(--heightmultiplier));
            display: flex;
            gap: calc(5px * var(--heightmultiplier));
        }
    }
}