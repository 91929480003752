.HelpContentTopics-container{
    width: 100%;
    border: 1px solid var(--primaryTextColor);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__addNewTopic{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        border: 1px solid var(--primaryTextColor);

        &__input{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            margin: 5px 10px;
        }
    }
}