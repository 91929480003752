.ElevationGraph-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0px;
    background-color: rgb(176, 173, 173);//rgba($color: #000000, $alpha:0.5);
    z-index: 5;
    border-radius: calc(30px * var(--heightmultiplier));
    padding-top: 50px;

    &__elevationgraph{
        position: relative;
        width: 100%;
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        &__closeBtn{
            position: absolute;
            right: 50px;
            top:50px;
            width: 30px;
            height: 30px;
        }

    }
    &__elevationbrush{
        // width: 100%;
        height: 20%;
        margin-bottom: 20px;
    }

    &__buttons{
        &__terrainBtn{
            position: absolute;
            right: 90px;
            top:50px;
            width: fit-content;
            height: 30px;
            padding: 0px 5px;
            border-radius: 5px;
            color:black;
        }
        &__closeBtn{
            position: absolute;
            right: 50px;
            top:50px;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            color:black;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

