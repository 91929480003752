.NewItemChecklistForm-modalalertBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    /* border: 1px solid black; */
}



.NewItemChecklistForm-modalalertContainer {
    position: relative;
    width: 90%;
    height: 90%;

    border-radius: calc(20px * var(--heightmultiplier));

    background-color: var(--primaryColor);
    ;
    box-shadow: #fd6125 0px 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    //  border: 5px solid #FC6025;
    //  padding-top: calc(50px);
    // padding-bottom: calc(50px); 
}

.NewItemChecklistForm {
    // border: 2px solid red;
    width: 90%;
    height: 80%;
    display: flex;
    text-align: center;
    justify-content: space-between;
    &__pageTitle {
        width: 100%;
        height: 5%;
        font-size: calc(30px * var(--heightmultiplier));
        font-weight: 500;
        margin-top: calc(15px * var(--heightmultiplier));
        margin-bottom: calc(20px * var(--heightmultiplier));
        //border: 1px solid red;
    }


    &__closeBtn {
        position: absolute;
        right: 3%;
        top: 3%;
        width: 2%;
        height: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(25px * var(--heightmultiplier));
        font-weight: 500;
        border-radius: 5px;
        border: 1px solid #fd6125;
        color:black;

        &:hover {
            cursor: pointer;
            background-color: #fd6125;
        }

        // padding: 0px calc(5px * var(--heightmultiplier));
    }
    &__createItemBtn{
        
            // width: 10%;
            // height: 80%;
            padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier));
            border-radius: calc(10px * var(--heightmultiplier));
            background-color: rgba($color: #fd6125, $alpha: 0.5);
            border-color: #FD6125;
            font-size: calc(20px * var(--heightmultiplier));
            font-weight: 600;
            border: #FD6125;
            color: white;
            &:hover{
                background-color: rgba($color: #fd6125, $alpha: 1);
            }
        
    }

    &__parameters-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border: 1px solid var(--primaryTextColor);

        &__left {
            width: 65%;
            height: 100%;
            // border: 1px solid var(--primaryTextColor);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            //border: 1px solid darkgreen;
            &__title {
                width: 100%;
                height: 6%;
                //border: 1px solid green;
                font-size: calc(20px * var(--heightmultiplier));
                font-weight: 500;
            }
        }

        &__right {
            width: 35%;
            height: 100%;
            // border: 1px solid var(--primaryTextColor);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__parameters-list {
        width: 100%;
        height: 94%;
        // border: 1px solid maroon;

        &__index {
            height: 6%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: calc(20px * var(--heightmultiplier));

            // border: 1px solid red;
            &__SrNo {
                height: 100%;
                width: 6%;
                border: 1px solid var(--primaryTextColor);
            }

            &__name {
                height: 100%;
                width: 30%;
                border: 1px solid var(--primaryTextColor);
            }

            &__type {
                height: 100%;
                width: 20%;
                border: 1px solid var(--primaryTextColor);
            }

            &__options {
                height: 100%;
                width: 25%;
                border: 1px solid var(--primaryTextColor);
            }

            &__actions {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 20%;
                border: 1px solid var(--primaryTextColor);
            }
        }

        // &__row-cont{
        //     width: 100%;
        //     height: fit-content;
        // }
        &__row {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: calc(18px * var(--heightmultiplier));
            border: 1px solid var(--primaryTextColor);
            //background-color: #FD6125;

            &__SrNo {
                width: 6%;
                height: 100%;
                border: 1px solid var(--primaryTextColor);
                display: flex;
                align-items: center;
                justify-content: center;
                //background-color: yellow;
            }

            &__name {
                height: 100%;
                width: 30%;
                border: 1px solid var(--primaryTextColor);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__type {
                height: 100%;
                width: 20%;
                border: 1px solid var(--primaryTextColor);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__options {
                height: 100%;
                width: 25%;
                border: 1px solid var(--primaryTextColor);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &__actions {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 20%;
                border: 1px solid var(--primaryTextColor);
                // display: flex;
                // align-items: center;
                // justify-content: center;
            }
        }

    }

    &__parameters-cont {
        width: 95%;
        height: 80%;
        // border: 1px solid var(--primaryTextColor);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: calc(10px * var(--heightmultiplier));

        &__options-cont {
            width: 100%;
            // background-color: ;
            border: 1px solid var(--primaryTextColor);

            &__title {
                font-size: calc(25px * var(--heightmultiplier));
                color: red
            }

            &__addNewRow {
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: space-around;
                // border: 1px solid var(--primaryTextColor);
            }

            &__allOptions-cont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: calc(10px * var(--heightmultiplier));
                height: fit-content;

                &__index {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: fit-content;
                    border: 1px solid var(--primaryTextColor);
                    font-weight: 500;
                    font-size: calc(20px * var(--heightmultiplier));

                }

                &__row {
                    display: flex;
                    align-items: center;
                    height: fit-content;
                    justify-content: space-between;
                    width: 100%;
                    //height: fit-content;
                    border: 1px solid var(--primaryTextColor);
                }

                &__rowSrNo {
                    width: 25%;
                    border-right: 1px solid var(--primaryTextColor);
                    height: 100%;
                    padding: 5px 0px;
                }

                &__rowOptionName {
                    width: 50%;
                    border-right: 1px solid var(--primaryTextColor);
                    height: 100%;
                    padding: 5px 0px;
                }

                &__rowAction {
                    width: 25%;
                    // border: 1px solid var(--primaryTextColor);
                    padding: 5px 0px;

                    &__btn {
                        box-sizing: border-box;
                        border: none;
                    }
                }
            }
        }

        &__addrow {
            width: 100%;
            // height: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__cont {
                width: 6%;
                height: 10%;
                display: flex;
                align-items: center;
                justify-content: center;

                &__btn {
                    border-radius: calc(10px * var(--heightmultiplier));
                    border: none;

                    &:hover {
                        background-color: #fd6125;
                    }
                }
            }

            &__addparaBtn {
                font-size: calc(20px * var(--heightmultiplier));
                margin-top: 5px;
                padding: 0px 5px;
                border-radius: calc(5px * var(--heightmultiplier));
                border: none;
                background-color: rgba($color: #FD6125, $alpha: 0.2);
                color: var(--primaryTextColor);

                &:hover {

                    background-color: #fd6125;
                }
            }

        }
    }

}

.NewItemChecklistForm__input-row-cont {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calc(20px * var(--heightmultiplier));
    // margin-bottom: calc(10px * var(--heightmultiplier));
    width: 80%;
    border: 1px solid #FD6125;
    border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
    overflow: hidden;
    padding: calc(5px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

    &__input {
        color: var(--primaryTextColor);
        background-color: var(--secondaryColor);
        outline: none;
        border: none;
        // border: 1px solid #FD6125;
        // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
        // width: fit-content;
        width: calc(600px * var(--widthmultiplier));
    }
    &__colon {
        margin-right: calc(7px * var(--widthmultiplier));

    }


    &__label {
        color: var(--primaryTextColor);
        margin-right: 10px;
        height: calc(27px * var(--heightmultiplier));
        width: calc(200px * var(--widthmultiplier));
        font-weight: 500;
    }
}