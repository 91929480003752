.UrgentOrders-container {
    position: relative;
    top: 0px;
    left: 0px;
    // z-index: 10;
    width: 100%;
    // height: 100%;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;


    // &__filter {

    //     box-sizing: border-box;
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-start;
    //     height: calc(50px * var(--heightmultiplier));
    //     width: 97%;
    //     padding: calc(10px * var(--heightmultiplier)) ;
    //     // border: 1px solid red;
    //     &__title {
    //         // background-color: #FD6125;
    //         color: var(--primaryTextColor);
    //         font-size: calc(30px * var(--heightmultiplier));
    //     }

    //     &__options {
    //         box-sizing: border-box;
    //         display: flex;
    //         align-items: center;
    //         justify-content: flex-start;
    //         // border: 1px solid #FD6125;
    //         height: 80%;
    //         // padding-left: calc(20px * var(--heightmultiplier));

    //         &__item {
    //             color: var(--primaryTextColor);
    //             font-size: calc(24px * var(--heightmultiplier));
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             padding: 0px 10px;
    //             margin: 0px 10px;
    //             border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    //             background-color: rgba($color: #FD6125, $alpha: 0.1);

    //             &:hover {
    //                 background-color: rgba($color: #FD6125, $alpha: 0.5);
    //                 border-color: #FD6125;
    //                 color: white;
    //             }
    //         }
    //         &__selected-item {
    //             color: white;
    //             font-size: calc(24px * var(--heightmultiplier));
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             padding: 0px 10px;
    //             margin: 0px 10px;
    //             border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    //             background-color: rgba($color: #FD6125, $alpha: 1);

    //             // &:hover {
    //             //     background-color: rgba($color: #FD6125, $alpha: 0.5);
    //             //     border-color: #FD6125;
    //             //     color: white;
    //             // }
    //         }
    //     }

    //     &__duration-title {
    //         color: var(--primaryTextColor);
    //         font-size: calc(24px * var(--heightmultiplier));
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         padding: 0px 10px;
    //     }
    //     &__duration-select{
    //         color: black;
    //         font-size: calc(20px * var(--heightmultiplier));
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         padding: 0px 10px;
    //         margin: 0px 10px;
    //         border-color: #FD6125;
    //         border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    //         // &__option{

    //         // }
    //     }
    //     &__urgent{
    //         width: 15%;
    //         height: 100%;
    //         // border: 1px solid green;
    //         float: right;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         &__btn{
    //             width: 60%;
    //             height: 100%;
    //             border: 1px solid rgba($color: #fd6125, $alpha: 1); 
    //             background-color: rgba($color: #fd6125, $alpha: 0.4);
    //             border-radius: calc(10px * var(--heightmultiplier));
    //             font-weight: 400;
    //             font-size: calc(18px * var(--heightmultiplier));
    //             color: var(--primaryTextColor);
    //             &:hover{
    //                 color: white;
    //                 background-color: rgba($color: #fd6125, $alpha: 0.8);

    //             }

    //         }
    //     }
    // }




    &__index {
        // position: sticky;
        // top:0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        background-color: rgba($color: #FD6125, $alpha: 1);
        color: white;
        height: calc(50px * var(--heightmultiplier));
        ;
        // margin-top: 10px;
        border-radius: 5px;
        // margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // margin-right: 17px;
        font-size: calc(12px * var(--heightmultiplier));
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 30%;
        // height: 100%;
        width: 98%;

        &__term {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: calc(24px * var(--heightmultiplier));
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 30%;
            border-right: 1px solid white;
            border-left: 1px solid white;
            height: 100%;
            -moz-user-select: -moz-none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            user-select: none;


            &:hover {
                cursor: pointer;
            }
        }

        &__order-no {
            width: 11.205%;
            // border: 1px solid black;
            border-left: none;

        }

        &__username {
            width: 16.205%;
            // border: 1px solid black;
            // border-left: none;
        }

        &__source {
            width: 13.705%;
            // border: 1px solid black;
        }

        &__destination {
            width: 13.705%;
            // border: 1px solid black;
        }

        &__payload {
            width: 11.205%;
            // border: 1px solid black;
        }

        &__delivery-type {
            width: 11.205%;
            // border: 1px solid black;
        }

        &__delivery-time {
            width: 11.3%;
            // border: 1px solid black;
        }

        &__status-update {
            width: 11.5%;
            // border: 1px solid black;
            border-right: none;
        }

        &__order-details {
            width: 10%;
            // border: 1px solid black;
            border-right: none;
        }
    }


}

.UrgentOrders-datacontainer {
    position: relative;
    top: 0px;
    left: 0px;
    // z-index: 10;
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding-bottom: calc(10px * var(--heightmultiplier));

    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        // padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // background-color: var(--ongoingColor);
        margin-top: 10px;
        border-radius: 5px;
        border: 1px solid #FD6125;
        // &__allData {


        // &__details{
        //     display: flex;
        //     flex-direction: column;
        //     gap: calc(10px * (var(--heightmultiplier)));
        // }

        &__header {
            // border: 1px solid blue;
            display: flex;
            width: 100%;
            // gap: calc(14px * var(--heightmultiplier));
            justify-content: space-between;
            font-size: calc(22px * var(--heightmultiplier));
            padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier)) calc(0px * var(--widthmultiplier)) calc(10px * var(--widthmultiplier));

            &__orderId {

                width: 25%;
                // border: 1px solid blue;
                text-align: start;

                &__doubleHeadedArrowSvg {
                    width: calc(60px * var(--heightmultiplier));
                    height: calc(40px * var(--heightmultiplier));
                }
            }

            &__orderNumber {
                width: 15%;
            }

            &__droneId {
                width: 30%;
                // border: 1px solid blue;
            }

            &__orderType {
                width: 25%;
                // text-align: start;
            }
        }

        // &__details {
        //     // border: 1px solid darkblue;
        // }
        // }
        &__orderData {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: calc(40px * var(--heightmultiplier));
            // padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            background-color: rgba($color: #FD6125, $alpha: 0.1);
            // margin-top: 10px;
            border-radius: 5px;

            // &:nth-child(odd) {
            background-color: rgba($color: #E2E2E2, $alpha: 0.3); // Light gray for odd rows

            //     &:hover {
            //         box-shadow: #FD6125 0px 0px 10px;
            //     }
            // }

            // &:nth-child(even) {
            //     background-color: rgba($color: #FD6125, $alpha: 0.2); // White for even rows

            //     &:hover {
            //         box-shadow: var(--primaryTextColor) 0px 0px 10px;
            //     }
            // }



            &__term {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: calc(20px * var(--heightmultiplier));
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 30%;
                border-right: 1px solid #FD6125;
                border-left: 1px solid #FD6125;
                height: 100%;
            }

            &__order-no {
                width: 11.205%;
                // border: 1px solid black;
                border-left: none;
                // border: 1px solid #FD6125;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: calc(20px * var(--heightmultiplier));

                &:hover {
                    color: #FD6125;
                    cursor: pointer;
                }

            }

            &__username {
                width: 16.205%;
                // border: 1px solid black;
                // border-left: none;
            }

            &__source {
                width: 13.705%;
                // border: 1px solid black;
            }

            &__destination {
                width: 13.705%;
                // border: 1px solid black;
            }

            &__payload {
                width: 11.205%;
                // border: 1px solid black;
            }

            &__delivery-type {
                width: 11.205%;
                // border: 1px solid black;
            }

            &__delivery-time {
                width: 11.3%;
                // border: 1px solid black;
            }


            &__status-update {
                width: 11.5%;
                // border: 1px solid black;
                border-right: none;
                display: flex;
                // flex-direction: column;

                align-items: center;
                justify-content: space-around;

                &__details-btn {
                    width: fit-content;
                    height: calc(40px * var(--heightmultiplier));
                    padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    border: 1px solid #9d85f6;
                    background-color: #b3a1f7;
                    color: var(--primaryTextColor);

                    &:hover {
                        cursor: pointer;
                        background-color: #9d85f6;
                    }
                }

                &__btn {
                    width: fit-content;
                    padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    border: 1px solid #4285F4;
                    background-color: rgba($color: #4285F4, $alpha: 0.4);
                    color: var(--primaryTextColor);

                    &:hover {
                        cursor: pointer;
                        background-color: rgba($color: #4285F4, $alpha: 1);
                    }
                }
            }

            &__order-details {
                width: 10%;
                // border: 1px solid black;
                border-right: none;


                &__btn {
                    width: fit-content;
                    padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    border: 1px solid #4285F4;
                    background-color: rgba($color: #4285F4, $alpha: 0.4);
                    color: var(--primaryTextColor);

                    &:hover {
                        cursor: pointer;
                        background-color: rgba($color: #4285F4, $alpha: 1);
                    }
                }
            }

        }
    }
}