@import './components/variables';

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/fonts/GeneralSans-Variable.woff2') format('woff2'),
    url('./fonts/fonts/GeneralSans-Variable.woff') format('woff'),
    url('./fonts/fonts/GeneralSans-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

html {
  font-size: 16px;
}

body {
  font-family: "General Sans";

}


.app-page-container {

  --widthmultiplier: 1;
  --heightmultiplier: 1;
  --primaryColor: white;
  --secondaryColor: #F2F2F2;
  --primaryTextColor: #08030A;
  --secondaryTextColor: #e2e2e2;
  --successColor: rgba($color: #3FDA76, $alpha: 0.2);
  --ongoingColor: rgba($color: #FECC02, $alpha: 0.2);
  --cancelColor: rgba($color: #F44242, $alpha: 0.2);
  height: calc(1080px * var(--heightmultiplier));
  width: calc(1920px * var(--widthmultiplier));
  // overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: aquamarine;
  box-sizing: border-box;
  background-color: var(--secondaryColor);
  color: var(--primaryTextColor);

  &__themeSwitch {
    position: absolute;
    right: 1%;
    bottom: 1%;
    box-sizing: border-box;
    padding: 0px;
    height: calc(20px * var(--heightmultiplier));
    width: calc(40px * var(--widthmultiplier));
    border: 1px solid #FD6125;
    border-radius: calc(10px * var(--heightmultiplier));
    ;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0px 0px 10px #FD6125;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    &__circle {
      height: 100%;
      width: 50%;
      border-radius: 50%;
      transition: all 0.2s linear;
      transform: translateX(calc(10px * var(--widthmultiplier)));
      background-color: #FD6125;
    }
  }

}