.AssignPilotPopup-modalBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /* border: 1px solid black; */
  }
  
  // .DroneDetailPopup-leftSide{
  //   width: 50%;
  //   height: fit-content;
  // }
  .AssignPilotPopup-modalContainer-main{
    width: 70%;
    height: 80%;
  
    border-radius: 0.75em;
  
    background-color: var(--primaryColor);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
      //font-size: calc(24px * var(--heightmultiplier));
  box-shadow: 0px 0px 15px #fd6125;
  }
  .AssignPilotPopup-modalContainer {
    width: 100%;
    height: 85%;
  
    border-radius: 0.75em;
  
    background-color: var(--primaryColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // border: 5px solid #FC6025;
    /* padding-top: calc(50px);
      padding-bottom: calc(50px); */

  // padding: calc(20px * var(--widthmultiplier));
  &__heading{
    width:100%;
    height: 10%;
    // border: 2px solid yellow;
    display: flex;
    // flex-direction: column;
     align-items: center;
     justify-content: space-evenly;
     font-size:  calc(32px * var(--heightmultiplier));
  }
  &__flexBox{
    width: 100%;
    height: 90%;
    padding: calc(5px * var(--heightmultiplier));
    display: flex;
   // flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid rgb(14, 144, 14);
  }

  &__leftSide {
    width: 60%;
    height: 100%;
    // border: 1px solid black;
    overflow-y: scroll;
    
  }

  &__rightSide {
    width: 40%;
    height: 100%;
    // border: 1px solid black;
  }

  &__item {
    font-size: calc(18px * var(--widthmultiplier));
    margin-top: calc(5px * var(--heightmultiplier));
    margin-bottom: calc(5px * var(--heightmultiplier));
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(0px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

    //border: 1px solid black;
    &__title {
      width: 50%;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__value {
      width: 50%;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    

    // border: 1px solid black;
    //margin-top: calc(10px * var(--heightmultiplier));
    &__Btn {
      box-sizing: border-box;
      width: calc(120px * var(--widthmultiplier));
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: calc(32px * var(--heightmultiplier));
      background-color: rgba(253, 97, 37, 1);
      border-radius: 5px;
      color: white;
      border: 1px solid #fd6125;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
        background-color: #fc6025;
      }
    }
  }

  &__field {
    width: 90%;

    font-size: calc(18px * var(--widthmultiplier));
    margin-top: calc(5px * var(--heightmultiplier));
    margin-bottom: calc(5px * var(--heightmultiplier));
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(0px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

    &__label {
      width: 50%;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__options {
      width: 50%;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__select {
        width: 100%;
        border: 1px solid #fd6125;
        background-color: var(--secondaryColor);
        color: var(--primaryTextColor)
      }
    }
  }
}