.AddMissionPopup-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* border: 1px solid black; */
}



.AddMissionPopup-modalContainer {
  width: 70%;
  height: 80%;

  border-radius: 0.75em;

  background-color: var(--primaryColor);
  ;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // border: 5px solid #FC6025;
  /* padding-top: calc(50px);
      padding-bottom: calc(50px); */

  padding: calc(15px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));

  font-size: calc(50px * var(--heightmultiplier));
  box-shadow: 0px 0px 15px #FD6125;

  &__title {
    width: 100%;
    height: 10%;
    font-weight: 500;
    // border: 1px solid red;
  }

  &__subContainer {
    width: 100%;
    height: 60%;
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__left {
      width: 52%;
      height: 85%;
      // border: 1px solid blue;
      padding: calc(10px * var(--heightmultiplier));
      overflow: auto;
    }

    &__right {
      width: 46%;
      height: 85%;
      // border: 1px solid blue;
      margin-left: calc(10px * var(--widthmultiplier));
      padding: calc(5px * var(--heightmultiplier));
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-evenly;
      &__input-row-cont {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        vertical-align: top;
        font-size: calc(20px * var(--heightmultiplier));
        margin-bottom: calc(10px * var(--heightmultiplier));
        //width: calc(900px * var(--widthmultiplier));
        border: 1px solid #fd6125;
        border-radius: calc(
          10px * (var(--heightmultiplier) * var(--widthmultiplier)) / 2
        );
        overflow: hidden;
        padding: calc(5px * var(--heightmultiplier))
          calc(20px * var(--widthmultiplier));
        // border: 1px solid white;
        &__input {
          color: var(--primaryTextColor);
          background-color: var(--secondaryColor);
          outline: none;
          border: none;
          margin-left: calc(8px * var(--widthmultiplier)) ;
          // border: 1px solid #FD6125;
          // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
          // width: fit-content;
          width: 65%;
        }
    
        &__label {
          color: var(--primaryTextColor);
          margin-right: 10px;
          height: calc(27px * var(--heightmultiplier));
          width: 35%;
          font-weight: 500;
        }
      }
    }


    &__title {
      width: 100%;
      height: fit-content;
      font-size: calc(32px * var(--heightmultiplier));
      font-weight: 400;
      // border: 1px solid red;
    }

    &__dataContainer {
      width: 100%;
      height: fit-content;
      display: flex;
      //flex-direction: row;
      align-items: center;
      text-align: center;
      justify-content: space-around;
      font-size: calc(20px * var(--heightmultiplier));
      //border: 1px solid blue;
      padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
      overflow: auto;

      &__label {
        width: 38%;
        height: fit-content;
        // border: 1px solid yellow;
        text-align: start;
        overflow: hidden;
      }

      &__colon {
        width: 4%;
        height: fit-content;
        // border: 1px solid yellow;
        overflow: hidden;
        margin-right: calc(7px * var(--widthmultiplier));

      }

      &__value {
        width: 58%;
        height: fit-content;
        // border: 1px solid yellow;
        text-align: start;
        overflow: hidden;
      }
    }

    &__forward {
      width: 100%;
      height: 47%;
      border: 2px solid var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));

      &__fileName {
        width: 100%;
        height: fit-content;
        font-size: calc(26px * var(--heightmultiplier));
        line-height: calc(26px * var(--heightmultiplier));
      }

      &__textField {
        width: 50%;
        height: fit-content;
        //border: 1px solid red;
        background-color: lightgrey;
        font-size: calc(26px * var(--heightmultiplier));
        line-height: calc(26px * var(--heightmultiplier));
      }

      &__hidden {
        display: none;
      }

      &__button {
        border: none;
        width: 20%;
        font-size: calc(24px * var(--heightmultiplier));
        font-weight: 500;
        height: calc(60px * var(--heightmultiplier));
        border-radius: calc(10px * var(--heightmultiplier));
        // border-radius: calc(10px * var(--heightmultiplier));
        background-color: rgba($color: #fd6125, $alpha: 0.1);
        color: #fd6125;
        margin: 05px;

        &:hover {
          cursor: pointer;
          color: white;
          background-color: rgba($color: #fd6125, $alpha: 1);
        }
      }

      &__DivBtn {
        width: 100%;
      }

      &__btn {
        border: none;
        width: 20%;
        font-size: calc(24px * var(--heightmultiplier));
        font-weight: 500;
        height: calc(60px * var(--heightmultiplier));
        border-radius: calc(10px * var(--heightmultiplier));
        // border-radius: calc(10px * var(--heightmultiplier));
        background-color: rgba($color: #6CCC54, $alpha: 0.2);
        border: 2px inset #6CCC54;
        color: var(--primaryTextColor);
        margin: 05px;

        &:hover {
          cursor: pointer;
          background-color: rgba($color: #6CCC54, $alpha: 1);
          color: white;
        }
      }
    }

    &__return {
      width: 100%;
      height: 47%;
      border: 2px solid var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));

      &__fileName {
        width: 100%;
        height: fit-content;
        font-size: calc(26px * var(--heightmultiplier));
        line-height: calc(26px * var(--heightmultiplier));
      }

      &__textField {
        width: 50%;
        height: fit-content;
        background-color: lightgrey;
        font-size: calc(26px * var(--heightmultiplier));
        line-height: calc(26px * var(--heightmultiplier));
      }
    }
  }

  &__SubmitBtn {

    box-sizing: border-box;
    width: calc(120px * var(--widthmultiplier));
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: calc(28px * var(--heightmultiplier));
    background-color: #90b7f6;
    border-radius: 5px;
    color: white;

    &:hover {
      cursor: pointer;
      background-color: #4285F4;
    }
  }
  &__button{
    width:100%;
    height:10%;
    // border: 1px solid red;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__OkayBtn {
      // border: 1px solid yellow;
      box-sizing: border-box;
      width: calc(150px * var(--widthmultiplier));
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 600;
      font-size: calc(32px * var(--heightmultiplier));
      background-color: rgba(253, 97, 37, 1);
      border-radius: 5px;
      color: white;
  
      &:hover {
        cursor: pointer;
        background-color: #FC6025;
      }
    }
  }
}