.OrderDetailModel {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  // z-index: 101;
}

.OrderDetailContainer {
  width: 68%;
  height: 70%;
  // border: 1px solid red;
  display: block;
  position: relative;


  border-radius: 0.75em;

  background-color: var(--primaryColor);
  box-shadow: #FD6025 0px 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  // border: 5px solid #FC6025;

  // align-items: center;
  // justify-content: space-between;
  //background-color: white;
  padding: calc(20px * var(--heightmultiplier));
  //font-size: calc(14px * var(--heightmultiplier));

  &__cancelBtn {
    width: 2%;
    height: fit-content;
    position: absolute;
    // z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    right: calc(20px * var(--heightmultiplier));
    top: calc(20px * var(--widthmultiplier));
    padding: calc(2px * var(--widthmultiplier)) calc(5px * var(--heightmultiplier));
    font-size: calc(25px * var(--heightmultiplier));
    line-height: calc(25px * var(--heightmultiplier));
    border-radius: 5px;

    border: 1px solid var(--primaryTextColor);

    &:hover {
      cursor: pointer;
      // font-weight: 500;
      background-color: var(--primaryTextColor);
      color: var(--primaryColor);
    }
  }

  &__heading {
    width: 100%;
    height: 8%;
    //width: 70%;
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    // border: 2px solid orangered;
    //justify-content: space-between;
    font-size: calc(28px * var(--heightmultiplier));
    //line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    // margin: calc(2px * var(--widthmultiplier)) calc(5px * var(--heightmultiplier));

    &__h1 {
      width: 100%;
      height: 100%;
      // border: 1px solid green;
    }
  }

  &__Container {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid red;
    // padding: calc(10px * var(--heightmultiplier))
  }

  &__detailsContainer {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    justify-content: flex-start;
    // border: 2px solid rgb(59, 11, 190);

    &__Title {
      width: 100%;
      height: 10%;
      box-sizing: border-box;
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 500;
      font-size: calc(24px * var(--heightmultiplier));
      // line-height: calc(54px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      //border: 1px solid red;
      // margin-bottom: calc(48px * var(--heightmultiplier))
    }

    &__row2 {
      width: 100%;
      height: 10%;
      box-sizing: border-box;
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: calc(20px * var(--heightmultiplier));
      // line-height: calc(27px * var(--heightmultiplier));
      color: var(--primaryTextColor);

      // margin-left: calc(3px * var(--heightmultiplier));
      &__flightInput {
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: black;
        gap: calc(10px * var(--widthmultiplier));

        &__btn {
          font-weight: 500;
          background-color: rgba($color: #FD6125, $alpha: 0.4);
          padding: calc(2px * var(--widthmultiplier)) calc(6px * var(--widthmultiplier));
          border-radius: calc(6px * var(--widthmultiplier));

          &:hover {
            cursor: pointer;
            color: white;

            background-color: rgba($color: #FD6125, $alpha: 1);
          }
        }
      }
    }

    &__sub-Container {
      width: 100%;
      height: 70%;
      //padding: calc(5px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // border: 2px solid rgb(67, 38, 226);

      &__title {
        width: 100%;
        height: 15%;
        // border: 1px solid yellow;
        display: flex;
        // flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(206, 206, 207);
        color: black;
        border-radius: calc(10px * var(--heightmultiplier));

        &__Sr-No {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          //color: var(--primaryTextColor);
          // border: 1px solid magenta
        }

        &__name {
          height: 100%;
          width: 38%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          // color: var(--primaryTextColor);
          // border: 1px solid magenta
        }

        &__weight {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          // color: var(--primaryTextColor);
          // border: 1px solid magenta
        }

        &__Volumetricweight {
          height: 100%;
          width: 14%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          //color: var(--primaryTextColor);
          // border: 1px solid magenta
        }

        &__quantity {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          //color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__totalWeight {
          height: 100%;
          width: 15%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          //color: var(--primaryTextColor);
          //border: 1px solid magenta
        }
      }

      &__dataContainer {
        height: 70%;
        width: 100%;
        //border: 2px solid red;
        padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        overflow-y: auto;
      }

      &__listDown {
        width: 100%;
        height: 20%;
        // border: 1px solid yellow;
        display: flex;
        // flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        border-radius: calc(10px * var(--heightmultiplier));
        background-color: var(--secondaryColor);
        // overflow-y: auto;

        &:nth-of-type(even) {
          background-color: var(--primaryColor);
        }

        &__Sr-No {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__name {
          height: 100%;
          width: 38%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);

          // text-align: left;
          // justify-self: center;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // border: 1px solid magenta;
          &__text {
            height: fit-content;
            width: 100%;
            // border: 1px solid midnightblue;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

          }
        }

        // &__unit-price{
        //   height: 100%;
        //   width: 12%;
        //   font-family: 'General Sans';
        //   font-style: normal;
        //   display: flex;
        //     align-items: center;
        //     justify-content: center;
        //   font-weight: 500;
        //   font-size: calc(20px * var(--heightmultiplier));
        //   color: var(--primaryTextColor);
        //     border: 1px solid magenta

        // }
        &__weight {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          // border: 1px solid magenta
        }

        &__Volumetricweight {
          height: 100%;
          width: 14%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__quantity {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__totalWeight {
          height: 100%;
          width: 15%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        // &__total-price{
        //   height: 100%;
        //   width: 12%;
        //   font-family: 'General Sans';
        //   font-style: normal;
        //   display: flex;
        //     align-items: center;
        //     justify-content: center;
        //   font-weight: 500;
        //   font-size: calc(20px * var(--heightmultiplier));
        //   color: var(--primaryTextColor);
        //     border: 1px solid magenta
        // }
      }

      &__totalWeight {
        width: 100%;
        height: 20%;
        //border: 1px solid yellow;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background-color: var(--primaryColor);

        &__title {
          height: 100%;
          width: 20%;
          font-family: 'General Sans';
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__value {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }
      }
    }
  }

  &__Right {
    width: 49%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    justify-content: flex-start;
    //border: 2px solid rgb(59, 11, 190);
  }

  &__dataContainer {
    width: 100%;
    height: 10%;
    box-sizing: border-box;
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: calc(24px * var(--heightmultiplier));
    // line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // border: 2px solid rgb(59, 11, 190);
    padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
  }

  &__content {
    width: 100%;
    height: 80%;
    // line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // border: 2px solid rgb(59, 11, 190);
    padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));

    &__customerDataContainer {
      position: relative;
      width: 80%;
      height: 25%;
      // border: 1px solid red;
      //   display: flex;
      //  // flex-direction: row;
      //   text-align: center;
      //   align-items: center;
      //   justify-content: center;
      border-radius: calc(10px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      border: 1px solid var(--primaryTextColor);

      &__label {
        position: absolute;
        width: fit-content;
        margin-left: calc(10px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier))calc(5px * var(--widthmultiplier));
        height: fit-content;
        //border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        font-weight: 500;
        display: flex;
        text-align: flex-start;
        align-items: center;
        top: calc(-13px * var(--heightmultiplier));
        left: 0px;
        background-color: var(--primaryColor);
        color: var(--primaryTextColor)
      }

      &__value {
        width: 100%;
        height: 50%;
        // display: flex;
        // flex-direction: row;
        // text-align: center;
        // align-items:center;
        // justify-content: space-around;
        // padding-top: calc(20px * var(--heightmultiplier));
        margin-top: calc(10px * var(--heightmultiplier));

        //margin-left: calc(-100px * var(--heightmultiplier));
        &__UserDataContainer {
          width: 100%;
          height: fit-content;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          font-family: 'General Sans';
          font-style: normal;
          font-weight: 400;
          font-size: calc(16px * var(--heightmultiplier));
          //line-height: calc(27px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          margin-top: calc(2px * var(--heightmultiplier));
          margin-right: calc(35px * var(--heightmultiplier));
          padding-right: 0px;
          padding-left: calc(15px * var(--widthmultiplier));

          // border: 1px solid orchid;
          &__label {
            width: 22%;
            // border: 1px solid rgb(112, 119, 218);
            height: fit-content;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 500;
            overflow: hidden;
            display: flex;
            text-align: center;
            justify-content: flex-start;
            // white-space: nowrap;
            // text-overflow: ellipsis;
          }

          &__colon {
            width: 2%;
            // border: 1px solid rgb(124, 218, 112);
            height: fit-content;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 500;
          }

          &__value {
            width: 75%;
            min-width: 75%;
            // border: 1px solid rgb(218, 116, 112);
            height: fit-content;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 500;
            // display: flex;
            // text-align: center;
            // justify-content: flex-start;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            // &__email {
            //   width: 100%;
            //   min-width: 100%;
            //   display: block;
            //   overflow: hidden;
            //   white-space: nowrap;
            //   text-overflow: ellipsis;
            //   display: flex;
            //   text-align: center;
            //   justify-content: flex-start;
            // }
          }
        }
      }

    }

    &__status {
      width: 100%;
      height: 73%;
      // border: 1px solid red;
      overflow-y: auto;

    }

    &__user {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      align-items: center;
      justify-content: flex-start;

      // border: 2px solid rgb(11, 190, 17);
      //padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        // text-overflow: ellipsis;
        // border: 1px solid green;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__type {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      text-align: center;
      justify-content: flex-start;

      // border: 2px solid rgb(11, 190, 17);
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }

    &__startLoc {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      justify-content: flex-start;

      // border: 2px solid rgb(11, 190, 17);
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }

    &__endLoc {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      justify-content: flex-start;

      //border: 2px solid rgb(11, 190, 17);
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }

    &__date {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      justify-content: flex-start;
      //border: 2px solid rgb(11, 190, 17);
      font-size: calc(18px*var(--heightmultiplier));

      //padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }
  }

  &__button {
    width: 100%;
    height: 10%;
    //border: 1px solid #F25858;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__orderCancel {
      width: 25%;
      height: 100%;
      border: 2px solid red;
      background-color: rgba($color: red, $alpha: 0.2);
      font-size: calc(18px*var(--heightmultiplier));
      font-weight: 500;
      border-radius: calc(5px * var(--heightmultiplier));
      color: red;

      &:hover {
        cursor: pointer;
        background-color: rgba($color: red, $alpha: 1);
        color: white;
      }
    }
  }

  &__Options {
    width: 100%;
    height: 10%;
    // border: 1px solid pink;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
  }
}

.SchedulePopup {

  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  // z-index: 19;
  /* border: 1px solid black; */
  &__popup {
    width: 34%;
    height: 34%;
    position: relative;
    border-radius: 0.75em;
    background-color: var(--primaryColor);
    box-shadow: #FD6025 0px 0px 15px;
    padding: calc(24px * var(--heightmultiplier));


    /* border: 5px solid #FC6025; */
    /* padding-top: calc(50px);
padding-bottom: calc(50px); */
    &__title {
      width: 100%;
      height: 10%;
      //border: 1px solid red;
      font-size: calc(28px * var(--heightmultiplier));
      font-weight: 500;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-around;
    }

    &__body {
      height: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      &__schedule-btn {
        // width: 25%;
        color: var(--primaryTextColor);
        font-size: calc(20px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        // margin: 0px 10px;
        border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
        background-color: rgba($color: #6CCC54, $alpha: 0.2);
        border: 1px solid #6CCC54;

        &:hover {
          background-color: rgba($color: #6CCC54, $alpha: 1);
          border: 1px solid #6CCC54;
          color: white;
        }

        &:focus {
          background-color: rgba($color: #6CCC54, $alpha: 1);
          border: 1px solid #6CCC54;
          color: white;
        }
      }
    }

    &__close {
      width: 4%;
      height: fit-content;
      position: absolute;
      // z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      right: calc(20px * var(--heightmultiplier));
      top: calc(20px * var(--widthmultiplier));
      padding: calc(2px * var(--widthmultiplier)) calc(5px * var(--heightmultiplier));
      font-size: calc(25px * var(--heightmultiplier));
      line-height: calc(25px * var(--heightmultiplier));
      border-radius: 5px;
      background-color: var(--primaryColor);
      border: 1px solid var(--primaryTextColor);

      &:hover {
        cursor: pointer;
        // font-weight: 500;
        background-color: var(--primaryTextColor);
        color: var(--primaryColor);
      }
    }
  }


}

.OrderDetailsTab-dronedropDown-cont {
  &__input {
    color: black;
  }
}