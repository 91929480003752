.tab-content-container {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: calc(20px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__formContainer {
        width: fit-content;
        height: fit-content;

        &__input-row-cont {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: calc(20px * var(--heightmultiplier));
            margin-bottom: calc(10px * var(--heightmultiplier));
            width: calc(900px * var(--widthmultiplier));
            border: 1px solid #FD6125;
            border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
            overflow: hidden;
            padding: calc(5px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

            &__input {
                color: var(--primaryTextColor);
                background-color: var(--secondaryColor);
                outline: none;
                border: none;
                // border: 1px solid #FD6125;
                // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
                // width: fit-content;
                width: calc(600px * var(--widthmultiplier));
            }

            &__label {
                color: var(--primaryTextColor);
                margin-right: 10px;
                height: calc(27px * var(--heightmultiplier));
                width: calc(200px * var(--widthmultiplier));
                font-weight: 500;
            }
        }

        &__date-row-cont {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: calc(20px * var(--heightmultiplier));
            margin-bottom: calc(10px * var(--heightmultiplier));
            width: calc(900px * var(--widthmultiplier));
            border: 1px solid #FD6125;
            border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
            overflow: hidden;
            padding: calc(20px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));


            &__label {
                width: calc(250px * var(--widthmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: calc(24px * var(--heightmultiplier));
                font-weight: 500;
            }

            &__value {
                width: calc(250px * var(--widthmultiplier));
                font-size: calc(20px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__dropdown-row-cont {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: calc(20px * var(--heightmultiplier));
            margin-bottom: calc(10px * var(--heightmultiplier));
            width: calc(900px * var(--widthmultiplier));
            border: 1px solid #FD6125;
            border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
            overflow: hidden;
            padding: calc(5px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

            &__input {
                color: var(--primaryTextColor);
                background-color: var(--secondaryColor);
                outline: none;
                border: none;
                // border: 1px solid #FD6125;
                // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
                // width: fit-content;
                width: calc(600px * var(--widthmultiplier));
            }

            &__label {
                color: var(--primaryTextColor);
                margin-right: 10px;
                height: calc(27px * var(--heightmultiplier));
                width: calc(200px * var(--widthmultiplier));
                font-weight: 500;
            }

        }
    }

    &__create-flight-btninActive {
        width: calc(200px * var(--widthmultiplier));
        padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier));
        font-size: calc(24px * var(--heightmultiplier));
        font-weight: 500;
        border: none;
        border-radius: calc(15px * var(--heightmultiplier));
        background-color: rgba($color: #504f4f, $alpha: 0.5);
        color: black;

        // &:hover {
        //     font-weight: 600;
        //     background-color: #FD6125;
        //     color: white;
        // }
    }
    &__create-flight-btnActive {
        width: calc(200px * var(--widthmultiplier));
        padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier));
        font-size: calc(24px * var(--heightmultiplier));
        font-weight: 600;
        border: none;
        border-radius: calc(15px * var(--heightmultiplier));
        background-color: rgba($color: #FD6125, $alpha: 1);
        color: white;

        // &:hover {
        //     font-weight: 600;
        //     background-color: #FD6125;
        //     color: white;
        // }
    }
}