table, th, td {
    border: 1px solid var(--primaryTextColor);
  }
//   .checklistInputForm-container-main{
//     width: 100%;
//     height: 100%;
//   }
.checklistInputForm-container{
    width: 100%;
    height: 100%;

    &__index{
        position: sticky;
        top: 0px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--primaryColor);

        &__SrNo{
            min-width: fit-content;
            max-width: 5%;
            width: 5%;
            border: 1px solid var(--primaryTextColor);
            text-align: center;
        }
        &__confirm{
            min-width: fit-content;
            max-width: 6%;
            width: 6%;
            border: 1px solid var(--primaryTextColor);
            text-align: center;
        }
        &__label{
            min-width: fit-content;
            max-width: 20%;
            width: 20%;
            border: 1px solid var(--primaryTextColor);
            text-align: center;
        }
        &__parameters{
            min-width: fit-content;
            max-width: 45%;
            width: 45%;
            border: 1px solid var(--primaryTextColor);
            text-align: center;
        }
        &__notes{
            min-width: fit-content;
            max-width: 25%;
            width: 25%;
            border: 1px solid var(--primaryTextColor);
            text-align: center;
        }
        
    }
}