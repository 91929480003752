.FlightDataViewer-container {
    width: 100%;
    height: 100%;
    // background-color: rgb(79, 88, 112);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &__filter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        // border: 1px solid white;
        gap: 10px;

        &__duration {
            gap: 5px;
            display: flex;
            align-items: center;
            justify-content: start;
            // border: 1px solid white;

            &__label {
                font-size: calc(30px * var(--heightmultiplier));
                height: 100%;
                display: flex;
                align-items: center;
            }
        }

        &__applyBtn{
            width: calc(100px * var(--widthmultiplier));
            padding: calc(5px * var(--heightmultiplier)) calc(2px * var(--heightmultiplier));
            font-size: calc(24px * var(--heightmultiplier));
            font-weight: 600;
            border: none;
            border-radius: calc(15px * var(--heightmultiplier));
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;
    
        }
    }

    &__viewer-cont {
        width: 100%;
        height: 92%;
        // border: 1px solid green;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &__charts {
            width: 100%;
            height: 100%;
            // border: 1px solid white;
            display: flex;
            align-items: center;
            // flex-wrap: wrap;
            justify-content: space-around;

            &__table{
                width: 40%;
                max-height: 100%;


                &__thead{
                    font-size: calc(20px * var(--heightmultiplier));
                }

                &__tbody{
                    font-size: calc(20px * var(--heightmultiplier));
                    overflow-y: auto;
                }
            }

            &__pie{
                font-size: calc(20px * var(--heightmultiplier));
                height: calc(400px * var(--heightmultiplier));
                width: calc(400px * var(--widthmultiplier));
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__settings {
            width: 19%;
            height: 100%;
            border: 1px solid white;
        }
    }

}