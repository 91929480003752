.ReschedulePopup-modalBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    // z-index: 100;
    //  border: 1px solid blueviolet;

    &__reschedule {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: rgba(6, 6, 6, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;

        // z-index: 19;
        /* border: 1px solid black; */
        &__popup {
            width: 40%;
            height: 40%;
            position: relative;
            border-radius: 0.75em;
            background-color: var(--primaryColor);
            box-shadow: #FD6025 0px 0px 15px;
            padding: calc(12px * var(--heightmultiplier));


            /* border: 5px solid #FC6025; */
            /* padding-top: calc(50px);
    padding-bottom: calc(50px); */
            &__title {
                width: 100%;
                height: 10%;
                //border: 1px solid red;
                font-size: calc(28px * var(--heightmultiplier));
                font-weight: 500;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: space-around;

            }

            &__body {
                height: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                margin-top: 1rem;

                &__textArea {

                    width:70%;
                    height:  calc(70px * var(--heightmultiplier));
                    padding: 10px;
                    border: 1px solid;
                    border-radius: 5px;
                    resize: vertical;
                    color: var(--primaryTextColor);
                    background-color: var(--primaryColor);
                }
                &__error{
                    font-size: calc(18px * var(--heightmultiplier));
                    color: red;
                    
                }
            }
        }
    }

}



.ReschedulePopup-modalContainer {
    width: 70%;
    height: 75%;

    border-radius: 0.75em;

    background-color: var(--primaryColor);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: calc(10px * var(--heightmultiplier));
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    //  border: 5px solid #FC6025; 
    /* padding-top: calc(50px);
    padding-bottom: calc(50px); */
    // border: 1px solid blueviolet;

    //padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

    // font-size: calc(50px * var(--heightmultiplier));
    box-shadow: 0px 0px 15px #FD6125;
    position: relative;

    &__close {
        position: absolute;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        right: calc(20px * var(--heightmultiplier));
        top: calc(20px * var(--widthmultiplier));
        padding: calc(2px * var(--widthmultiplier)) calc(5px * var(--heightmultiplier));
        font-size: calc(25px * var(--heightmultiplier));
        line-height: calc(25px * var(--heightmultiplier));
        border-radius: 5px;
        background-color: var(--primaryColor);
        border: 1px solid var(--primaryTextColor);

        &:hover {
            cursor: pointer;
            // font-weight: 500;
            background-color: var(--primaryTextColor);
            color: var(--primaryColor);
        }
    }

    &__header {


        &__title {
            font-size: calc(35px * var(--heightmultiplier));
            font-weight: 500;
        }

        &__location {
            font-size: calc(24px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            width: 100%;

            &__start {
                width: 46.2%;
                text-align: end;
            }

            &__doubleHeadedArrowSvg {
                width: 7.5%;
                padding-inline: calc(13px * var(--heightmultiplier));
            }

            &__end {
                width: 40%;
                text-align: start;
            }
        }
    }

    &__body {
        width: 100%;
        display: flex;
        max-height: calc(400px * var(--heightmultiplier));
        overflow-y: auto;
        flex-direction: column;
        margin: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));

        &__item {
            font-size: calc(20px * var(--widthmultiplier));
            margin-bottom: calc(5px * var(--heightmultiplier));
            width: 100%;
            display: flex;

            &__title {
                width: 50%;
                font-weight: 500;
                text-align: start;
                padding-left: 12%;
            }

            &__value {
                width: 50%;
                padding-left: 10px;
                text-align: start;
            }
        }
    }


    &__footer {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: self-start;
        position: absolute;
        // align-self: self-start;
        bottom: calc(25px * var(--heightmultiplier));



        &__schedule {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: self-start;
            gap: calc(10px * var(--heightmultiplier));
            padding-left: 10%;
            // align-self: self-start;

            &__schedule-btn {
                // width: 25%;
                color: var(--primaryTextColor);
                font-size: calc(20px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                // margin: 0px 10px;
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #6CCC54, $alpha: 0.2);
                border: 1px solid #6CCC54;

                &:hover {
                    background-color: rgba($color: #6CCC54, $alpha: 1);
                    border: 1px solid #6CCC54;
                    color: white;
                }

                &:focus {
                    background-color: rgba($color: #6CCC54, $alpha: 1);
                    border: 1px solid #6CCC54;
                    color: white;
                }
            }

            // &__datePicker {
            //     // z-index: 10;
            // }
        }

        &__btns {
            // margin-top: ;
            width: 60%;
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }

        &__cancel {

            &__cancel-btn {
                // width: 25%;
                color: var(--primaryTextColor);
                font-size: calc(20px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #F25858, $alpha: 0.2);
                border: 1px solid #F25858;

                &:hover {
                    background-color: rgba($color: #F25858, $alpha: 1);
                    border: 1px solid#F25858;
                    color: white;
                }

                &:focus {
                    background-color: rgba($color: #F25858, $alpha: 1);
                    border: 1px solid #F25858;
                    color: white;
                }
            }
        }
    }

}

.rs-input {
    height: calc(10px var(--heightmultiplier)) !important;
    font-size: calc(16px * var(--widthmultiplier)) !important;
    padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--heightmultiplier)) !important;
    background-color: transparent !important;
    overflow: hidden !important;
    color: var(--primaryTextColor) !important
}

.rs-input-group-addon {
    padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier)) !important;
    background-color: transparent !important;
    color: var(--primaryTextColor) !important
}

.rs-input-group {
    color: var(--primaryTextColor) !important;
    background-color: transparent !important;
    border: 2px solid var(--primaryTextColor) !important;
}