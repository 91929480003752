.AllFlightsTabContent-container {
    position: relative;
    top: 0px;
    left: 0px;
    // z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__filter {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: calc(80px * var(--heightmultiplier));
        width: 97%;

        &__title {
            // background-color: #FD6125;
            color: var(--primaryTextColor);
            font-size: calc(30px * var(--heightmultiplier));
            margin-left: calc(5px * var(--widthmultiplier));
        }

        &__options {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid #FD6125;
            height: 80%;
            // padding-left: calc(20px * var(--heightmultiplier));

            &__item {
                color: var(--primaryTextColor);
                font-size: calc(24px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #FD6125, $alpha: 0.1);

                &:hover {
                    cursor: pointer;
                    background-color: rgba($color: #FD6125, $alpha: 0.5);
                    border-color: #FD6125;
                    color: white;
                }
            }

            &__selected-item {
                color: white;
                font-size: calc(24px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #FD6125, $alpha: 1);

                // &:hover {
                //     background-color: rgba($color: #FD6125, $alpha: 0.5);
                //     border-color: #FD6125;
                //     color: white;
                // }
            }
        }

        &__duration-title {
            color: var(--primaryTextColor);
            font-size: calc(24px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
        }

        &__duration-select {
            color: black;
            font-size: calc(20px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-color: #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            // &__option{

            // }
        }
    }




    &__index {
        position: sticky;
        top: 0px;
        width: 98%;
        height: calc(50px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: rgba($color: #FD6125, $alpha: 1);
        color: white;
        // margin-top: 10px;
        border-radius: 5px;
        // margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // margin-right: 17px;
        font-size: calc(20px * var(--heightmultiplier));
        font-weight: 400;
        // width: 30%;
        // height: 100%;

        // border: 1px solid rgb(219, 11, 11);

        &__term {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: calc(24px * var(--heightmultiplier));
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 30%;
            border-right: 1px solid white;
            border-left: 1px solid white;
            height: 100%;

            -moz-user-select: -moz-none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        &__flight-type {
            width: 10.05%;
            // border: 1px solid black;

        }

        &__drone {
            width: 18%;
            // border: 1px solid black;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__source {
            width: 10%;
            // border: 1px solid black;
        }

        &__destination {
            width: 10%;
            // border: 1px solid black;
        }

        &__payload {
            width: 6%;
            // border: 1px solid black;
        }

        &__distance {
            width: 7%;
            // border: 1px solid black;
        }

        &__flight-date {
            width: 11.25%;
            // border: 1px solid black;
        }

        &__start-time {
            width: 9.20%;
            // border: 1px solid black;
        border-left: none;

        }

        &__time-taken {
            width: 8.5%;
            // border: 1px solid black;
        }

        &__order-details {
            width: 10%;
            // border: 1px solid black;
            border-right: none;


            &__btn {
                padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            }
        }
    }

    &__pagination {
        width: 98%;
        height: fit-content;
        // border: 1px solid red;
        position: sticky;
        bottom: 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }


}

.AllFlightsTabContent-serach {
    width: fit-content;
    height: fit-content;
    // border: 1px solid red;
    margin: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__box {
        width: fit-content;
        height: fit-content;
        border-radius: calc(16px * var(--heightmultiplier));
        margin: calc(2px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        border: 1px solid var(--primaryTextColor);
        outline: none;
        padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__input {
            width: 80%;
            height: fit-content;
            border-radius: calc(16px * var(--heightmultiplier));
            border: none;
            outline: none;
            padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
        }
    }

    &__filterBox {
        width: fit-content;
        height: fit-content;
        // border: 1px solid red;
        margin-left: calc(16px * var(--widthmultiplier));
        // padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));

        &__button {
            width: calc(100px * var(--widthmultiplier));
            height: fit-content;
            padding: calc(4px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            border: 2px solid #FD6125;
            background-color: rgba($color: #FD6125, $alpha: 0.2);
            border-radius: calc(10px * var(--heightmultiplier));

            &:hover {
                cursor: pointer;
                background-color: rgba($color: #FD6125, $alpha: 0.8);
                border-color: #FD6125;
                color: white;
                font-weight: 500;
            }
        }
    }

    &__dispalyParams {
        width: fit-content;
        height: fit-content;
        // border: 1px solid red;
        margin-left: calc(16px * var(--widthmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__container {
            width: fit-content;
            height: fit-content;
            border: 2px solid #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            margin: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            // background-color: #FD6125;
        }

        &__title {
            width: fit-content;
            height: fit-content;
            // border: 1px solid yellow;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: calc(2px * var(--heightmultiplier)) calc(2px * var(--widthmultiplier));
            font-size: large;
            font-weight: 400;
            color: var(--primaryTextColor);
        }

        &__input {
            width: fit-content;
            height: fit-content;
            // border: 1px solid #FD6125;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__InputBox {
                width: fit-content;
                height: fit-content;
                // border: 1px solid red;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        // &__value {
        //     width: fit-content;
        //     height: fit-content;
        //     // border: 1px solid yellow;
        //     display: flex;
        //     flex-direction: row;
        //     align-items: center;
        //     justify-content: flex-start;
        //     padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        //     font-size: large;
        //     font-weight: 400;
        //     color: var(--primaryTextColor);
        // }
        &__select {
            width: fit-content;
            height: fit-content;
            color: black;
            font-size: calc(18px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // padding: 0px 10px;
            margin: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            // border:none;
            border-color: #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
            padding-left: calc(10px * var(--heightmultiplier));
            // border: 1px solid purple;
            // &__option{

            // }
        }

        &__selectFrom {
            width: fit-content;
            height: fit-content;
            color: black;
            font-size: calc(18px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // padding: 0px 10px;
            margin: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            border: none;
            border-color: #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
            // border: 1px solid purple;
            // &__option{

            // }
        }

        &__selectTo {
            width: fit-content;
            height: fit-content;
            color: black;
            font-size: calc(18px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // padding: 0px 10px;
            margin: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            border: none;
            border-color: #FD6125;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
            // border: 1px solid purple;
            // &__option{

            // }
        }

    }

}

.AllFlightsTabContent-datacontainer {
    position: relative;
    top: 0px;
    left: 0px;
    // z-index: 10;
    width: 99%;
    height: 88%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    flex-grow: 1;

    // border: 1px solid red;
    &__row-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        // padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // background-color: var(--ongoingColor);
        margin-top: 10px;
        border-radius: 5px;
        border: 1px solid #FD6125;
        // overflow: hidden;

        // &:nth-child(odd) {
        //     background-color: rgba($color: #E2E2E2, $alpha: 0.5); // Light gray for odd rows
        // }

        // &:nth-child(even) {
        //     background-color: rgba($color: #FD6125, $alpha: 0.3); // White for even rows
        // }
        &__p1 {
            width: 9.30%;
            border: 1px solid #FD6125;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: calc(20px * var(--heightmultiplier));

            &:hover{
                // color: #FD6125;
                // cursor: pointer;
            }
        }
        &__p2{
            width: 90.70%;
            border: 1px solid #FD6125;
            display: flex;
            gap:calc(5px * var(--heightmultiplier));
            flex-direction: column;
            padding: calc(8px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier)) calc(8px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier));
        &__flight{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: calc(40px * var(--heightmultiplier));
            // padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            background-color: var(--ongoingColor);
            // margin-top: 10px;
            border-radius: 5px;
        }
        &__term {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: calc(19px * var(--heightmultiplier));
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 30%;
            border-right: 1px solid #FD6125;
            border-left: 1px solid #FD6125;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__flight-type {
            width: 11.3%;
            // border: 1px solid black;
            border-left: none;

        }

        &__drone {
            width: 20.20%;
            // border: 1px solid black;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__source {
            width: 11.25%;
            // border: 1px solid black;
        }

        &__destination {
            width: 11.30%;
            // border: 1px solid black;
        }

        &__payload {
            width: 6.78%;
            // border: 1px solid black;
        }

        &__distance {
            width: 7.89%;
            // border: 1px solid black;
        }

        &__flight-date {
            width: 12.55%;
            // border: 1px solid black;
        }

        // &__start-time {
        //     width: 12.53%;
        //     // border: 1px solid black;
        // }

        &__time-taken {
            width: 9.57%;
            // border: 1px solid black;
        }

        &__order-details {
            width: 11.35%;
            // border: 1px solid black;
            border-right: none;


            &__btn {
                width: fit-content;
                height: calc(40px * var(--heightmultiplier));
                padding: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                border: 1px solid #4285F4;
                background-color: #7facfa;
                color: var(--primaryTextColor);

                &:hover {
                    cursor: pointer;
                    background-color: #4285F4;
                }
            }
        }
    }
    }
    // &__row-container{
    //     border: 1px solid red;
    //     display: flex;
    //     align-items: center;
    //     width: 99%;
    // }
    &__row-containerGreen {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: calc(40px * var(--heightmultiplier));
        // padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: var(--successColor);
        // margin-top: 10px;
        border-radius: 5px;

    }
    &__row-containerRed {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: calc(40px * var(--heightmultiplier));
        // padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: var(--cancelColor);
        // margin-top: 10px;
        border-radius: 5px;
    }
}