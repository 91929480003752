$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

.ViewFlightsDashboard-PageCard-container {

    width: calc(500px * var(--widthmultiplier));
    height: calc(300px * var(--heightmultiplier));
    border: 1px solid var(--primaryTextColor);
    // margin: calc(15px * var(--widthmultiplier));
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: calc(15px * var(--widthmultiplier));
    overflow: hidden;
    padding: 0px;
    border: none;
    background-color: rgba($color: #9D85F6, $alpha: 0.2);
    border: 1px solid red;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 15px #686868;
        scale: 1.1;
    }

    &__image {
        width: 100%;
        height: 100%;
    }

    &__title {
        font-size: calc(32px * var(--heightmultiplier));
        // border: 1px solid var(--primaryTextColor);
    }
    &__active{
        cursor: pointer;
        box-shadow: 0px 0px 15px #686868;
        scale: 1.1;
    }
}

.ViewFlightsDashboard-PageCard-containerWithImage {

    width: calc(300px * var(--widthmultiplier));
    height: calc(400px * var(--heightmultiplier));
    border: 1px solid var(--primaryTextColor);
    margin: calc(15px * var(--widthmultiplier));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: calc(15px * var(--widthmultiplier));
    overflow: hidden;
    padding: 0px;
    border: none;
    background-color: rgba($color: #FD6125, $alpha: 0.5);
    border: 1px solid red;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 15px #686868;
        scale: 1.1;
    }

    &__image {
        width: 100%;
        height: 100%;
    }

    &__title {
        font-size: calc(32px * var(--heightmultiplier));
        // border: 1px solid var(--primaryTextColor);
    }
}

.ViewFlightsDashboard-container {

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: rgb(237, 233, 234);
    background-color: var(--secondaryColor);
    border: 1px solid green;

    &__Sidebar {
        // flex: 1;
        background-color: var(--primaryColor);
        // border: 2px solid #FD6125;
        // border-left-color: white;
        // border: 2px solid black;
        position: relative;
        box-sizing: border-box;
        width: 15%;
        height: 100%;
        border-top-right-radius: calc(20px * var(--widthmultiplier));
        border-bottom-right-radius: calc(20px * var(--widthmultiplier));
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid var(--secondaryTextColor);

        // @media (max-width: $breakpoint-xl) {
        //     width: 15%;
        // }

        &__logodiv {
            height: 8%;
            width: 88%;
            // background: #f0f0f0;
            display: flex;
            justify-content: center;
            align-items: center;

            // padding: 5px 20px;
            // border-bottom: 2px solid rgb(83, 83, 83);
            &__logoimg {
                margin: calc(10px * var(--heightmultiplier)) calc(12px * var(--widthmultiplier));
                height: 2.4em;
                width: 10em;

                // border: 2px solid #FD6125;
                &:hover {
                    cursor: pointer;
                }

                // @media (max-width: $breakpoint-xl) {
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     height: 2.4em;
                //     width: 10em;
                // }
            }
        }

        &__container {
            height: 80.33%;
            width: 100%;
            // border: 2px solid #0fc8e9;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            padding: calc(10px * var(--heightmultiplier));

            &__block {
                height: 100%;
                width: 100%;
                // border: 1px solid red;
            }
        }

        &__logodiv-border {
            height: 2px;
            width: 90%;
            background-color: var(--secondaryColor);
        }

        &__designation-container {
            position: absolute;
            bottom: 54px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            font-size: calc(24px * var(--heightmultiplier));
            border: 2px solid #FD6125;
            border-radius: calc(10px * var(--heightmultiplier));
            box-sizing: border-box;
            overflow: hidden;
            color: var(--primaryTextColor);
            // margin-top: ;

            &__title {
                font-size: calc(24px * var(--heightmultiplier));
                font-weight: 600;
                background-color: #FD6125;
                color: white;
                padding: calc(5px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));

            }

            &__value {
                cursor: pointer;
                font-size: calc(16px * var(--heightmultiplier));
                font-weight: 500;
                padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
                &:hover{
                    background-color: #FD6125;
                }
            }
        }

        &__logout {
            position: absolute;
            bottom: 8px;
            font-size: calc(24px * var(--heightmultiplier));
            width: calc(145px * var(--widthmultiplier));
            height: calc(40px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #FD6125, $alpha: 0.1);
            border-radius: calc(10px * var(--heightmultiplier));
            font-weight: 500;
            margin-top: calc(15px * var(--heightmultiplier));
            border-color: #FD6125;
            color: var(--primaryTextColor);

            &:hover {
                // text-decoration: underline;
                cursor: pointer;
                background-color: rgba($color: #FD6125, $alpha: 1);
                color: white;
            }
        }
    }

    &__SubModulesView {
        width: 100%;
        height: 100%;
        // border:1px solid red;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        // border: 1px solid red;
        &__header {
            width: 100%;
            min-height: fit-content;
            max-height: 10%;
            // border: 1px solid blue;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &__body{
            width: 100%;
            height: 90%;
            // border: 1px solid blue;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    &__BackButton {
        background-color: transparent;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transform: rotate(90deg);
        // margin-bottom: 20px;
        // &:hover {
        //     cursor: pointer;
        //     box-shadow: 0px 0px 15px #686868;
        // }      

    }

    &__RightContainer {
        width: 100%;
        height: 100%;
        // border: 1px solid red;
        // display: flex;
        // grid-template-columns: auto auto auto;
        // grid-gap: 10px;
        justify-content: center;
        align-items: center;
    }

    &__header {
        width: 100%;
        height: 8.2%;
        border-bottom: 1px solid var(--secondaryTextColor);
        // border: 1px solid var(--primaryTextColor);
        // border-radius: calc(16px * var(--heightmultiplier));
        display: flex;
        justify-content: end;

        &__userIcon {
            width: calc(200px * var(--widthmultiplier));
            height: 100%;
            // background-color: var(--secondaryColor);
            // border: 1px solid red;
            display: flex;
            align-items: center;
            justify-content: center;

            &__proImg {
                height: 3em;
                width: 3em;
                // min-width: 50px;
                // margin-left: 1em;
                box-sizing: border-box;
                border-radius: 50%;
                background-color: #FD6125;
                color: var(--primaryColor);
                display: flex;
                align-items: center;
                justify-content: center;


                &:hover {
                    cursor: pointer;
                }
            }

            &__username {
                width: fit-content;
                height: 100%;
                padding: calc(5px * var(--heightmultiplier)) calc(12px * var(--widthmultiplier));
                // border: 1px solid green;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__midCont{
        width: 100%;
        height: 85%;
        // border: 1px solid red;
        display:flex;
        justify-content: center;
        align-items: center;
        // padding: calc(15px * ((var(--heightmultiplier) + var(--widthmultiplier)) / 2));
    }
    &__CardContainer {
        width: 100%;
        // height: 85%;
        // border: 1px solid red;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: calc(70px * ((var(--heightmultiplier) + var(--widthmultiplier)) / 2));
        justify-content: center;
        align-items: center;
        // padding: calc(15px * ((var(--heightmultiplier) + var(--widthmultiplier)) / 2));
    }
}

.DashboardTree {
    width: 100%;
    // height: calc(120px * var(--heightmultiplier));
    // border: 1px solid pink;
    // text-align: center;
    margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    color: var(--primaryTextColor);
    // font-size: calc(18px * var(--heightmultiplier));
    // font-weight: 600;
    // // display: flex;
    // align-items: center;
    // // justify-content: center;
}

.DashboardTreeParent {
    width: 100%;
    height: calc(60px * var(--heightmultiplier));
    // border: 1px solid pink;
    // text-align: center;
    color: var(--primaryTextColor);
    font-size: calc(18px * var(--heightmultiplier));
    font-weight: 600;
    // display: flex;
    align-items: center;
}

.DashboardTreelabel {
    width: 100%;
    height: fit-content;
    border: 1px solid green;
}

.supportlink{
    text-decoration: none;
    color: rgb(24, 24, 24);
    &:hover{
        text-decoration: none;
        color: rgb(24, 24, 24);
    }
}
.ReportbugsandErrors{
    text-decoration: none;
    color: var(--primaryTextColor);
    &:hover{
        text-decoration: none;
        color: var(--primaryTextColor);
    }
}

.active-sidebar-item{
    color: rgba($color: #FD6125, $alpha: 0.5);
}