
.EmployeeDeployPopup-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* border: 1px solid black; */
}



.EmployeeDeployPopup-modalContainer {
  width: 70%;
  height: 80%;
  position: relative;
  border-radius: 0.75em;

  background-color: var(--primaryColor);
  ;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-evenly;
  /* border: 5px solid #FC6025; */
  /* padding-top: calc(50px);
    padding-bottom: calc(50px); */

  padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

  font-size: calc(50px * var(--heightmultiplier));
  box-shadow: 0px 0px 15px #FD6125;
  // overflow-y: scroll;
  // scrollbar-track-color: rgba(0, 0, 0,0);
  // scrollbar-tra: rgba(0, 0, 0,0);


  &__heading {
    width: 100%;
    height: 9%;
    // border: 1px solid #fd6125;
    font-size: calc(32px * var(--heightmultiplier));
    font-weight: 500;
  }

  &__data_Container {
    width: 100%;
    height: 75%;
    // border: 1px solid #fd6125;
    // font-size: calc(32px * var(--heightmultiplier));
    // font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  &__field {
    font-size: calc(20px * var(--heightmultiplier));
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    //border: 1px solid #fd6125;
    &__title {
      width: 29%;
      font-weight: 500;
      display: flex;
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
      //border: 1px solid #fd6125;
    }

    &__colon {
      width: 2%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: calc(7px * var(--widthmultiplier));
      //border: 1px solid #fd6125;
    }

    &__value {
      width: 69%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      // border: 1px solid #fd6125;
      &__option {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: none;
        outline: none;
        //padding-left: calc(10px * var(--heightmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor)
      }
    }

  }

  &__left {
    width: 55%;
    height: 90%;
    //border: 1px solid black;
    padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  &__right {
    height: 90%;
    width: 42%;
    //border: 1px solid black;
    // border: 1px solid pink;
    padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    //margin-bottom: calc(18px * var(--heightmultiplier));
    overflow-y: auto;


    &__permision {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      margin-bottom: calc(10px * var(--heightmultiplier));

      &__save-button{
        font-size: calc(24px * var(--heightmultiplier));
        line-height: calc(30px * var(--heightmultiplier));
        padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(10px * var(--heightmultiplier));
        background-color: #15acf7;

        &:hover{
          background-color: #0167ff;
          color: white;
          font-weight: 500;
        }
      }

      &__Update {
        font-size: calc(20px * var(--widthmultiplier));
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        text-align: left;
        justify-content: space-between;
        padding: calc(0px * var(--heightmultiplier))calc(10px * var(--widthmultiplier));
        margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // border: 1px solid red;

        &__title {
          width: 30%;
          // height: 100%;
          // border: 1px solid green;
          display: flex;
          text-align: center;
          align-items: center;
          font-weight: 600;
        }

        &__options-container {
          width: 67%;
          display: flex;
          align-items: center;
          justify-content: space-around;

          &__inputLabel {
            width: 40%;
            margin-left: 5px;
          }
        }

        &__value {
          width: 67%;
          // height: 100%;
          // border: 1px solid blue;
          border-radius: calc(10px * var(--heightmultiplier));
          margin: calc(0px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
          color: var(--primaryTextColor);
          display: flex;
          flex-direction: column;
          //text-align: center;
          align-items: flex-start;
          justify-content: space-between;


          &__select {
            width: 100%;
            height: 65%;
            font-size: calc(18px * var(--heightmultiplier));
            margin-top: calc(10px * var(--heightmultiplier));
            padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            border-radius: calc(10px * var(--heightmultiplier));
            border: 2px solid var(--primaryTextColor);
            display: flex;
            flex-direction: row;
            text-align: left;
            align-items: center;
            justify-content: space-around;
            //border: 1px solid pink;
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);

            &__option {
              width: 100%;
              height: 80%;
              outline: none;
              border: none;
              //border: 1px solid blue;
              background-color: var(--primaryColor);
              color: var(--primaryTextColor);
            }
          }
        }
      }
    }
  }

  &__physicalLoc {
    width: 100%;
    height: fit-content;
    //border: 1px solid red;
    //padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    margin-bottom: calc(18px * var(--heightmultiplier))
  }

  &__PhyTitle {
    width: 100%;
    height: fit-content;
    font-size: calc(24px * var(--heightmultiplier));
    display: flex;

  }

  &__desigLoc {
    width: 95%;
    height: fit-content;
    // display: flex;
    // text-align: center;
    // align-items: center;
    // justify-content: center;
    margin: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
    margin-bottom: calc(18px * var(--heightmultiplier));
    //border: 1px solid red;
  }

  &__Unassign {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: calc(16px * var(--heightmultiplier));
    // border: 1px solid yellow;

    &__button {
      width: 30%;
      height: fit-content;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: space-around;
      border-radius: calc(10px * var(--heightmultiplier));
      font-size: calc(18px * var(--heightmultiplier));
      font-weight: 500;
      // font-family: '';
      //border: 1px solid red;
      border-color: #ee2929;
      background-color: rgba($color: #ee2929, $alpha: 0.4);

      &:hover {
        cursor: pointer;
        background-color: rgba($color: #ee2929, $alpha: 1);
        color: white;
      }
    }
  }

  &__item {
    font-size: calc(30px * var(--widthmultiplier));
    margin-top: calc(10px * var(--heightmultiplier));
    margin-bottom: calc(10px * var(--heightmultiplier));
  }

  &__button {
    width: 100%;
    display: flex;
    //flex: column;
    align-items: center;
    justify-content: space-evenly;
    // font-family: '';
    //border: 1px solid red;

    &__OkayBtn {

      box-sizing: border-box;
      width: calc(150px * var(--widthmultiplier));
      height: fit-content;
      // display: flex;
      // flex: row;
      // align-items: center;
      // justify-content: center;
      // font-family: '';
      font-style: normal;
      font-weight: 600;
      font-size: calc(30px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier));
      background-color: rgba($color: #fd6125, $alpha: 0.4);
      border-radius: 5px;
      color: black;

      &:hover {
        cursor: pointer;
        background-color: #FC6025;
        color: white;
      }
    }

    &__cancelBtn {

      box-sizing: border-box;
      width: calc(150px * var(--widthmultiplier));
      height: fit-content;
      // display: flex;
      // flex: row;
      // align-items: center;
      // justify-content: center;
      // font-family: '';
      font-style: normal;
      font-weight: 600;
      font-size: calc(30px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier));
      background-color: rgba($color: #fd6125, $alpha: 0.4);
      border-radius: 5px;
      color: black;

      &:hover {
        cursor: pointer;
        background-color: #FC6025;
        color: white;
      }
    }
  }
}