.pre-flightChecklist-main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    // border: 5px solid maroon;

}


.pre-flightChecklist-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 98%;
    height: 95%;
    //border: 1px solid red;


    &__topBar {
        //width: 98%;
        height: 6%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px;
        //border: 1px solid red;
        &__title {
            width: 100%;
            height: 100%;
            //border: 1px solid red;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: calc(25px * var(--heightmultiplier));
            font-weight: 500;
        }

        &__updateBtn {
            position: absolute;
            right: 5%;

        }
    }

    &__table {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        border: 1px solid var(--primaryTextColor);
    }

    &__index {
        position: sticky;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: var(--primaryColor);

        &__SrNo {
            border: 1px solid var(--primaryTextColor);
            width: 5%;
        }

        &__CheckBox {
            border: 1px solid var(--primaryTextColor);
            width: 5%;
            height: 100%;
        }

        &__Title {
            border: 1px solid var(--primaryTextColor);
            width: 30%;
        }

        &__Parameters {
            border: 1px solid var(--primaryTextColor);
            width: 40%;
        }

        &__Notes {
            border: 1px solid var(--primaryTextColor);
            width: 15%;
        }

        &__actions {
            border: 1px solid var(--primaryTextColor);
            width: 5%;
        }
    }
}
.pre-flightChecklist-addButton{
    width : 98%;
    height : 5%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    
    //border: 1px solid red;
    &__Button{
        width: 10%;
        height: 80%;
        border-radius: calc(10px * var(--heightmultiplier));
        background-color: rgba($color: #fd6125, $alpha: 0.5);
        border-color: #FD6125;
        font-size: calc(20px * var(--heightmultiplier));
        font-weight: 600;
        border: #FD6125;
        color: white;
        &:hover{
            background-color: rgba($color: #fd6125, $alpha: 1);
        }
    }
}