

// .AuthLocationInputBox-modalBackground {
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     width: 100%;
//     height: 100%;
//     background: rgba(6, 6, 6, 0.5);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 100;
//     /* border: 1px solid black; */
// }



.AuthLocationInputBox-modalContainer {
    width: 100%;
    height: 100%;
    background-color: var(--primaryColor);
    //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    font-size: calc(50px * var(--heightmultiplier));
    //border: 1px solid red;
   // box-shadow: 0px 0px 15px #FD6125;
   &__heading {
    width: 100%;
    height: 9%;
   // border: 1px solid #fd6125;
    font-size: calc(32px * var(--heightmultiplier));
    font-weight: 500;
  }

  &__data_Container {
    width: 100%;
    height: 75%;
   // border: 1px solid #fd6125;
    // font-size: calc(32px * var(--heightmultiplier));
    // font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  &__field {
    font-size: calc(20px * var(--heightmultiplier));
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    &__title {
      width: 28%;
      font-weight: 500;
      display: flex;
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__colon {
      width: 4%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    &__value {
      width: 67%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &__option{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: none;
        outline: none;
        //padding-left: calc(10px * var(--heightmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor)
      }
    }

  }

  &__left {
    width: 55%;
    height: 90%;
    //border: 1px solid black;
    padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  &__right {
    height: 90%;
    width: 100%;
    position: relative;
    //border: 1px solid black;
    border: 1px solid var(--primaryTextColor);
    border-radius: calc(16px * var(--heightmultiplier));
    padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
  }
  &__physicalLoc{
    width: 100%;
    height: fit-content;
   // border: 1px solid red;
    //padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
  }
  &__PhyTitle{
    //position: absolute;
    width: fit-content;
    height: fit-content;
    font-size: calc(24px * var(--heightmultiplier));
    display: flex;
    margin-top: calc(-20px * var(--heightmultiplier));
    background-color: var(--primaryColor);
    // margin: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
    padding:  calc(0px * var(--heightmultiplier)) calc(8px * var(--widthmultiplier));
    //border: 1px solid red;
    //top: 0px;
  }
  &__desigLoc{
    width: 100%;
    height: fit-content;
    border: 1px solid red;
  }

  &__item {
    font-size: calc(30px * var(--widthmultiplier));
    margin-top: calc(10px * var(--heightmultiplier));
    margin-bottom: calc(10px * var(--heightmultiplier));
  }
}