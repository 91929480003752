.HelpSectionTabContent-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    

    &__navigator{
        width: 100%;
        border: 1px solid var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__websiteBtn{
            padding: 5px;
        }
    }
    &__Content{
        width: 100%;
        border: 2px solid blue;
        height: 95%;
    }
}