.ImportExportBtn-container {
    width: 50%;
    // border: 1px solid var(--primaryTextColor);
    display: flex;
    align-items: center;
    justify-content: space-around;

    &__import-exportBtn {
        font-size: calc(24px * var(--heightmultiplier));
        width: calc(100px * var(--widthmultiplier));
        height: calc(40px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #FD6125, $alpha: 0.2);
        border-radius: calc(10px * var(--heightmultiplier));
        font-weight: 500;
        border: none;
        color: var(--primaryTextColor);

        &:hover {
            // text-decoration: underline;
            cursor: pointer;
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;
        }
    }
}