.droneBackground {
  width: 100%;
  height: 100%;
  //border: 1px solid black;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 100;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.droneContainer {
  width: 60%;
  height: 60%;
  border: 1px solid red;
  background-color: var(--primaryColor);
  justify-content: space-evenly;
  box-shadow: 0px 0px 30px #fd6125;
  padding: calc(50px * var(--widthmultiplier));
  border-radius: calc(
    20px * (var(--heightmultiplier) * var(--widthmultiplier)) / 2
  );
  &__form {
    height: 15%;
    font-size: calc(28px * var(--heightmultiplier));
    font-weight: 600;
    color: var(--primaryTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 2px solid black;
    // border: 1px solid yellow;
  }
  &__input {
    // border: 1px solid blue;
    display: flex;
    text-align: left;
    justify-content: left;
  }
}
.DroneTab-Container {
  width: 100%;
  height: 50%;
  padding: calc(50px * var(--heightmultiplier))
    calc(50px * var(--widthmultiplier));
  //border: 1px solid blue;
  // border: 1px solid red;
}
.DroneTab-content-container {
  // position: absolute;
  // left: 0px;
  // top: 0px;
  width: 100%;
  height: 100%;
  vertical-align: top;
  align-items: center;
  padding: calc(20px * var(--heightmultiplier))
    calc(20px * var(--widthmultiplier));
  //justify-content: center;
  // align-items: center;
  // justify-content: end;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  border: 1px solid yellow;

  &__input-row-cont {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    vertical-align: top;
    font-size: calc(20px * var(--heightmultiplier));
    margin-bottom: calc(10px * var(--heightmultiplier));
    width: calc(900px * var(--widthmultiplier));
    border: 1px solid #fd6125;
    border-radius: calc(
      10px * (var(--heightmultiplier) * var(--widthmultiplier)) / 2
    );
    overflow: hidden;
    padding: calc(5px * var(--heightmultiplier))
      calc(20px * var(--widthmultiplier));
    //border: 1px solid black;
    &__input {
      color: var(--primaryTextColor);
      background-color: var(--secondaryColor);
      outline: none;
      border: none;
      // border: 1px solid #FD6125;
      // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
      // width: fit-content;
      width: calc(600px * var(--widthmultiplier));
    }

    &__label {
      color: var(--primaryTextColor);
      margin-right: 10px;
      height: calc(27px * var(--heightmultiplier));
      width: calc(200px * var(--widthmultiplier));
      font-weight: 500;
    }
    &__colon {
      color: var(--primaryTextColor);
    }
  }

  &__dropdown-row-cont {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calc(20px * var(--heightmultiplier));
    margin-bottom: calc(10px * var(--heightmultiplier));
    width: calc(900px * var(--widthmultiplier));
    border: 1px solid #fd6125;
    border-radius: calc(
      10px * (var(--heightmultiplier) * var(--widthmultiplier)) / 2
    );
    overflow: hidden;
    padding: calc(5px * var(--heightmultiplier))
      calc(20px * var(--widthmultiplier));
    &__input {
      color: var(--primaryTextColor);
      background-color: var(--secondaryColor);
      outline: none;
      border: none;
      // border: 1px solid #FD6125;
      // border-radius: calc(10px * (var(--heightmultiplier)*var(--widthmultiplier)) / 2);
      // width: fit-content;
      width: calc(600px * var(--widthmultiplier));
    }

    &__label {
      color: var(--primaryTextColor);
      margin-right: 10px;
      height: calc(27px * var(--heightmultiplier));
      width: calc(200px * var(--widthmultiplier));
      font-weight: 500;
    }
    // &__colon {
    //   color: black;
    // }
  }
}
.DroneSubmitButton {
  width: 100%;
  height: 35%;
  // border: 1px solid Black;
  display: flex;
  align-items: center;
  justify-content: center;
  &__button {
    width: 12%;
    height:30%;
    border: 1px solid #fd6125;
    border-radius: calc(10px * var(--heightmultiplier));
    padding: calc(2px * var(--heightmultiplier))
      calc(5px * var(--widthmultiplier));
    background-color: rgba($color: #fd6125, $alpha: 0.2);
    font-size: calc(20px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    &:hover {
      cursor: pointer;
      background-color: rgba($color: #fd6125, $alpha: 1);
      color: white;
    }
  }
}
