.PreChecklistItemRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__SrNo {
        border: 1px solid var(--primaryTextColor);
        width: 5%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__CheckBox {
        border: 1px solid var(--primaryTextColor);
        width: 5%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Title {
        border: 1px solid var(--primaryTextColor);
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Parameters {
        border: 1px solid var(--primaryTextColor);
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 0px;

        &__table {
            width: 90%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            //margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));

            &__thead{
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            &__tbody{
                height: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            &__index {
                 height: fit-content;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                font-size: calc(20px * var(--heightmultiplier));
                // margin: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                margin-top: calc(10px * var(--heightmultiplier));
            }

            &__row {
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: space-between;
                //margin: 5px 0px;

            }

            &__SrNo {
                height: 100%;
                width: 10%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--primaryTextColor);
            }

            &__name {
                height: 100%;
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--primaryTextColor);
            }

            &__type {
                height: 100%;
                width: 15%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--primaryTextColor);
            }

            &__options {
                height: 100%;
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--primaryTextColor);
            }

            &__actions {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 20%;
                border: 1px solid var(--primaryTextColor);
            }
        }
    }

    &__Notes {
        border: 1px solid var(--primaryTextColor);
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__actions {
        border: 1px solid var(--primaryTextColor);
        width: 5%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;

    }
}