.CustomPopUpTempleteBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 19;
  /* border: 1px solid black; */
}



.CustomPopUpTempleteContainer {
  width: 60%;
  height: 60%;
  position: relative;


  border-radius: 0.75em;

  background-color: var(--primaryColor);
  box-shadow: #FD6025 0px 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  /* border: 5px solid #FC6025; */
  /* padding-top: calc(50px);
    padding-bottom: calc(50px); */
  &__title {
    width: 100%;
    height: 10%;
    //border: 1px solid red;
    font-size: calc(24px * var(--heightmultiplier));
    font-weight: 500;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
  }

  &__msg {
    width: 90%;
    height: 90%;
    // border: 1px solid red;
    margin-bottom: calc(10px * var(--heightmultiplier));
    overflow: auto;
  }

  &__close {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    right: calc(20px * var(--heightmultiplier));
    top: calc(20px * var(--widthmultiplier));
    padding: calc(2px * var(--widthmultiplier)) calc(5px * var(--heightmultiplier));
    font-size: calc(25px * var(--heightmultiplier));
    line-height: calc(25px * var(--heightmultiplier));
    border-radius: 5px;
    background-color: var(--primaryColor);
    border: 1px solid var(--primaryTextColor);

    &:hover {
      cursor: pointer;
      // font-weight: 500;
      background-color: var(--primaryTextColor);
      color: var(--primaryColor);
    }
  }
}